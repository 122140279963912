/*!
 * Start Bootstrap - Modern Business (https://startbootstrap.com/template-overvies/modern-business)
 * Copyright 2013-2017 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-logomodern-business-nav/blob/master/LICENSE)
 */

 /*=========================================================================================*/
 .request-button{
    background-color: rgb(255, 209, 5);
      height: 50px;
      position: fixed;
      bottom: 80px;
      width: 50px;
      -webkit-transition: opacity 0.5s ease-in-out 0s, right;
      -o-transition: opacity 0.5s ease-in-out 0s, right;
      transition: opacity 0.5s ease-in-out 0s, right;
      cursor: pointer;
      opacity: 1;
      border: none;
      color: #252525;
      right: 20px;
      display: none;
      z-index: 1;
  }
  
  .modal-wrapper{
    position: fixed;
    top: 0;
    z-index: 9999999;
    height: 100vh;
    left: 0;
    width: 100vw;
    background: #21252999;
  }
  .fa-check{
    color:rgb(9, 177, 9) !important;
  }
  .fa-close{
    color:rgb(192, 20, 20) !important;
  }
  
  .subs{
    font-size:15px;
  }
  .request-button2{
    background-color:rgb(255, 208, 0);
      height: 50px;
      position: fixed;
      bottom: 140px;
      width: 50px;
      border: none;
      -webkit-transition: opacity 0.5s ease-in-out 0s, right;
      -o-transition: opacity 0.5s ease-in-out 0s, right;
      transition: opacity 0.5s ease-in-out 0s, right;
      cursor: pointer;
      opacity: 1;
      padding:0px 20px 0px 0px;
      color: #1a1a1a;
      right: 20px;
      display: none;
      z-index: 1;
  }
   
  .mainbody1 {
    padding-top: 0px !important;
    overflow-x: hidden;
  }
  
  .consulttext{
    font-size:12px;
  }
  .textsocial{
    font-size:12px; 
  }
  
  .navbar .li:active{
    color:blue;
  
  }
  /* .image{
    margin-left:40px;
    width:150px;
    height:150px;
  } */
  
  
  * a:hover{
    text-decoration: none;
  }
  .dropdown .dropdown-menu {
    transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -webkit-transition: all 0.0010ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
    -o-transition: all 0.001ms ease;
    -ms-transition: all 2000ms ease;
  }
  
  
  .dropdown:hover .dropdown-menu {
    display: block;
    margin-top:-1px;
  }
  .heading8{
    font-size:19px;
    color:#ffffff;
    font-weight:200;
   
  }
  .dropdown-item {
    padding: 10px 30px !important;
  }
  
  .dropdown-item:hover {
    color: rgb(1, 1, 73) !important;
    background-color: #ffffff;
  }
  
  #main-navbar {
    padding-left: 20px;
  }
  .expertimg{
    padding-left:40px;
    padding-bottom:20px;
    animation: expert 4s ease infinite ;
  }
  @-webkit-keyframes expert {
    from {transform: scale(1.0);}
    to {transform: scale(1.2);}
  }
  
  @keyframes expert {
    from {transform: scale(1.0);}
    to {transform: scale(1.1);}
  }
  

  
  .navbar-brand {
    margin-left: 40px;
    font-size: 24px;
    font-weight: 600;
  }
  @media only screen and (max-width: 480px){
    .navbar-brand {
      margin-left: -40px;
      font-size: 24px;
      font-weight: 600;
    }}
  
 
  
  .nav-link, .navbar-brand {
    color: #0d1d37;
  }
  
  .nav-link:hover {
    color: #3c3c3d;
  }
  
  .login-nav-item {
    padding: 0px 3px;
    font-size: 16px;
    font-weight: 500;
    font-family:lato,'sans-serif';
    color: #071730;
  }
  .nav-item {
    padding: 0px 3px;
    font-size: 16px;
    font-weight: 500;
    font-family:lato,'sans-serif';
    color: #ffffff !important;
  }
  .breadcrumbitem{
    font-size:14px;
    color:rgb(63, 63, 63) !important;
    display:inline;
    margin-right: -4px;
    padding-left:2px;
    padding-right:0px;
    font-weight:100;
  }
  
  .breadcrumb li {
    display:inline;
    
  
  }
  .carousel-item {
    margin-top:0px;
    height: 55vh;
    min-height: 400px;
    background: no-repeat center center scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    -webkit-transition: property duration timing-function delay;
    -moz-transition: property duration timing-function delay;
    transition: property duration timing-function del;
  }
  
  .car1 {
    height: 55vh !important;
  }
  
  .car5 {
    height: 67vh;
  }
  
  .banimage1 {
    padding: 65px 150px 50px 0px;
  }
  .banimage {
    padding: 1px 56px 0px 95px;
  }
  
  .portfolio-item {
    margin-bottom: 30px;
  }
  
  .circles {
    width: 150px;
    height: 150px;
  }
  
  .circles2 {
    width: 50px;
    height: 50px;
  }
  
  #card1 {
    margin: 40px;
  }
   .pricestart{
     background-color:#FFF129;
     padding:10px 10px 3px 6px;
     margin-bottom:10px;
   }
   .pricestart1{
    background-color:#FFF129;
    padding:10px 10px 3px 10px;
    margin-bottom:10px;
  }
  .prices{
  color:rgb(84, 84, 84);
  font-weight:600;
  font-size:14px;
  }
  #contactform{
    padding:10px;
  }
  
  
  
  abbr[data-original-title], abbr[title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: none;
    cursor: help;
    border-bottom: 0;
  }
  /* .copyright {
    background-color: #000932;
  } */
  
  .contactsec{
    padding:30px;
  
  }
  
 
  
  #latestarticles {
    color: #535d6d;
    font-family: 'Lato', sans-serif;
   font-size:200;
  }
  
  .fa {
    padding: 25px;
  }
  
  .fa:hover {
    opacity: 0.7;
  }
  
  /* Facebook */
  
 
  
  .homesection2{
    margin-top:50px;
    padding:40px 30px;
    height:269px;
    background-color: white;
  }
  /* Twitter */
  
  /* .fa-twitter {
    background: #55ACEE;
    color: white;
    height: 13px;
    width: 13px;
    font-size: 20px;
  } */
  
 
  
  .contact {
    margin-top: 30px;
    background-color: #ffffff;
    padding: 30px;
  }
  
  .common {
    padding: 60px;
  }
  
  /*wht we offer*/
  
  .choose{
    box-shadow:1px 1px 14px 0px #b7b7b7;
    
  }
  
    
    

  .circle-row {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  
  .circle-row1 {
    margin-top: 30px;
    margin-bottom:30px;
    margin-left: -59px;
  }
  .customers7{
    padding:20px 0px 30px 0px;
  }
  .customers{
    padding:20px 20px 20px 0px !important;
  }
  .customers9{
    padding:40px 50px 40px 60px;
  }
  .customers5 {
    padding:40px 40px 40px 50px;
  }
  .customers4 {
    padding:40px 40px 40px 120px;
  }
  .worktext{
    font-size:18px;
    font-weight:200;
    font-family: lato , 'sans-serif';
  }
  .aboutus {
    height: 400px;
  }
  
  .head {
    color: #ffffff;
    padding-top: 70px;
    padding-left: 230px;
    font-family: 'Lato', sans-serif;
  }
  
  /*stuff to be used again*/
  
  .circles1 {
    width: 20px;
    height: 20px;
    margin-bottom: 20px;
  }
  
  /* PRODUCTS */
  .products{
    margin:60px -30px;
  }
  
  #requestademo {
    padding: 30px;
    background-color: rgb(255, 255, 255);
    text-align: center;
  }
  
  .button1:hover {
    opacity: 0.6;
  }
  
  /*main page css*/
  
  .sec1 {
    height: 280px;
    
     background-color: #ffffff;
    padding: 28px 30px 5px 20px;
    margin: 70px 20px 30px 20px;
    border-radius: 8px;
    transition:all 0.2s ease;
    box-shadow: 5px 6px 20px 0px #9494935c
    
    
  
  }
  .sec1:hover {
    height: 280px;
   
    
    padding: 28px 30px 5px 20px;
    margin: 70px 20px 30px 20px;
    border-radius: 4px;
    
    
    
    transform:scale(1);
    cursor:pointer;
    
  
  }
  .sec2 {
    height: 280px;
   
     background-color: #ffffff;
    padding: 28px 30px 5px 20px;
    margin: 70px 20px 30px 20px;
    border-radius: 8px;
    transition:all 0.2s ease;
    box-shadow: 5px 6px 20px 0px #9494935c
    
    
   
  
  
  }
  .carousel button {
    outline: 0;
    border: 0;
    background: 0 0;
  }
  .button10{
    background-color:#fff129 !important; 
  }
  .testimonials{
    font-size:28px;
    color:#686868;
    font-family:'lato', sans-serif;
    font-weight:100;
    padding-bottom:20px;
  }
  
  .sec2:hover{
    height: 280px;
   
     background-color: #ffffff;
    padding: 28px 30px 5px 20px;
    margin: 70px 20px 30px 20px;
    border-radius: 8px;
    transition:all 0.2s ease;
    box-shadow: 5px 6px 20px 0px #9494935c;
    
    cursor:pointer;
   
  
  }
  
  .row1 {
    padding: 10px 50px 10px 130px !important;
    margin: 0px 30px;
  }
  
  .link1 {
    background-color: #0e89dbce;
    color: #ffffff;
    padding: 40px;
    padding: 40px 20px 40px 300px;
  }
  
  .link2 {
    background-color: #000932;
    color: #ffffff !important;
    padding: 40px 20px 40px 0px;
    transition:all 0.5s ease;
    text-align:center;
  }
  /* .link2:hover{
    background-image:url('../images/pricinghover.png');
    color:#000000 !important;
   
  } */
  
  .sec3 {
    padding: 40px 50px 40px 68px;
    height: 340px;
    font-family: 'Lato', sans-serif;
    color: #6b7070;
  }
  
  .textblock {
    margin-top: 13%;
    padding: 26px;
    background-color: rgba(55, 185, 255, 0.479);
    /* height: 280px; */
    border-radius: 5px;
    width: 100%;
    color: #050505;
  }
  
  .textblock2 {
    margin-top: 10%;
    padding: 40px;
    background-color: rgba(237, 255, 159, 0.542);
    height: 280px;
    border-radius: 5px;
    width: 50%;
    font-size: 22px;
    color: #333333;
  }
  
  .oppor {
    padding: 30px 90px 30px 90px;
    height: 460px;
    text-align: center;
    border-radius: 5px;
    margin: 20px -20px;
  }
  
  .oppor1 {
    padding: 40px;
  }
  
  .opportrain {
    padding: 30px 0px 30px 0px;
    height: 460px;
    text-align: center;
    border-radius: 5px;
  }
  
  .oppor1train {
    padding: 40px;
  }
  
  
  /*fonts*/
  
  .heading3 {
    color: #2b2b2b;
    font-weight: 700;
    font-size: 30px;
    font-family: lato , 'sans-serif';
  }
  
  .heading5 {
    color: #2b2b2b;
    font-weight: 300;
    font-size: 25px;
    font-family: lato , 'sans-serif';
  }
  
  
  .heading18
   {
    color: #3c3c3c !important;
    font-weight: 200 !important;
    font-size: 20px !important;
    font-family: lato , 'sans-serif';
  }
  
  .heading15 {
    color: #ffffff !important;
    font-weight: 200 !important;
    font-size: 20px !important;
    font-family: lato , 'sans-serif';
  }
  
  .heading4 {
    color: #505050;
    font-weight: 200;
    font-size: 18px;
    font-family: lato , 'sans-serif';
  }
  
  .heading1 {
    color: #3d3d3d;
    font-family: lato , 'sans-serif';
    font-size: 27px;
    margin-top: 2px;
    font-weight: 200;
  }
  
  .headingpricing {
    margin-top: 30px;
    color: #3d3d3d;
    font-size: 30px;
    font-family: lato , 'sans-serif';
    margin-left: 0px;
  }
  
  .heading {
    margin-top: 25px;
    color: #2b2b2b;
    font-size: 24px;
    font-family: lato , 'sans-serif';
    
  }
  .heading32 {
    margin-top: 25px;
    color: #ffffff;
    font-size: 24px;
    font-family: lato , 'sans-serif';
    font-weight: 200;
  }
  .heading1 {
    margin-top: 25px;
    color: #ffffff;
    font-size: 24px;
    font-family: lato , 'sans-serif';
    font-weight: 200;
  }
  .heading64 {
    margin-top: 25px;
    color: #272727;
    font-size: 24px;
    font-family: lato , 'sans-serif';
    font-weight: 200;
  }
  
  .heading16 {
    margin-top: 25px;
    color: #1b1b1b;
    font-size: 24px;
    font-family: lato , 'sans-serif';
    font-weight: 200;
  }
  .sub-heading-query{
    margin-top: 25px;
    color: #ffffff;
    font-size: 20px;
    font-family: lato , 'sans-serif';
    font-weight: 200;
  }
  .headinghow{
  
    color: #6d6d6d;
    font-size: 24px;
    font-family: lato , 'sans-serif';
    font-weight: 200;
  }
  
  
  
  
  .heading2 {
    font-size: 23px;
    font-weight: 600;
    color: #1a0e3c;
    font-family: lato , 'sans-serif';
  }
  
  .button1 {
    margin-top: 30px;
    text-align: center !important;
    text-decoration: none;
    font-size: 30px;
    font-family: 'Lato', sans-serif;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
    color: rgb(55, 55, 55) !important;
    text-decoration: blink !important;
  }
  
  .button1:hover {
    transform: scale(1.1);
  }
  
  .button2 {
    margin-top: 30px;
    background-color: rgb(247, 165, 13);
    /* Green */
    border: none;
    padding: 8px 10px 4px 10px;
    text-align: center !important;
    display: inline-block;
    font-size: 10px;
    font-weight: 900;
    border-radius: 4px;
    color: #ffffff;
    font-family: 'Lato', sans-serif;
    transition: all 0.2s ease;
    box-shadow:-1px 1px 2px 0px #b7b7b7;
  
  }
  .heading6 {
    color: #161616;
    font-weight: 600;
    font-size: 14px;
   
  }
  
  .smalltext{
    font-size:12px;
    color:rgb(78, 78, 78);
    padding-left:40px;
    padding-top:5px;
  }
  .heading34 {
    color: #161616;
    font-weight: 200;
    font-size: 16px;
    font-family: lato , 'sans-serif';
  }
  
  .button11:hover {
    transform: scale(1);
    padding: 8px 10px 4px 10px;
    background-color: rgba(112, 191, 230, 0.548);
    cursor:pointer;
    border: 2px solid #2F9CD5;
  }
  .button11{
    margin-top: 30px;
    background-color:  rgba(109, 188, 225, 0.21);
    /* Green */
    border: 2px solid rgba(255, 255, 255, 0);
    padding: 8px 10px 4px 10px;
    text-align: center !important;
    display: inline-block;
    font-size: 10px;
    font-weight: 900;
    border-radius: 4px;
    color: #ffffff;
    font-family: 'Lato', sans-serif;
    transition: all 0.2s ease;
    
  
  
  }
  
  .button12:hover {
    transform: scale(1);
    padding: 8px 10px 4px 10px;
    background-color:  rgba(47, 158, 213, 0.5);
    cursor:pointer;
    border: 2px solid #2F9CD5;
   
  }
  
  
  .button12{
    margin-top: 8px;
    background-color: rgba(109, 188, 225, 0.21);
    /* Green */
    border: 2px solid rgba(255, 255, 255, 0);
    padding: 8px 10px 4px 10px;
    text-align: center !important;
    display: inline-block;
    font-size: 10px;
    font-weight: 900;
    border-radius: 4px;
    
    font-family: 'Lato', sans-serif;
    transition: all 0.2s ease;
    
  
  }
  .button13{
    margin-top: 48px;
    background-color: rgb(17, 55, 84) !important;;
    /* Green */
    border: 2px solid rgb(255, 255, 255);
    padding: 14px 16px 8px 16px;
    text-align: center !important;
    display: inline-block;
    font-size: 15px;
    font-weight: 900;
    border-radius: 3px;
    margin-left:10px;
    width:290px;
    font-family: 'Lato', sans-serif;
    transition: all 0.2s ease;
    
  
  }
  
  .button31{
    margin-top: 8px;
    background-color: rgb(30, 195, 166) !important;
    /* Green */
    border: 2px solid rgb(255, 255, 255);
    padding: 14px 16px 8px 16px;
    text-align: center !important;
    display: inline-block;
    font-size: 15px;
    font-weight: 900;
    border-radius: 3px;
    margin-left:10px;
    width:180px;
    font-family: 'Lato', sans-serif;
    transition: all 0.2s ease;
    
  
  }
  .labels{
    color:white !important;
  }

  .button14{
    margin-top: 8px;
    background-color: rgb(255, 255, 255) !important;
    /* Green */
    border: 2px solid rgb(255, 255, 255);
    padding: 14px 16px 8px 16px;
    text-align: center !important;
    display: inline-block;
    font-size: 15px;
    font-weight: 900;
    border-radius: 3px;
    margin-left:10px;
    box-shadow:-1px 1px 2px 0px #b7b7b7;
    font-family: 'Lato', sans-serif;
    transition: all 0.2s ease;
    
  
  }
  .button18{
    margin-top: 8px;
    background-color: rgb(255, 175, 3) !important;
    /* Green */
    border: 2px solid rgb(255, 255, 255);
    padding: 14px 20px 8px 20px;
    text-align: center !important;
    display: inline-block;
    font-size: 15px;
    font-weight: 900;
    border-radius: 3px;
    margin-left:10px;
    box-shadow:-1px 1px 2px 0px #b7b7b7;
    font-family: 'Lato', sans-serif;
    transition: all 0.2s ease;
    
  
  }
  .button14:hover{
  cursor:pointer;
  transform:scale(1.1);
  }
  .button18:hover{
    cursor:pointer;
    transform:scale(1.1);
    }
  .button15{
    margin-top: 8px;
    background-color: rgba(2, 4, 41, 0.789) !important;
    /* Green */
    border: 2px solid rgb(255, 255, 255);
    padding: 14px 16px 8px 16px;
    text-align: center !important;
    display: inline-block;
    font-size: 15px;
    font-weight: 900;
    border-radius: 3px;
    margin-left:10px;
    box-shadow:-1px 1px 2px 0px #b7b7b7;
    font-family: 'Lato', sans-serif;
    transition: all 0.2s ease;
    
  
  }
  .button15:hover{
  cursor:pointer;
  transform:scale(1.1);
  }
  .button13:hover{
    margin-top: 8px;
    background-color:rgb(11, 36, 56) !important;
    /* Green */
   cursor: pointer;
    padding: 14px 16px 8px 16px;
    text-align: center !important;
    display: inline-block;
    font-size: 15px;
    font-weight: 900;
    border-radius: 3px;
    margin-left:10px;
    width:290px;
    
    font-family: 'Lato', sans-serif;
    transition: all 0.2s ease;
    
  
  }
  
  
  
  .button7 {
    margin-top: 30px;
    background-color: rgb(6, 170, 247);
    /* Green */
    border: none;
    padding: 8px 10px 4px 10px;
    text-align: center !important;
    display: inline-block;
    font-size: 10px;
    font-weight: 900;
    border-radius: 4px;
    color: #ffffff;
    font-family: 'Lato', sans-serif;
    transition: all 0.2s ease;
    box-shadow:-1px 1px 2px 0px #b7b7b7;
  
  }
  .button7:hover {
    transform: scale(1);
    padding: 8px 10px 4px 10px;
    background-color: rgb(2, 32, 92);
  }
  
  .button6 {
  
  
    background-color:#2F9CD5;
    /* Green */
    border: none;
    padding: 12px 35px 8px 35px;
    margin-left:15px;
    margin-bottom: 10px;
    text-align: center !important;
    display: inline-block;
    font-size: 17px;
    font-weight: 900;
    border-radius: 4px;
    color: #ffffff;
    font-family: 'Lato', sans-serif;
    transition: all 0.2s ease;
    box-shadow:-1px 1px 2px 0px #b7b7b7;
  
  }
  .button6:hover {
    transform: scale(1.1);
  
    background-color: rgb(2, 34, 99);
  }
  
  .contactbuttontext{
    font-size:20px;
  }
  
  .startupmodal{
    padding:20px 40px 20px 30px;
  }
  
  .dialogue {
    /* padding: 10px 10px 0px 10px; */
    padding:20px 10px 30px 10px;
    border:8px solid white;
    background-color:rgb(255, 255, 255);
    width:320px;
    border-radius:5px;
    margin-bottom:50px;
    margin-top:50px;
    margin-right:-45px;
    box-shadow: 3px 1px 20px 0px #16648598;
  
  }
  
  .car3{
    height:88vh;
  }
  
  .imagecorp {
    
      border: 2px solid #ffffff;
      border-radius: 5px;
      margin: 0px 30px;
      padding: 40px 5px 30px 5px;
      background-color: #ffffff;
      -webkit-box-shadow: -6px 7px 12px 1px #6da3f9;;
      box-shadow: -6px 7px 12px 1px #6dbff9e8;
  
  }
  
  imagecorp {
    border: 2px solid #ffffff;
    border-radius: 5px;
    margin: 0px 30px;
    padding: 40px 5px 30px 5px;
    background-color: #ffffff;
    -webkit-box-shadow: 4px 3px 20px 0px #888888;
    box-shadow: 5px 2px 13px 0px #03030387;
  }
  .homestripitem1{
    cursor:pointer;
  }
  .imagecorp2 {
    border: 2px solid #ffffff;
    border-radius: 10px;
    margin: 0px 0px 0px 70px;
    height:150px;
    
    padding: 40px 5px 30px 5px;
    background-color: #ffffff;
    -webkit-box-shadow: -6px 7px 12px 1px #6da3f9;;
      box-shadow: -6px 7px 12px 1px #6da3f9;;
  
  }
  
  .ask1 {
    padding: 40px;
    background-color: #a0a0a065;
    box-shadow: 2px 1px 4px 0px #888888;
  
  }
  
  /* .ask {
    background-image: url('../images/askbanner.png');
    box-shadow:2px 1px 4px 0px #888888;
  } */
  
  .askanexpert {
  
  
    border-radius: 10px;
    padding: 60px;
  }
  
  .askbutton {
    padding: 20px;
    height:119px;
    border-radius: 5px;
    
    
  }
  /* .askb{
    width:290px;
    height:90px;
    background-image: url('../images/phoneicon2.png');
  
  } */
  /* .askb:hover{
    width:290px;
    height:119px;
    background-image: url('../images/phoneicon1.png');
  } */
  
  /* .askbutton:hover {
    
    background-image: url('../images/phoneicon2.png');
    -webkit-background-clip: text;
    -webkit-text-fill-color: #4b2424;
  } */
  
  .dialogue1 {
    padding: 10px 10px 0px 10px;
    border: 8px solid white;
    background-color: rgb(255, 255, 255);
    border-radius: 2px;
    border-radius: 21px;
    margin-bottom: 50px;
    margin-top: 41px;
    margin-right: -45px;
    -webkit-box-shadow: 4px 4px 11px 0px #0a0a0a;
    box-shadow: 4px 4px 11px 0px #0a0a0a;
  }
  .dialogue2 {
    /* padding: 10px 10px 0px 10px; */
    padding: 13px 10px 3px 10px;
    border: 8px solid rgb(228, 225, 225);
    background-color: rgb(255, 255, 255);
    width: 320px;
    border-radius: 15px;
    margin-bottom: 50px;
    margin-top:5%;
    margin-right: -45px;
    box-shadow:-11px 8px 10px 0px #408090;;
  }
  
  
  .dialoguetext {
    font-family: 'Lato', sans-serif;
    color: #888888;
    font-size:18px;
  
   
  }
  .dialoguetextmain {
    font-family: 'Lato', sans-serif;
    color: #575656;
    font-size:17px;
    font-weight:600;
    background-color: #F7E92A;
    padding:5px 0px;
    margin-bottom:8px;
   
  }
  
  .textblock1 {
    padding: 5px 0px 25px 40px;
    margin-bottom: 50px;
  }
  
  
  .paddingTop-5{
  
    padding-top: 145px;
  }
  
  
  .paddingTop-10{
  
    padding-top: 9%;
  }
  /*ABOUT PAGE CSS*/
  
  .aboutimage  {
    margin-left: 30px;
    font-size: 18px;
    
  }
  .abouthead{
    font-size: 18px;
    font-weight:600 ;
    font-family:lato,'sans-serif';
  }
  
  .aboutcontent {
    font-size: 14px;
    color: #6d6c6c;
    
  
  }
  
  .iconcontent {
    padding-top: 20px;
    font-size: 18px;
    font-weight: 600;
  }
  
  .iconborder {
    border: 1px solid #88e9fa;
    margin: 45px;
    padding: 19px;
    border-radius: 7px;
    background-color: #ffffff
  }
  
  .iconborder1 {
    margin: 30px 32px 30px 50px;
    border-radius: 7px;
    -webkit-box-shadow: 5px 8px 20px 10px #cfcfcf;
    box-shadow: 5px 8px 20px 10px #cfcfcf;
    background-color: rgb(29, 29, 29);
    opacity: 1;
    height: 140px;
    width: 170px;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  
  .iconborder1:hover {
    transform: scale(1.2);
  }
  
  .iconcontent1 {
    padding-top: 20px;
    font-size: 25px;
    font-weight: 600;
    color: #ffffff;
  }
  
  .requestname {
    padding-bottom: 20px;
  }
  
  .textblock1>.row {
    padding-top: 2%;
  }
  
  /*training page*/
  .trainingdiv{
    padding:3px 0px;
  }
  .trainsec1 {
    font-family: 'Lato', sans-serif;
    border: 2px solid #dfdfdf;
    padding: 20px 20px 10px 20px;
    margin: 20px 0px;
    border-radius: 4px;
  }
  
  .mysec {
    padding: 40px 0px 100px 0px;
  }
  
  .trainsec3 {
    background: #fafafa;
    border-radius: 100%;
    border: 2px solid #dcd5e8;
    height: 200px;
    width: 200px;
    padding-top: 70px;
    margin: 40px 20px 20px 30px;
    color: #333333;
    font-size: 18px;
    font-weight: 600px;
    font-family: 'Lato', sans-serif;
  }
  
  
  /*CORPORATE*/
  .whatweoffer{
   padding:20px 0px 20px 0px;
    
  }
  .boxcor {
    border: 2px solid #ffffff;
    border-radius: 10px;
    margin: 0px 49px;
    padding: 30px 5px 0px 5px;
    background-color: #ffffff;
    box-shadow: -6px 7px 12px 1px #6da3f9;;
   
  }
  
  .boxsocial {
    border: 2px solid #e6e5f4;
    border-radius: 10px;
    margin: 0px 0px 0px 39px;
    padding: 20px 5px 14px 5px;
    background-color: #ffffff;
    box-shadow: -5px 2px 20px 0px #85bddc;
    height: 150px;
    font-size: 12px;
  }
  .boxsocial:hover{
    cursor:pointer;
  }
  
  .boxsocial1 {
    border: 2px solid #e5e6f4;
    border-radius: 10px;
    margin: 0px 45px 0px 39px;
    padding: 20px 5px 14px 5px;
    background-color: #ffffff;
    box-shadow: -5px 2px 20px 0px #85bddc;
    color: #6f6e6e
  }
  
  .circle-row2 {
    padding-left: 140px;
    padding-bottom:50px;
    padding-top:50px;
  }
  
  
  /*CORPORATE*/
  
  .customers{
    margin-left:30px;
  }
  
  .ct1 {
    background-color: #165691;
    padding: 30px 0px 30px 190px;
  }
  
  .contact3 {
    padding-top: 50px;
    height: 130px;
    background-color: #ffffff;
    color: #5c5c5c;
    font-family: 'Lato', sans-serif;
    border-radius: 6px;
    margin: 5px 0px 5px -15px;
  }
  
  .contact2 {
    border: 4px solid #1F4E8C;
    background-color: #1F4E8C
  }
  
  .contact1 {
    border: 4px solid #1F4E8C;
    background-color: #1F4E8C;
    padding: 20px 40px 20px 140px;
  }
  
  .contacttext {
    font-size: 22px;
    font-weight: 600;
  }
  .subcontacttext{
    font-size:22px;
    font-weight: 400;
    color:#252525;
    padding-left:0px;
  
  }
  .details{
    color:rgb(63, 63, 63);
    font-size:17px;
    font-family:'lato',sans-serif;
    font-weight:600;
  }
  .request {
    padding: 0px 60px 0px 60px;
  }
  
  .contactstrips {
    margin-top:20px;
    background-color: #ebf3fd;
   border-radius:10px;
  padding-bottom:50px; 
    padding-top: 50px;
    color: #ffffff !important;
  
  }
  .contactText{
    font-size:30px;
    color:#056db8;
    font-weight:400;
  }
  .contactsec1{
    padding:30px 20px;
  }
  
  .contactstrips a {
    width: 100%;
  }
  
  /*PROCESSSSSSSSSSSING*/
  
  .line1{
    height: 4px;
    width: 0px;
    background-color: #ffffff;
    /* top: 1px; */
    margin-top: 73px;
    transition:all 0.5s ease;
    border:dashed;
    border-color:white;
    padding:none;
    
  }
  .line2 {
    height: 4px;
    width: 0px;
    background-color: #ffffff;
    /* top: 1px; */
    margin-top: 73px;
    transition:all 0.2s ease;
    border:dashed;
    border-color:white;
  }
  .line3 {
    height: 4px;
    width: 0px;
    background-color: #ffffff;
    /* top: 1px; */
    margin-top: 73px;
    transition:all 0.2s ease;
    border:dashed;
    border-color:white;
  }
  
  .imgcorp2:hover line1{
    background-color:blue;
  }
  
  
  .imagecorp1 {
    font-size: 18px;
    font-weight: 600;
    padding:0px 0px;
    width:180px;
  }
  
  
  @media only screen and (min-width:758px){
    .mobile{
      display:none;
    }
  }
  
  @media only screen and (max-width: 500px) {
  
  .breadCrumb-mobile{
    top: 63px;
    z-index: 1120;
    position: fixed;
  }
  
    .collapse {
      display: none !important;
    }
  }
  .subtitles{
    padding-top:0px;
    padding-left:44px;
    font-size:13px;
    
    color:#aeaeae;
    font-weight:200;
  }
  
  .bantext {
    color: #ffffff;
    font-size: 18px;
     font-weight:100;
    padding-top: 8px;
    padding-right: 1px;
    margin-left: -30px;
  }
  
  .iconrow {
    padding-top: 20px;
  }
  .iconrow2 {
    padding-top: 46px;
  }
  /*PRICINGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGG*/
  
  /* Style the tab */
  
  /*PRICINGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGG*/
  
  /* Style the tab */
  
  .accordion-btn {
    background-color: rgb(160, 214, 250);
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: 0.4s;
    border: 1px solid #ffffff;
  }
  /*css for react-accessible-accordion component */
  .accordion{
    background-color: #19447F;
    color: #ffffff;
  }
  .accordion__body{
    padding-bottom: 10px;
    z-index: 1;
  }
  
  .pricehead {
    font-size: 14px;
    font-weight: 400;
    
    text-align: center;
  }
  
  
  .second1{
    margin-top:30px;
  }
  .second2{
    margin-top:30px;
  }
  .request-button2{
    background-color:rgb(255, 208, 0);
      height: 35px;
      position: fixed;
      bottom: 120px;
      width: 45px;
      border: none;
      -webkit-transition: opacity 0.5s ease-in-out 0s, right;
      -o-transition: opacity 0.5s ease-in-out 0s, right;
      transition: opacity 0.5s ease-in-out 0s, right;
      cursor: pointer;
      opacity: 1;
      padding:0px 0px 10px 0px;
      color: #1a1a1a;
      right: 20px;
      display: none;
      z-index: 1;
  }
  
  .request-button{
    background-color: rgb(255, 209, 5);
      height: 35px;
      position: fixed;
      bottom: 80px;
      width:45px;
      -webkit-transition: opacity 0.5s ease-in-out 0s, right;
      -o-transition: opacity 0.5s ease-in-out 0s, right;
      transition: opacity 0.5s ease-in-out 0s, right;
      cursor: pointer;
      opacity: 1;
      border: none;
      color: #252525;
      right: 20px;
      display: none;
      z-index: 1;
  }
  
  /* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
  .accordion-btn:focus{
    outline: none;
    /* to remove outline after accordion has been clicked */
  }
  .active, .accordion-btn:hover {
    background-color: #19447F;
    color: #ffffff;
    ;
  }
  
  
  /* Style the accordion panel. Note: hidden by default */
  
  .panel {
    background-color: #19447F;
    display: none;
    width: 517.5px;
    transition: all 0.5s ease-in;
    padding-bottom: 10px;
  }
  
  /* Style the buttons that are used to open the tab content */
  
  .pricingtabs {
    margin-top: 120px;
  }
  
  .listcard {
    border: 1px solid #000000;
    padding: 20px;
  }
  
  .pricingprice {
    color: #f5f4f4;
    font-size: 27px;
    padding: 5px 0px 0px 0px;
    font-weight: 700;
    font-family: lato, ' sans-serif';
    width: 530px;
    text-align: center;
    border-bottom: white;
  }
  .pricingpriceside{
    font-family: lato, ' sans-serif';
    font-size:18px;
  }
  
  .pricingcontent {
    color: #fffdfd;
    font-size: 18px;
    font-weight: 600;
    padding: 4px;
    width: 510px;
    font-family: lato, ' sans-serif';
    text-align: center;
  }
  
  .pricingcontent1 {
    color: #fffdfd;
    font-size: 18px;
    font-weight: 600;
    padding: 4px;
    width: 430px;
    font-family: lato, ' sans-serif';
    text-align: center;
  }
  
  .incorporatepricing {
    height: auto;
    padding-bottom:30px;
    padding-top:30px;
  }
  .complypricing {
    height: auto;
    padding-bottom:30px;
    padding-top:30px;
  }
  .protectpricing {
    height:auto;
    padding-bottom:30px;
    padding-top:30px;
  }
  .taxpricing {
    height: auto;
    padding-bottom:30px;
    padding-top:30px;
  }
  .windpricing {
    height: auto;
  padding-bottom:30px;
  padding-top:30px;
  }
  
  
  .faq {
    margin-top: 5%;
  }
  .mainbutton{
    
     width:334px;
      background-color:#f7e92a;
       border:none;
       color:#333231;
   }
  .tabgrid{
    margin:80px;
   
    box-shadow: 2px 0px 20px rgba(154, 147, 147, 0.4);
  }
  /*HEXAGON*/
  .headingpricing{
    margin-left:0px;
  }
  .customers3 {
    padding: 40px 90px 20px 370px;
  }
  
  .customers2 {
    padding: 40px 40px 20px 160px;
  }
  
  .customers02 {
    padding: 40px 40px 20px 280px;
  }
  
  .customers11 {
    padding: 40px 40px 20px 190px;
  }
  .customers12 {
    padding: 40px 40px 20px 480px;
  }
  .customers1 {
    padding: 40px 90px 20px 0px;
  }
  
  .pin{margin-top:-45px;
  transition:all 0.3s ease;}
  
  
  .offercontent {
    padding:0px 10px;
    color: #2e2e2e;
    font-size:16px;
     font-family: lato , 'sans-serif';
    font-weight:200;
    margin-top:-15px;
  }
  
  
  .offercontent1 {
    padding:0px 10px;
    color: #2e2e2e;
    font-size:16px;
    font-weight:100;
    font-family: lato , 'sans-serif';
  
    margin-top:-11px;
  }
  
  
  
  
  
  
  
  .offercontent2{
    padding: 5px;
    color: #adadad;
    font-size:17px;
  }
  
  
  /*********************************************/
  .hexagon1:hover .offercontent{
    padding: 5px;
    color: #ffffff;
    font-size:17px;
  }
  
  .offercontent3 {
    padding: 10px;
    color: #fffbfb;
    font-size:17px;
  }
  
  .hexagon1:hover .offercontent1 {
    padding: 10px;
    color: #ffffff;
    font-size:17px;
  }
  
  
  
  .hexagon1 {
    position: relative;
    width: 150px;
    height: 86.60px;
    background-color: #2694DF;
    margin: 43.30px 0;
    box-shadow: 0 0 20px rgba(80, 80, 80, 0.4);
    transition:all 0.2s ease;
  }
  .hexagon1:hover
  { color:#fafafa;
    transform: scale(1.2);
    background-color: rgb(0, 22, 63);
  }
  
  .expertrow{
    padding-left:1%;
    padding-top:80px;
  }
  .hexagon1:before,
  .hexagon1:after {
    content: "";
    position: absolute;
    z-index: 1;
    width: 106.07px;
    height: 106.07px;
    -webkit-transform: scaleY(0.5774) rotate(-45deg);
    -ms-transform: scaleY(0.5774) rotate(-45deg);
    transform: scaleY(0.5774) rotate(-45deg);
    background-color: inherit;
    left: 21.9670px;
    box-shadow: 0 0 20px rgba(102, 127, 141, 0.4);
  }
  
  .hexagon1:before {
    top: -53.0330px;
  }
  
  .hexagon1:after {
    bottom: -53.0330px;
  }
  
  /*cover up extra shadows*/
  .hexagon1 span {
    display: block;
    position: absolute;
    top:0px;
    left: 0;
    width:150px;
    height:86.6025px;
    z-index: 2;
    background: inherit;
  }
  /*opportuneety*/
  .textfields{
    width:110px;
  }
  .textfields1{
    width:110px;
  }
  .opportuneety1 {
    height: 510px;
    transition: 0.2s ease-in;
  }
  
  
  
  .oppormain {
    padding: 0px 45px 0px 45px;
  }
  
  .platformcontent {
    padding-top: 0px;
    font-family: 'Lato', sans-serif;
    color: #6e6e6e;
    font-size: 28px;
  }
  
  /*form-group*/
  

  .resource1{
    width:860px;
    color: #7a7a7a;
    font-size:14px;
  }
  .form-control1 {
    color: #0f0f0f;
    font-size:14px;
  }
  
  
  .pricing {
    padding-top: 100px;
    padding-left: 60px;
  }
  /*-----======================================================================================*/
  
  /*===========================================================================================*/
  .charitybox{
    margin-top:35px;
  
    height:auto;
    background-color: #F7E92A;
    border:2px solid rgb(255, 225, 0);
    padding:6px;
    border-radius:5px;
    box-shadow: 0 0 20px rgba(80, 80, 80, 0.4);
  
  }
  .charitytxt{
    padding:7px 30px 30px 30px;
    font-size:15px;
    font-weight:200;
    margin-left:15px;
    font-family:lato,'sans-serif';
    color:#020202;
  }
  .charityhead{
    padding-top:10px;
    text-align:center;
    color:#000000;
    font-weight:200;
  }
  
  label {
    color: rgb(39, 37, 37);
  }
  
  .options{
    padding-top:6px;
  }
  .headitem{
    background-color: #f0f8fa;
    padding: 10px 15px;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Lato', sans-serif;
    color: #0d1d37;
  }
  .options-dropdown{
    padding:20px;
    width:200px;
    margin-right:10px;;
  }
  .overview{
    padding:30px 0px;
  }
  
  .bannername
  {padding-top:20px;
  padding-left:210px;
  font-family: 'Arial-Rounded',sans-serif;
  font-size:50px;
  text-shadow: 2px 2px 8px #62b1d0;}
  
  .bannername1
  {padding-top:60px;
  padding-left:0px;
  font-family: 'Arial-Rounded',sans-serif;
  font-size:50px;
  text-shadow: 2px 2px 8px #62b1d0;}
  
  .productview{
    height:400px;
    
  }
  .navmain-item{
    color:#6b6b6d ;
    font-size:15px;
    font-weight:200;
  }
  .navmenu{
    margin-left:0px;
    margin-top:40px;
    background-color: #F2E700;
  }
  
 
  .navvyitem{
    background-color: rgb(255, 255, 255);
    border:1px solid rgb(255, 217, 0);
    padding:7px 5px;
     width:245px;
    text-align:center;
    -webkit-box-shadow: 0px 0px 11px 1px #bbbbbb;
    box-shadow: -1px 2px 8px 0px #e4dfdf;
  
  }
  
  .homestripitem1{
    background-color: rgb(255, 255, 255);
    border:1px solid rgb(255, 217, 0);
    padding:7px 5px;
   
    text-align:center;
    -webkit-box-shadow: 0px 0px 11px 1px #bbbbbb;
    box-shadow: -1px 2px 8px 0px #e4dfdf;
  
  }
  
  .white{
    background-color: rgb(255, 255, 255);
    -webkit-box-shadow: 0px 0px 11px 1px #bbbbbb;
    box-shadow: -1px 2px 8px 0px #e4dfdf;
  }
  
  
  .navvyitem1{
    background-color: rgb(255, 255, 255);
    padding:7px 5px;
    border:1px solid rgb(255, 230, 1);
     width:365px;
    text-align:center;
   
    -webkit-box-shadow: 0px 0px 11px 1px #bbbbbb;
    box-shadow: -1px 2px 8px 0px #e4dfdf;
  }
  
  .navvyitem:hover{
    cursor:pointer;
  }
  .navvyitem1:hover{
    cursor:pointer;
  }

  /* .only1productsection{
    background-image:url('../images/mainproban.png');
    box-shadow: 0px 0px 11px 1px #bbbbbb;
    padding-top:30px;
    padding-bottom:50px;
  } */
  .onlyproductsection1{
    background-color: #ffffff;
    box-shadow: 0px 0px 11px 1px #bbbbbb;
   
   
  }
  .pricecard{
  margin-top:30px;
  margin-bottom:40px;
  }
  .priceheaddiv{
    background-color: #104892;
    border-bottom:1px solid white;
    text-align:center;
    padding:10px 0px;
  }
  .pricehead1{
    color:white;
    font-size:30px;
   
  
  }
  .pricingprice1{
    color:white;
    font-size:27px;
   
  
  }
  .pricingcontent2{
    color:white;
    font-size:23px;
    
   
  
  }
  .aboutprice{
    padding-top:20px;
    text-align:center;
    background-color: #104892;
    padding-bottom:30px;
  }
  .arrow-down {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid rgb(255, 255, 255);
    margin-top: 0px;
    position: absolute;
    z-index: 3;
    opacity: 0;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    
  }
  .filebase > input{font-size: 14px;
  background-color: #f3f0f0;}
  
  .center-image{
    text-align: -webkit-center;
  }
  .guidebox{
    border:2px solid rgb(236, 233, 233);
    height:600px;
    overflow: scroll;
  }
  .cardstuff{
  
    border: 1px solid #ebe9e9;
      padding: 10px 10px 10px 10px;
      /* -webkit-box-shadow: 5px 6px 20px 0px #9494935c; */
      /* box-shadow: 5px 6px 20px 0px #9494935c; */
      margin: 25px 15px;
      background-color: white;
      height: auto;
  }
  
  
  .detail3{
    
    background-color: rgb(222, 237, 255);
    padding:17px 0px;
    
  }
  .detail4{
    
    background-color: #a1d6ff;
    padding:8px 0px;
    border-top:1px solid rgb(237, 237, 237);
    border-bottom:1px solid  rgb(237, 237, 237);
    
  }
  
  .detail5{
    
    background-color: rgb(212, 255, 143);
      
    padding:8px 0px;
    
  }
  .detail6{
    
    background-color: rgb(222, 237, 255);
    padding:8px 0px;
    border-top:1px solid  rgb(237, 237, 237);
    border-bottom:1px solid  rgb(237, 237, 237);
  }
  
   .imagestuff{
     padding : 2vw 0vw 2vw 0vw;
  
   }
   
   
   
  .navvvyitem{
    background-color: rgb(200, 222, 255);
    border:1px solid white;
    padding:12px 5px 6px 5px;
     width:245px;
    text-align:center;
   
  
  }
  
  .privatesection{
    margin-top: 40px ;
    margin-bottom:80px;
   
    
  }
  
  
  .privateback {
    margin-bottom: 50px;
    padding: 20px 20px 34px 20px;
    background-color: #f8f8f8;
  }
  .privatecard{
    margin-top:90px;
    
  }
  .privatecardhead{
    font-size:20px;
    font-weight:600;
    
  }
  .privateimage{
    height:120px;
    width:120px;
    border-radius:10px;
  }
  .privateicon1{
   margin-bottom: 20px  ;
   }
  .privateicon{
   padding:10px;
   border-radius:50px;
  margin:0px 145px 20px 145px ;
  }
  .logoconstruction{
    text-align:center;
  }
  
  .construction{
    margin-top:-80px;
    margin-bottom:30px;
  }
  .privatecard:hover >.privateicon{
    background-color: yellow;
  }
  .viewmore{
    margin-top:20px;
    width:180px;
  }
  .viewmore-section{
    padding-bottom:30px;
  }
 
  a{    color: #1b1b1b;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
  }
  
  
  
  
  
  
  .second1{
    text-align:left;
    border-left:3px solid #2379BC;
    padding-right:30px;
  }
  .second2
  {text-align:center;
  padding-right:30px;}
  
  .content1 {
    margin-top: 21px;
    font-size: 21px;
    color: #353535;
    font-weight: 300;
  }
  
  .content2 {
    margin-top: 21px;
    font-size: 18px;
    color: #353535;
    font-weight: 300;
  }
  .content6 {
    margin-top: 21px;
    font-size: 21px;
    color: #ffffff;
    font-weight: 200;
  }
  
  .content8 {
    margin-top: 21px;
    font-size: 16px;
    color: #3d3d3d;
    font-weight: 100 !important;
    
  
  }
  .content7{
    margin-top: 5px;
    font-size: 28px;
    color: #2b2b2b;
    font-weight: 200;
  }
  
  .content3 {
    margin-top: 14px;
    font-size: 27px;
    color: #353535;
    font-weight: 300;
  }
  .content5  {
    margin-top: 14px;
    font-size: 25px;
    color: #ffffff;
    font-weight: 200;
    
  }
  .content4{
    margin-top: 21px;
    font-size: 12px;
    color: #353535;
    font-weight: 200;
  }

  .dedicatedstuff{
    height:auto
  }
  
  
 
  
  .sidebuttondiv{
    text-align:center;
    padding-left:90px;
  }
  
  
  .buttondiv{
    cursor:pointer;    
  }
  
  
 
  .btnfilter{
    border:1px solid #e9e9e9;
  }
  
  .filtercontent{
    padding-top:8px;
  }
  .search-bar{
    padding-top:454px;
    padding-left:0px;
    padding-right:20px;
  }
  .blocksearch{
   
    width:40px;
    height:20px;
    padding:0px 14px 0px 4px;
  }
  .fa-search{
  height:14px;
  }
  
  
  
  
  
  
 
  
  .imagecredit {
    border: 1px solid #f5f1f1;
    padding: 30px 30px;
   
}
  
  .link{
    color:rgb(31, 30, 36)!important;
  }
  
  .testimonials1{
    margin-bottom:60px;
  }
  
  .docsec1{
    padding-top:90px;
  }
  
  .customers24 {
    padding:40px 0px 40px 0px;
  }
  
  @media (max-width: 768px) {
    .body{
      overflow-x: hidden;
      width: 100%;
    }
  
  
    .carousel-item {
      margin-top:0px;
      height: 55vh;
      min-height: 400px;
      background: no-repeat center center scroll;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      -webkit-transition: property duration timing-function delay;
      -moz-transition: property duration timing-function delay;
      transition: property duration timing-function del;
    }
  
  .bannername{
  
    padding-top: 190px;
      padding-left: 5%;
      font-family: 'Arial-Rounded',sans-serif;
      font-size: 42px;
      text-shadow: 2px 2px 8px #62b1d0;
  
  }
  .fa
  {padding:18px !important;
  }
   .mobilerow1{
     padding-left:10%;
     padding-right:0%;
     padding-bottom:5px;
     padding-top:5px;
   }
   .mobilerow9{
   
      padding-left: 0%;
      padding-right: 0%;
      padding-bottom: 5px;
      padding-top: 8px;
      text-align: center;
      margin-left: -60px;
      margin-right: -70px;
  
  }
   .mobilerow5{
    padding-left:0%;
    padding-right:0%;
    padding-bottom:5px;
    padding-top:5px;
    margin-bottom:20px;
  }
  .mobilerow3{
    padding-left:3%;
  }
   
  .mobilerow4 {
    padding-left: inherit;
  }
   
   
  .mobilerow7{
    padding-left:17%;
    padding-right:0%;
  }
  .mobilerow6{
    padding-left:20%;
    padding-right:20%;
  }
  .mobilerow8{
    padding-left:2%;
    padding-right:0%;
    padding-bottom:40px;
    padding-top:5px;
  }
   .mobilerow2{
     padding-left:18%;;
   }
   .mobilerow10{
    padding-left:15%;
  }
  .mobile11{
    padding-left:27%;
    padding-right:30%;
  }
   .mobilerow{
    padding-left:21%;
  }
  
  .docban{
    padding-top:10px !important;
   }
  .docblock{
    padding:10px 40px 20px 4px !important;
  }
  
  .docban2{
    padding-top:106px !important;
    
  }
  
  .docblock1{
    padding:10px 40px 20px 40px !important;
    
  }
  
   
    .heading15 {
      color: #ffffff !important;
      font-weight: 600 !important;
      font-size: 22px !important;
      font-family: 'Lato', sans-serif;}
    
    .oppobanner{
      
      margin:0px 0px;
    }
    .opposec {
      font-size: 45px;
      font-family: 'Showcard gothic', sans-serif;
      font-weight: 600;
      color: #88ff00;
      margin-left: 0px;
      margin-top: 30px;
      transition:all 0.3s ease;
    }
    .platformcontent {
      padding-top: 0px;
      font-family: 'Lato', sans-serif;
      color: #ffffff;
      font-size: 28px;
    }
    .waitstyle {
      margin: 100px 0px -60px 50px;
      font-weight: 700;
      color: #2c2c2c;
    }
    .charimage{
      text-align:center;
  
    }
  
    .charitybox{
      height:auto;
    }
    .navbar-brand {
      left: 0px;
      font-size: 24px;
    
      width: 48%;
      font-weight: 600;
  
     
    }
  
    .other-navbar-mobile{
      width: 75vw !important;
      
    }
  
    .dropdown:hover .dropdown-menu {
    
      display: block;
      margin-top: 27rem;
      margin-left: -15rem;
  
  }
  
    
    .productssec{
      background-image: none;
    }
    
    .link2 {
      background-color: #000932;
      color: #ffffff !important;
      padding: 40px 20px 40px 0px;
      transition:all 0.5s ease;
      text-align:center;
    }
    /* .link2:hover{
      background-image:url('../images/pricinghover.png');
      color:#000000 !important;
     
    } */
    .headingpricing{
      margin-left:0%;
    }
    
   .firstcol1{
     display:block;
     padding-left:0%;
  
   }
    .expertrow{
      padding-left:15%;
     
        padding-top:20px;
     
    }
    .mainbutton{
     
      width:280px;
       background-color:#f7e92a;
        border:none;
        color:#333231;
    }
    .mainbutton:hover{
     
      width:280px;
       background-color:#f7e92a;
        border:none;
        color:#3743e9;
    }
    /* .askb{
      width:150px;
      height:60px;
      background-image: url('../images/phoneicon2.png');
    
    } */
    /* .askb:hover{
      width:150px;
      height:60px;
      background-image: url('../images/phoneicon1.png');
    } */
   .abouthead{
     margin-left:-36%;
     font-size: 18px;
     font-weight:600 ;
     font-family:lato,'sans-serif';
   }
   
   .aboutcontent1{
    margin-left:-33%;
    font-weight:400;
    padding:10px;
   }
   
   .mobile-menu-icon{
  
    width: 21%;
    margin-left: 0 !important;
    margin-right: -18px;
    list-style: none !important;
  
   }
   .map{
     margin-left:-10%;
   }
   .requestmap{
     padding:10px;
   }
  
   .request{
    padding:175px 10px 20px 10px !important;
  }
   /* .askb{
    width:195px;
    height:46px;
    background-image: url('../images/askmobile.png');
  
  } */
  /* .askb:hover{
    width:195px;
    height:46px;
    background-image: url('../images/askmobile.png');
  } */
  .sec3 {
    padding: 20px 50px 20px 68px;
    height: 220px;
    font-family: 'Lato', sans-serif;
    color: #6b7070;
  }
  
  .line1{
    color:white;
  
  }
  .line2{
    color:white;
    
  }
  .body{
    overflow-y:scroll;
    height: 100vh;
  }
  .line3{
    color:white;
    
  }
  .pricingcontent {
    color: #fffdfd;
    font-size: 18px;
    font-weight: 600;
    padding: 4px;
    width: auto;
    font-family: lato, ' sans-serif';
    text-align: center;
  }
  .pricingprice {
    color: #f5f4f4;
    font-size: 27px;
    padding: 5px 0px 0px 0px;
    font-weight: 700;
    font-family: lato, ' sans-serif';
    width: auto;
    text-align: center;
    border-bottom: white;
  }
  .accordion-btn {
    background-color: rgb(234, 244, 250);
    color: rgb(31, 31, 31);
    cursor: pointer;
    padding: 18px;
    
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: 0.4s;
    border: 1px solid #ffffff;
  }
  .pricing {
    height: 700px;
    margin-left:-20px;
  }
  .request{
    padding:40px 10px;
  }
  
  .line1{
    height: 0px;
    width: 0px;
    background-color: #ffffff;
    /* top: 1px; */
    margin-top: 73px;
    transition:all 0.5s ease;
    border:none;
    border-color:white;
    padding:none;
    
  }
  .line2 {
    height: 0px;
    width: 0px;
    background-color: #ffffff;
    /* top: 1px; */
    margin-top: 73px;
    transition:all 0.2s ease;
    border:none;
    border-color:white;
  }
  .line3 {
    height: 0px;
    width: 0px;
    background-color: #ffffff;
    /* top: 1px; */
    margin-top: 73px;
    transition:all 0.2s ease;
    border:none;
    border-color:white;
  }
  .soc{
    margin-bottom:20px;
    height:300px;
  }
  .textfields{
    width:180px;
  }
  .textfields1{
    width:157px;
  }
  .mysec {
    padding: 10px 25px 30px 25px;
  }
  .title{
    padding-bottom:10px;
  }
  .dialogue1{
    margin-top:40px !important;
    width:90% !important;
  }
  .dialogue2{
    margin-top:380px !important;
    width:90% !important;
  }
  .textblock {
    margin-top: 13%;
    padding: 26px;
    background-color: rgba(55, 185, 255, 0.479);
    /* height: 280px; */
    border-radius: 5px;
    width: 100%;
    color: #050505;
  }
  
  .textblock2 {
    margin-top: 10%;
    padding: 40px;
    background-color: rgba(237, 255, 159, 0.542);
    height: 280px;
    border-radius: 5px;
    width: 50%;
    font-size: 22px;
    color: #333333;
  }
  .car1 {
    margin-top:110px;
    height: 820px !important;
  }
  .car2 {
    padding-top:100px;
    height: 700px !important;
  }
  .car4 {
    padding-top:10px;
    height: 520px !important;
  }
  .fa{
    padding:10px;
  }
  .car8 {
    margin-top:160px;
    height: 360px !important;
  }
  .car5 {
    padding-top:0px;
    height: 980px !important;
  }
  mobile{
    display:block !important;
  }

  .incorporatepricing {
    padding-top:210px;
    height: auto;
    padding-bottom:40px;
  }
  .complypricing {
    padding-top:210px;
    height: auto;
    padding-bottom:40px;
  }
  .protectpricing {
    padding-top:210px;
    height: auto;
    padding-bottom:40px;
  }
  .taxpricing {
    padding-top:210px;
    height: auto;
    padding-bottom:40px;
  }
  .windpricing {
    padding-top:210px;
    height: auto;
    padding-bottom:40px;
  }
  .accordion__body{
    padding-bottom: 10px;
    opacity:1;
    z-index: 1;
  }
  .boxcor {
    border: 2px solid #ffffff;
    border-radius: 10px;
    margin: 0px 15px;
    padding: 30px 5px 0px 5px;
    background-color: #ffffff;
    box-shadow: -6px 7px 12px 1px #6da3f9;;
   
  }
  .boxsocial {
    border: 2px solid #e6e5f4;
    border-radius: 10px;
    margin: 0px 0px 0px 13px;
    padding: 20px 5px 14px 5px;
    background-color: #ffffff;
    box-shadow: -5px 2px 20px 0px #85bddc;
    height: 150px;
    font-size: 12px;
  }
  .textblock1 {
    padding: 5px 0px 25px 38px;
    margin-bottom: 50px;
  }
  
  .textblock5 {
    padding: 5px 0px 25px 38px;
    margin-bottom: 50px;
  }
  
  .iconrow2 {
    padding-top: 10px;
    padding-left:12%;
  }
  .iconrow {
    padding-top: 10px;
    padding-left:12%;
  }
  .car3{
    margin-top:98px;
    height:920px;
  }
  
  
  .heading3 {
    color: #2b2b2b;
    font-weight:700;
    font-size: 24px;
    font-family: 'Lato', sans-serif
  }
  
  .iconborder1 {
    margin: 30px 20px 30px 90px !important;
    border-radius: 7px;
    -webkit-box-shadow: 5px 8px 20px 10px #cfcfcf;
    box-shadow: 5px 8px 20px 10px #cfcfcf;
    background-color: rgb(29, 29, 29);
    opacity: 1;
    height:140px !important;
    width:160px !important;
   
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  /* .ask {
    background-image: url('../images/askbannermobile.png');
    box-shadow:2px 1px 4px 0px #888888;
  } */
  
  .dialogue {
    /* padding: 10px 10px 0px 10px; */
    padding:20px 10px 30px 10px;
    border:8px solid white;
    background-color:rgb(255, 255, 255);
    width:90%;
    border-radius:5px;
    margin-top:330px;
    box-shadow: 3px 1px 20px 0px #16648598;
  
  }
  
  
    .imagecorp {
      border: 2px solid #ffffff;
      border-radius: 10px;
      margin: 0px 10px;
      padding: 40px 5px 5px 5px;
      background-color: #ffffff;
      -webkit-box-shadow: -6px 7px 12px 1px #6da3f9;
      box-shadow:-6px 7px 12px 1px #6da3f9;
  }
  
    .imagecorp4 {
      border: 2px solid #ffffff;
      border-radius: 10px;
      margin: 0px 4%;
      padding: 30px 5px 0px 5px;
      background-color: #ffffff;
      
      box-shadow: none !important;}
  .hexagon2{
    position: relative;
    width: 150px; 
    height: 86.60px;
    background-color: rgb(254, 255, 255);
    margin: 43.30px 10px 43.30px 0px;
    box-shadow: 0 0 20px rgba(0,0,0,0.2);
    margin-right:20%;
    margin-left:2%;
  }
  
  .hexagon2:before,
  .hexagon2:after {
    content: "";
    position: absolute;
    z-index: 1;
    width: 106.07px;
    height: 106.07px;
    -webkit-transform: scaleY(0.5774) rotate(-45deg);
    -ms-transform: scaleY(0.5774) rotate(-45deg);
    transform: scaleY(0.5774) rotate(-45deg);
    background-color: inherit;
    left: 21.9670px;
    box-shadow: 0 0 20px rgba(0,0,0,0.2);
  }
  
  .hexagon2:before {
    top: -53.0330px;
  }
  
  .hexagon2:after {
    bottom: -53.0330px;
  }
  
  /*cover up extra shadows*/
  .hexagon2 span {
    display: block;
    position: absolute;
    top:0px;
    left: 0;
    width:150px;
    height:86.6025px;
    z-index: 2;
    background: inherit;
  }
  
  
  .hexagon2:hover .pin{
    transform:rotateX(170deg);
  }
  .offercontent {
    padding:10px 10px;
    color: #2e2e2e;
    font-size:14px;
    font-family:'lato', sans-serif;
    font-weight:200;
    margin-top:-15px;
  }
  .hexagon2:hover .offercontent{
    padding: 10px 10px;
    color: #ffffff;
    font-size:16px;
    font-family:'lato', sans-serif;
    font-weight:600;
    margin-top:-15px;
  }
  
  .offercontent1 {
    padding:10px 10px;
    color: #2e2e2e;
    font-size:14px;
    font-weight:100;
    margin-top:-11px;
  }
  
  .hexagon2:hover .offercontent1 {
    padding:10px 10px;
    padding: 4px;
    color: #ffffff !important;
    font-size:16px;
    font-family:'lato', sans-serif;
    font-weight:600;
    margin-top:-11px;
  }
  
  
  
  .hexagon2 {
    position: relative;
    width: 150px;
    height: 86.60px;
    background-color: rgb(254, 254, 255);
    margin: 43.30px 0;
    box-shadow: 0 0 20px rgba(80, 80, 80, 0.4);
    transition:all 0.2s ease;
  }
  .hexagon2:hover
  { color:#fafafa;
    transform: scale(1.0);
    background-color: rgb(37, 37, 37);
    cursor:pointer;
  }
  .whymobile{
    margin-right:20px;
  }
  .button13{
    margin-top: 48px;
    background-color:rgb(17, 55, 84) !important;
    /* Green */
    border: 2px solid rgb(255, 255, 255);
    padding: 14px 16px 8px 16px;
    text-align: center !important;
    display: inline-block;
    font-size: 12px;
    font-weight: 900;
    border-radius: 3px;
    margin-left:10px;
    width:180px;
    
    
    font-family: 'Lato', sans-serif;
    transition: all 0.2s ease;
    
  
  }
  
  #whatwedoterms{
    margin-left:2px;
  }
  .footrow{
    padding-bottom:20px;
  }
  .mobilepro{
    margin-left:-73px;
    margin-right:45px;
  }
  .iconborder1 {
    margin: 30px 10px 30px 10px;
    border-radius: 7px;
    -webkit-box-shadow: 5px 8px 20px 10px #cfcfcf;
    box-shadow: 5px 8px 20px 10px #cfcfcf;
    background-color: rgb(29, 29, 29);
    opacity: 1;
    height: 140px;
    width: 170px;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  
  .iconborder1:hover {
    transform: scale(1.2);
  }
  .iconrowmob {
    padding-top: 80px;
    padding-left:8%;
  }
  .iconrowmob2 {
    padding-top: 10px;
    padding-left:8%;
  }
  .banimage {
    padding: 25px 0px 25px 0px;
  }
  .bantext {
    color: #ffffff;
    font-size: 16px;
    font-family: 'Lato', sans-serif;
    padding-top: 8px;
   
    margin-left: -8px;
  }
  
  .dialoguetext {
    font-family: 'Lato', sans-serif;
    color: #888888;
    font-size:18px;
    padding-left:5px;
  
   
  }
  .heading15 {
    color: #ffffff !important;
    font-weight: 600 !important;
    font-size: 17px !important;
    font-family: 'Lato', sans-serif;
  }
  .head {
    color: #ffffff;
    padding-top: 70px;
    padding-left: 20px;
    font-family: 'Lato', sans-serif;
  }
  .navvyitem{
    background-color: #F2E700;
    padding: 7px 5px;
    border: 1px solid white;
    width: 48%;
    text-align: center;
   
  
  }
  .navvyitem1{
    background-color: #F2E700;
      padding: 7px 5px;
      border: 1px solid white;
      width: 48%;
      text-align: center;
   
  
  }
 
  
  
  }
  
  
  .modal-box{
    padding:30px;
  }
  
  .modal-field{
    margin-left:30px;
    margin-top: 10px;
    width: 400px;
    margin-bottom:10px;
  }
  .modal-button{
    margin-left:90px;
    width:60%;
    background-color: blue;
    margin-bottom:30px;
    color:white;
  }
  
  .modal-button:hover{
   cursor:pointer;
  }
  .modal-head{
    margin-top:20px;
    margin-left:50%;
    
     
  }
  
  .step-form-wrapper-box {
    width: 102vw;
    left: -1%;
    position: fixed;
    top: 5%;
    height: 102vh;
    border-radius: 5%;
  }
  
  
  .close-step-form{
    position: absolute;
  top: 20px;
  cursor: pointer;
      right: 35px;
      background: #ffffff;
      color: #212529;
      border: none;
      border-radius: 10px;
      box-shadow: 4px 4px 2px #3838388c;
      transition: all 0.3s ease-in-out;
  }
  .close-step-form:hover{
    position: absolute;
  top: 20px;
  cursor: pointer;
      right: 35px;
      background: #ffc107;
      color: #212529;
      border: none;
      border-radius: 10px;
      box-shadow: 4px 4px 2px #3838388c;
  }
  
    .progress {
      overflow: visible;
      margin-bottom: 26px;
      height: 7px;
      width: 88%;
    }
    .progress .progress-bar {
      background-color: #ffc107;
      position: relative;
      height: 7px;
      border-radius: 4px;
    }
    .progress .progress-bar span {
      background-color: #ffc107;
      position: absolute;
      bottom: -26px;
      font-size: 15px;
      line-height: 9px;
      padding: 5px 2px 5px 7px;
      right: -1.4em;
      border-radius: 2px;
    }
    .progress .progress-bar span:after {
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: #ffc107;
      border-width: 5px;
      margin-left: -5px;
    }
    
    .subscriptt{
      color: #ffc107;
    }
  
    .cardinfo{
    background-color:rgb(255, 255, 255);
    padding:20px 5px;
    border-radius:20px;
    margin-bottom:10px;
    }
    .cardinfotext{
    color:rgb(34, 34, 34) !important;
    font-size:15px;
    }
  
  