/* Basic styles */

.skeleton{
    background: #ddd;
    margin: 10px 0;
    border-radius: 4px;
}

.skeleton.sk-text{
    width: 100%;
    height: 12px;
    position: relative;
}

.skeleton.title{
    /* align-content: center; */
    margin-left: 25%;
    width: 50%;
    height: 20px;
    margin-top: 20px;
    margin-bottom: 60px;
    position: relative;
}

.skeleton.title-ob{
    width: 50%;
    height: 20px;
    position: relative;
}

.skeleton.avatar{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: relative;
}

.skeleton.thumbnail{
    width: 100px;
    height: 100px;
    position: relative;
}

.skeleton-para{
    margin-bottom: 35px;
}

/* profile and other blog */

.skeleton-profile{
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 30px;
    align-items: center;
    /* position: relative; */
}


/* theme */

.skeleton-wrapper.light{
    background: #f2f2f2;
}

.skeleton-wrapper{
    margin: 15px auto;
    padding: 10px 15px;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
}

/* animation effect */

.shimmer-wrapper{
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
    animation: loading 2.5s infinite;
}

.shimmer{
    width: 50%;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    transform: skewX(-20deg);
    box-shadow: 0 0 30px 30px rgba(255, 255, 255, 0.05);
}

@keyframes loading{
    0% {transform: translateX(-780%);}
    /* 50% {transform: translateX(-60%);} */
    100% {transform: translateX(780%);}
}

/* css for legal cards */

.skeleton.legal-title{
    width: 85%;
    height: 20px;
    margin-top: 10px;
    margin-bottom: 18px;
    position: relative;
}

.skeleton-legal{
    margin-bottom: 1%;
}

.skeleton-wrapper-legal{
    margin: auto;
    padding: 5px 15px;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
}