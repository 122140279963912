

.navmain-item{
    color:#6b6b6d ;
    font-size:15px;
    font-weight:200;
  }
  
  .nav-item{
    font-size:16px;
  }
  
  .firstbut{
    margin-left:50px;
  }
  .secondbut{
    margin-left:50px;
  }
    .Navbar1{
     
      margin-top:-2px;
      padding-bottom:0px;
     padding-top:0px;
     height:44px;
     margin-left: 30px;
    }
  
    .user-icon{
      padding-top:0px !important;
      padding-right:7px !important;
      padding-left:4px !important;
  
    }
    .input-group{
      height:34px;
    }
    .fixed-top1 {
      position: fixed;
      top: 0px;
      height:37px;
      right: 0;
      left: 0;
      z-index: 1030;
      background-color:#f5f5f5;
    }
    
    .input-group-btn{
      border:1px solid rgb(202, 202, 202);
    }
    .here {
      border-top: 4px solid #a52534;
      border-bottom: 1px solid rgb(243 242 242);
  }
    
  
    .glyphicon-search{
      margin-right:20px;
      color:black;
    }
    
  
    .heading28{
      font-family:'lato',sans-serif;
      font-size:15px;
      color:rgb(255, 255, 255);
      margin-right:10px;
      padding:0px;
      margin-left:10px;
    }
  
  
    .filter{
    
      font-size: 14px;
      color:#919AA2;
      margin-bottom:20px;
      padding: 0px 10px 17px 10px;
    }
  
    .input-group-btn:not(:last-child)>.btn, .input-group-btn:not(:last-child)>.btn-group {
      margin-right: -1px;
      padding: 4px 10px 17px 10px;
      font-size: 14px;
      color:#919AA2 !important;
      margin-bottom:0px;
  }
  .a{
    text-decoration: none;
  }
  .opt{
    margin-right: -1px;
      padding:0px 0px 0px 10px;
      font-size: 14px;
      color:#919AA2 !important;
  }
  .fa-search{
    padding:0px 0px 29px 0px !important;
    color:rgb(145, 145, 145);
    margin-bottom:10px;
  }

  .loginOpt{
    margin-top: 26px;
    font-size: 15px;
  }

  .loginButton{
    margin-left: 4%;
    margin-top:7px;
    font-size: 15px;
  }

  @media screen and (max-width:450px) {
    .loginButton>a{
      font-size: 0.9em;
    }
    .loginButton{
      margin-left: 0;
      margin-top: 0;
    }
    .heading30{
      margin-left: -9vw;
      width: 45vw;
    }

    .dropdown-menu{
      min-width: 86px;
    }

    .dropdown-content{
      margin-top: -5vh;
      margin-left: 20vh;
    }

    .dropdown-content .navmain-item{
      font-size: 0.9em;
    }
  }