body{
  font-family: Arial, Helvetica, sans-serif;
}

.react-scrollbar-default {
    width: 35%;
    min-width: 300px;
    max-height: 450px;
  }

  .mainbody {
    padding-top: 0px !important;
    overflow-x: hidden !important;
    margin-top:-8px;
    background-color: white;
  }
  .highlight{
    /* color: black !important; */
    margin-bottom: 0vh;
    width: auto;
    height: 7vh;
    margin-right: 14px;
    margin-top: 2px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 3px;
    font-family: lato,"sans-serif";
    padding-right: 7px !important;
    padding-left: 7px !important;
  }

  .highlight-article{
    color: #777777 !important;
    margin-bottom: 0vh;
    width: auto;
    height: 7vh;
    margin-right: 14px;
    margin-top: 2px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 3px;
    font-family: lato,"sans-serif";
    padding-right: 7px !important;
    padding-left: 7px !important;
  }

  @media only screen and (min-width:1920px){
    .highlight{
      /* color: white !important; */
      margin-bottom: 0vh;
      width: auto;
      height: 4vh;
      margin-right: 14px;
      margin-top: 2px;
      font-size: 15px;
      border-radius: 3px;
      font-family: lato,"sans-serif";
      padding-right: 7px !important;
      padding-left: 7px !important;
    }
  }

  @media only screen and (min-width:2304px){
    .highlight{
      /* color: white !important; */
      margin-bottom: 0vh;
      width: auto;
      height: 3vh;
      margin-right: 14px;
      margin-top: 2px;
      font-size: 15px;
      border-radius: 3px;
      font-family: lato,"sans-serif";
      padding-right: 7px !important;
      padding-left: 7px !important;
    }
  }

  .highlight1{
    color:#404040 !important;
  }
  .highlight2{
    color:#404040 !important;
    margin-top: 0px;
    font-family: lato,"sans-serif";
    font-size: 16px;
    font-weight: 500;
  }
  /*The Content*/
  .scroll-me {
    min-width: 750px;
  }
  .imagex{
    margin-left: 0vw;
    width: 153%;
    height: 64vh;
  }

  #readonly{
    margin-top:33px;
    font-family:helvetica neue;
  }

  .image{
    margin-left:12px;
    width: 12vw;
    height: 18vh;
    border-radius: 5px; 
  }

.footer-style{
    width: 100vw;
}
.back{
    background-color: white;
}

.article-style {
  border: 1px solid rgb(243 239 239)  !important;
  background-color: white;
  border-radius: 10px; 
  margin: 15px 0px 22px 0px;
  padding: 10px 20px;
  width: 55vw;
  height: 220px;
}

@media only screen and (min-width:1920px){
  .article-style {
    border: 1px solid rgb(243 239 239)  !important;
    background-color: white;
    border-radius: 10px; 
    margin: 15px 0px 22px 0px;
    padding: 10px 20px;
    width: 55vw;
    height: auto;
}
}

@media only screen and (min-width:2304px){
  .article-style {
    border: 1px solid rgb(243 239 239)  !important;
    background-color: white;
    border-radius: 10px; 
    margin: 15px 0px 22px 0px;
    padding: 10px 20px;
    width: 55vw;
    height: auto;
}
}

@media only screen and (min-width:3840px){
  .article-style {
    border: 1px solid rgb(243 239 239)  !important;
    background-color: white;
    border-radius: 10px; 
    margin: 15px 0px 22px 0px;
    padding: 10px 20px;
    width: 55vw;
    height: auto;
}
}
.dedicatedstuff {
  position: relative;
  border: 1px solid #f5f1f1;
  padding: 40px 17px 20px;
  margin: -5px 0;
}

.heading-case{
    padding-top: 0%;
    padding-bottom: 1%;
    margin-top:50px;
    font-size: 28px;
    font-weight: bold;
    font-family: 'Helvetica', sans-serif;
    text-align: center;
}

.heading-case1{
  margin-top: 12vh;
  padding:30px 150px 0px 150px;
  font-size: 22px;
  font-weight: bold;
  font-family: 'Helvetica', sans-serif;
  /* text-align: center; */
}
.subheading-case {
  padding-bottom: 1%;
  font-weight: 600;
  font-size: 15px;
  font-family: 'Source-sans-pro', sans-serif;
  /* text-align: center; */
}

.subhead {
  font-size: 15px;
  font-family: 'lato', sans-serif;
  font-weight: 400;
  color: #093146;
  padding-top: 6px;
  font-stretch: 1px;
  padding-left: 5px;
}

.breadcrumb li {
  display:inline;
  margin-right: 10px;
  
  }
  
  
  .margin-5{
  margin-left: -10px;
  }
  
  :root {
  --breadcrumb-theme-1: #0065a0;
  --breadcrumb-theme-2: #fff;
  --breadcrumb-theme-3: #0065a0;
  --breadcrumb-theme-4:  #0065a0;
  }
  .breadcrumb {
  z-index: -2;
  text-align: center;
  display: inline-block;
  box-shadow: 0 2px 5px rgba(219, 219, 219, 0.25);
  overflow: hidden;
  background-color: #f3f3f3;
  border-radius: 0px;
  counter-reset: flag;
  }
  .breadcrumb__step {
  text-decoration: none;
  outline: none;
  display: block;
  float: left;
  font-size: 12px;
  line-height: 20px;
  padding: 0 10px 0 20px;
  position: relative;
  background: none;
  color: #45a296;
  transition: background 0.5s;
  }
  .breadcrumb__step:first-child {
  padding-left: 15px;
  border-radius: 5px 0 0 5px;
  }
  .breadcrumb__step:first-child::before {
  left: 14px;
  }
  .breadcrumb__step:last-child {
  border-radius: 0 5px 5px 0;
  padding-right: 20px;
  }
  .breadcrumb__step:last-child::after {
  content: none;
  }
  /* .breadcrumb__step::before {
  content: counter(flag);
  counter-increment: flag;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  line-height: 20px;
  margin: 8px 0;
  position: absolute;
  top: 0;
  left: 30px;
  font-weight: bold;
  background: var(--breadcrumb-theme-2);
  box-shadow: 0 0 0 1px var(--breadcrumb-theme-1);
  } */
  .breadcrumb__step::after {
    content: '';
    position: absolute;
    top: 3px;
    right: -6px;
    width: 14px;
    height: 15px;
    transform: scale(0.707) rotate(
45deg
);
    border-radius: 0 5px 0 50px;
    background: none;
    transition: background 0.5s;
    box-shadow: 2px -2px 0 2px #45a296;
}
  .breadcrumb__step--active {
  color: var(--breadcrumb-theme-2);
  background: var(--breadcrumb-theme-1);
  }
  .breadcrumb__step--active::before {
  color: var(--breadcrumb-theme-1);
  }
  .breadcrumb__step--active::after {
  background: var(--breadcrumb-theme-1);
  }
  .breadcrumb__step:hover {
  color: var(--breadcrumb-theme-2);
  background: var(--breadcrumb-theme-3);
  }
  .breadcrumb__step:hover::before {
  color: var(--breadcrumb-theme-1);
  }
  .breadcrumb__step:hover::after {
  color: var(--breadcrumb-theme-1);
  background: var(--breadcrumb-theme-3);
  }
.content-case{
    padding:20px 15px 0px 5px;
    font-size: 16px;
    font-weight: 400;
    font-family:'Source Sans Pro',sans-serif; 
    text-align: left;
    margin-bottom: 20vh;
}

.second-subhead {
  text-align: left;
  font-size: 14px;
  padding-left: 10px;
  font-family: 'Source Sans Pro',sans-serif;
}
.second-head {
  color: black !important;
  font-weight: 800;
  font-size: 15px;
  margin-bottom: 6px;
  padding: 0px 10px;
  text-align: left;
  font-family: 'Source Sans Pro',sans-serif;
}


.bottom-line{
  width: 56vw;
  background-color: #a52534;
  margin-left: -14px;
  margin-bottom: 2vh;
  border-radius: 3px;
}
/*resource-page-css*/
.resourcepage-line{

    background-color: #A52534;
    margin-left: -3px;
    margin-right: -3px;
    margin-top: -41px;
    border-radius: 0px;
    padding: 4px 0;
}

.fa-print{
  color:white;
  margin-top:6px;
}

.fa-copy{
  color:white;

}

.fa-bookmark{
  color:white;
}
.fa-share-alt{
  color:white;
}
#divcontents{
  padding:26px;
}

.glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: "Glyphicons Halflings";
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  color:white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media only screen and (min-width:'2560'){
  .resourcepage-line{
   
      background-color: #A52534;
      margin-left: -3px;
      margin-top: -41px;
      border-radius: 5px;
  }
}

.bottom-line1 {
  width: 898px;
  background-color: #A52534;
  margin-top: -15px;
  margin-left: -15px;
  /* box-shadow: 1px 3px 7px 1px #e1e0e0; */
  border-radius: 3px;
  position: absolute;
  z-index: 70000;
}

.bottom-line2 {
  width: 100%;
  background-color: #A52534;
  margin-top: -16px;
  margin-left: -15px;
  padding-top: 5px;
  /* box-shadow: 1px 3px 7px 1px #e1e0e0; */
  border-radius: 3px;
  position: absolute;
  height: 5vh;
  z-index: 99999;

}
.fas-color{
  color: #9396a5!important;
}

.fas-font-s{
  font-size: 48px !important;
}

.modal-header1 {
  height: 648px;
  overflow-y: scroll;
  padding: 15px;
}

.modal-body{
    position: relative;
    padding: 0px 39px;
}

.modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #999;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: 6px;
  box-shadow: 0 3px 9px rgb(0 0 0 / 50%);
  outline: 0;
  
  overflow-x: hidden;
  overflow-y: hidden;
  height: 89vh;
}


.modal-content-main {
 
  overflow-x: hidden;
  overflow-y: auto;
  height: 87vh;
}

.modal-header {
  padding: 15px;
  border-bottom: none !important;
}

@media only screen and (min-width:1600px){
  .bottom-line1{
    width: 55vw;
    background-color: #A52534;
    margin-bottom: 2vh;
    box-shadow: 1px 3px 7px 1px #e1e0e0;
    border-radius: 3px;
  }
}

@media only screen and (min-width:1920px){
  .bottom-line1{
    width: 45vw;
    background-color: #A52534;
    margin-bottom: 2vh;
    box-shadow: 1px 3px 7px 1px #e1e0e0;
    border-radius: 3px;
  }
}

@media only screen and (min-width:2304px){
  .bottom-line1{
    width: 38vw;
    background-color: #A52534;
    margin-bottom: 2vh;
    box-shadow: 1px 3px 7px 1px #e1e0e0;
    border-radius: 3px;
  }
}

@media only screen and (min-width:2560px){
  .bottom-line1{
    width: 35vw;
    background-color: #A52534;
    margin-bottom: 2vh;
    box-shadow: 1px 3px 7px 1px #e1e0e0;
    border-radius: 3px;
  }
}

@media only screen and (min-width:3000px){
  .bottom-line1{
    width: 29vw;
    background-color: #A52534;
    margin-bottom: 2vh;
    box-shadow: 1px 3px 7px 1px #e1e0e0;
    border-radius: 3px;
  }
}

@media only screen and (min-width:3840px){
  .bottom-line1{
    width: 22vw;
    background-color: #A52534;
    margin-bottom: 2vh;
    box-shadow: 1px 3px 7px 1px #e1e0e0;
    border-radius: 3px;
  }
}
.bookmark1{
  cursor: pointer;
  float: right;
  margin: 2px 14px 0px 0;
  color: #8d8d8d;
  font-size: 20px;
}
.bookmark{
  cursor: pointer;
  float: right;
  margin: 0 2vw 0 0;
  color: #8d8d8d;
  font-size: 20px;
}

.bookmark2{
  cursor: pointer;
  float: right;
  margin: 2px 2vw 0 0;
  color: #8d8d8d;
  font-size: 20px;
}

@media only screen and (min-width:1920px){
  .bookmark{
    cursor: pointer;
    float: right;
    margin: 2px 2vw 0 0;
    color: #8d8d8d;
    font-size: 20px;
    margin-top: 7px;
  }
}

@media only screen and (min-width:2304px){
  .set_margin{
    margin-left: 39vw !important;
  }
}

.set_margin{
  margin-left: 37vw;
}

.set-resmargin{
  margin-left: 2ve;
}

.copy{
  cursor: pointer;
  float: right;
  margin: 0 2vw 0 0;
  color: #8d8d8d !important;
  font-size: 20px !important;  
}
.copy2{
  cursor: pointer;
  float: right;
  margin: 2px 2vw 0 0;
  color: #8d8d8d !important;
  font-size: 20px !important;  
}
.copy1 {
  cursor: pointer;
  float: right;
  margin: 2px 20px 0 0;
  color: #8d8d8d !important;
  font-size: 20px;
}

.print{
  cursor: pointer;
  float: right;
  margin: 2px 36vw 0 0;
  color: #8d8d8d !important;
  font-size: 20px !important;  
}

@media only screen and (min-width:2880px){
  .print{
    cursor: pointer;
    float: right;
    margin: 2px 39vw 0 0;
    color: #8d8d8d !important;
    font-size: 20px !important;  
  }
}

@media only screen and (min-width:2560px){
  .print{
    cursor: pointer;
    float: right;
    margin: 2px 38vw 0 0;
    color: #8d8d8d !important;
    font-size: 20px !important;  
  }
}

.modalprint{
  cursor: pointer;
  float: left;
  margin-top: 2px ;
  margin-right: 1%;
  color: #8d8d8d !important;
  font-size: 20px !important;  
}

.printresource{
  cursor: pointer;
  float: left;
  margin-right: 1%;
  color: #8d8d8d !important;
  font-size: 20px !important;  
}
@media only screen and (min-width:1600px)
{  .printresource{
  cursor: pointer;
  float: right;
  margin: 2px 42vw 0 0;
  color: #8d8d8d !important;
  font-size: 20px !important;  
  }}

.share1{
  cursor: pointer;
  float: right;
  margin: 2px 1vw 0 0;
  color: #8d8d8d !important;
  font-size: 20px !important;  
}
.share{
  cursor: pointer;
  float: right;
  margin: 0 1vw 0 0;
  color: #8d8d8d!important;
  font-size: 20px!important; 
}
.share2{
  cursor: pointer;
  float: right;
  margin: 1px 2vw 0 0;
  color: #8d8d8d!important;
  font-size: 20px!important;
}

@media only screen and (min-width:1920px){
  .share{
    cursor: pointer;
    float: right;
    margin: 2px 2vw 0 0;
    color: #8d8d8d !important;
    font-size: 20px !important; 
    margin-top: 7px 
  }
}

.shareall1 {
  margin-top: -35vh;
  position: absolute;
  background-color: #f9f9f9;
  min-width: auto;
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
  z-index: 1;
  margin-left: 83vh;
}
.shareall {
  margin-top: -16px;
  position: absolute;
  background-color: #f3f3f3;
  min-width: auto;
  padding: 9px;
  border-radius:4px;
  box-shadow: -1px 3px 10px 0px #f5f3f3;
  border: 1px solid #f3f3f3;
  
  z-index: 1;
  margin-left: 76vh;
}
.shareall2 {
  position: absolute;
  background-color: #f9f9f9;
  min-width: auto;
  box-shadow: 3px 6px 16px 0px rgb(155 152 152 / 20%);
  z-index: 1;
  margin-left: 75vh;
}
.shareall3 {
  position: absolute;
  background-color: #f9f9f9;
  min-width: auto;
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
  z-index: 1;
  margin-left: 118vh;
  margin-top: 29px;
}

@media only screen and (min-width:1920px){
  .shareall{
    position: absolute;
    background-color: #f9f9f9;
    min-width: auto;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    margin-left: 87vh
  }
}

@media only screen and (min-width:2304px){
  .shareall {
    margin-top: -16px;
    position: absolute;
    background-color: #f3f3f3;
    min-width: auto;
    padding: 9px;
    box-shadow: -1px 3px 10px 0px #f5f3f3;
    border: 1px solid #f3f3f3;
    
    z-index: 1;
    margin-left: 76vh;
}
}

@media only screen and (min-width:2560px){
  .shareall {
    margin-top: -16px;
    position: absolute;
    background-color: #f3f3f3;
    min-width: auto;
    padding: 9px;
    box-shadow: -1px 3px 10px 0px #f5f3f3;
    border: 1px solid #f3f3f3;
    
    z-index: 1;
    margin-left: 76vh;
}
}

.fab {
  font-family: "Font Awesome 5 Brands";
  font-size: 17px;
  padding-left: 5px;
  color: grey;
  background-color: snow;
}
.fas{
  font-size: 17px;
}
@media only screen and (min-width:3000px){
  .shareall {
    margin-top: -16px;
    position: absolute;
    background-color: #f3f3f3;
    min-width: auto;
    padding: 9px;
    box-shadow: -1px 3px 10px 0px #f5f3f3;
    border: 1px solid #f3f3f3;
    
    z-index: 1;
    margin-left: 76vh;
}
}
.share-option{
  width: auto;
  height: auto;
  /* background-color: white; */
  color: #8d8d8d;
  font-size: 26px;
  float: right;
  margin-right: 1vw;
  cursor: pointer;
  z-index: 1;
}

.drop {
  box-shadow: 0px 0px 20px 3px rgb(230 230 230 / 20%);
  width: 25vw;
  margin-top: -4px !important;
  height: auto;
  border: 1px solid white !important;
  border-radius: 5px !important;
  /* display: block; */
  padding-bottom: 20px;
}

.drop > li{
  font-size:16px;
  margin-top:20px;
  margin-bottom:5px;
  font-family:helvetica neue;
}
.sub-cat:hover .dropdown{
  display: block;
}

.image-law {
  margin-top: 1vh;
  margin-left: 2vw;
  margin-right: 1vw;
  width: 2vw;
  height: 4vh;
  color: blueviolet !important;
}

.company {
  font-size: 15px;
  color: #3a3a3a;
  margin-left: 0;
  font-family: 'Lato';
}

.des-law{
  font-size: 13px;
  color: #9e9d9d;
  margin-left: 5vw;
  margin-top: 0vh;
  font-family: helvetica neue;
}

.button-color{
  background-color: none;
  color: #ffffff
}
.content-box{
    overflow-y: scroll; 
    height:57vh;
}

.closetab{
  margin-top: -30px;
  cursor: pointer;
  float: right;
  color: grey;
  margin-right: 8px;
  float: right;
  position: relative;
}

.closetab1{
  margin-top: -30px;
  cursor: pointer;
  float: right;
  color: grey;
  margin-right: 8px;
  float: right;
  position: relative;
}


.moretab{
  margin-left:44vw;
}

.contenttag {
  margin-left:26px;
  margin-top: -10px;
  font-size: 15px;
  color: #353535;
  font-weight: 200;
}

.tagname{
  /* margin-top:-12px; */
  margin-bottom: 16px; 
}

.tagcont{
  margin-top: -4px;
}
.more{
  float: right;
  cursor: pointer;
  color: rgb(255, 255, 255);
  text-align: center;
  font-size: 16px !important;
  margin-right: 1vw;
  position: relative;
}

.more1{
  float: right;
  cursor: pointer;
  color: blue;
  text-align: center;
  font-size: 16px !important;
  margin-right: 1vw;
  position: relative;
  margin-top: -2px;
  margin-left: -11px;
}

.more2{
  float: right;
    cursor: pointer;
    color: blue;
    text-align: center;
    font-size: 16px !important;
    margin-right: 1vw;
    position: relative;
    margin-top: -2px;
    margin-left: -8px;
}

@media only screen and (min-width:1920px){
  .more{
    float: right;
    cursor: pointer;
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 16px !important;
    margin-right: 1vw;
    position: relative;
    margin-top:1px;
  }
}

.arrow-up {
  margin-top: -2%;
  width: 0;
  height: 0;
  border-left: 11px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 17px solid #f3f3f3;
  position: absolute;
  opacity: 1;
  margin-left: 52vw;
  transition: all 0.5s ease;
  /* box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%); */
}

.arrow-up1 {
  top: 37px;
  /* margin-top: -29px; */
  width: 0;
  height: 0;
  border-left: 11px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 17px solid #f3f3f3;
  position: absolute;
  opacity: 1;
  margin-left: 60vw;
  transition: all 0.5s ease;
}

@media only screen and (min-width:1600px){
  .arrow-up3{
    margin-top: -2%;
    width: 0;
    height: 0;
    border-left: 11px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 17px solid #f3f3f3;
    position: absolute;
    opacity: 1;
    margin-left: 52vw;
    transition: all 0.5s ease;
  }
}

.arrow-up3{
  margin-top: -2%;
  width: 0;
  height: 0;
  border-left: 11px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 17px solid #f3f3f3;
  position: absolute;
  opacity: 1;
  margin-left: 52vw;
  transition: all 0.5s ease;
}

.khub-content-summary>.arrow-up3{
margin-left: 52vw;
}

.arrow-up4{
  margin-top: -2%;
  width: 0;
  height: 0;
  border-left: 11px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 17px solid #f3f3f3;
  position: absolute;
  opacity: 1;
  margin-left: 56vw;
  transition: all 0.5s ease;
}
@media only screen and (min-width:1920px){
  .arrow-up {
    width: 0;
    height: 0;
    border-left: 11px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 17px solid #f3f3f3;
    margin-top: -67vh;
    position: absolute;
    opacity:1;
    margin-left: 50vw;
    transition:all 0.5s ease;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  }
}

.moreopt {
  padding: 15px;
  height: 19vw;
  width: 35vw;
  border: 3px solid #f3f3f3;
  display: block;
  position: absolute;
  background-color: #ffffff;
  min-width: 96%;
  box-shadow: 0px 6px 12px 0px rgb(204 204 204 / 10%);
  z-index: 1;
  cursor: pointer;
  border-radius: 5px;
}

.moreopt1{
  padding: 15px;
  height: 74%;
  display: block;
  position: absolute;
  background-color: #ffffff;
  min-width: 98%;
  box-shadow: 1px 2px 12px 0px rgb(0 0 0 / 10%);
  z-index: 1;
  /* top: 15%; */
  margin-top: 55px;
  left: 12px;
  cursor: pointer;
  border-radius: 5px;
}


.moreopt2{
  padding: 15px;
  height: 34%;
  display: block;
  position: absolute;
  background-color: #ffffff;
  min-width: 97%;
  box-shadow: 1px 2px 12px 0px rgba(0, 0, 0, 0.1);
  z-index: 1;
  margin-top: 3%;
  overflow-y: auto;
  margin-left: 2%;
  cursor: pointer;
  border-radius: 5px;
}

.khub-content-summary>.moreopt2{
  margin-top: 0%;
}

.khub-content-summary .moreopt2 article{
  font-size: 15px;
}

.khub-blogcard-opt{
  margin-top: 7%;
}

.khub-blogcard-opt>div>img{
  height: 70px;
  cursor: pointer;
}

.khub-blogcard-opt-dd{
  background: white;
  z-index: 2;
  position: absolute;
  width: 0vw;
  visibility: hidden;
  transition: visibility 0s, width 0.5s;
  height: 169px;
  margin-top: 6%;
  margin-left: 9%;
  overflow-y: auto;
  word-break: break-word;
}

.slider-active{
  /* width: 51vw; */
  visibility: visible;
  width: 51vw;
}

.khub-blogcard-opt-dd>div>article{
  font-size: 15px;
}

.moreopt3 {
  padding: 15px;
  height: 19vw;
  width: 58vw;
  border: 3px solid #f3f3f3;
  display: block;
  position: absolute;
  background-color: #ffffff;
  min-width: 96%;
  box-shadow: 0px 6px 12px 0px rgb(204 204 204 / 10%);
  z-index: 1;
  cursor: pointer;
  border-radius: 5px;
  overflow-y: auto;
}
@media only screen and (min-width:1600px){
  .moreopt{
    display: block;
    position: absolute;
    background-color: #ffffff;
    min-width: 174px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    margin-top: -70vh;
    margin-left: 40vw;
    cursor: pointer;
  }
}
@media only screen and (min-width:1920px){
  .moreopt{
    display: block;
    position: absolute;
    background-color: #ffffff;
    min-width: 174px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    margin-top: -68vh;
    margin-left: 43vw;
    cursor: pointer;
  }
}

@media only screen and (min-width:2304px){
  .moreopt{
    display: block;
    position: absolute;
    background-color: #ffffff;
    min-width: 174px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    margin-top: -68vh;
    margin-left: 44vw;
    cursor: pointer;
  }
}
@media only screen and (min-width:2560px){
  .moreopt{
    display: block;
    position: absolute;
    background-color: #ffffff;
    min-width: 174px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    margin-top: -66vh;
    margin-left: 45vw;
    cursor: pointer;
  }
}
.button-size{
    /* width: 75px;
    height: 35px;
    margin-right: 10px;

    text-align: center !important; */
    margin-top: 10px;
    margin-left: 2px;
    background-color: #66c0db;
    border: none;
    padding: 0px 7px ;
    text-align: center!important;
    display: inline-block;
    font-size: 10px;
    font-weight: 900;
    border-radius: 4px;
    color: #fff !important;
}

.button24 {
    margin-top: 6px;
    margin-left: 22px;
    width: 180px;
    padding: 4px 4px;
    border-radius: 3px;
    background-color: #1ec3a6!important;
    border: 1px solid #1ec3a6!important;
    font-weight:500;
    color:white;

    /* box-shadow: -1px 2px 8px 0 #e4dfdf; */
}

.headstuff {
  text-align: left;
  margin-top: 3px;
  margin-bottom: 2px;
  line-height:20px;
  font-size: 15px;
  font-weight: 600;
  font-family: helvetica;
  color: black !important;
  cursor: pointer !important;
}

.headstuff1{
  text-align: left;
  margin-top: 3px;
  font-size: 18px;
  font-weight: 600;
  color: grey !important;
  font-family: 'Helvetica', sans-serif;
}

.headstuff1 a{
  word-break: break-word;
}
@media only screen and (min-width:1600px){
  .banner-image{
    height: 55vh;
    width: 100% !important;
    margin-bottom: 20px;
   
  }
}



.banner-image{
  margin-top: -8px;
  height: 26vh;
  width: 100vw;
  margin-bottom: 20px;
 }


.heading24{
    font-family:'lato',sans-serif;
    font-size:11px;
    color: rgb(22, 22, 22);
    margin-top: -8px;
    margin-top: -5px;    
  }
  .heading98{
    font-family:'lato',sans-serif;
    font-size:14px;
    color: rgb(60, 57, 57);
    margin-top: 4px;
    
  }

  .heading25{
    font-family:'lato',sans-serif;
    font-size:16px;
    color: white;
    margin-top: 3px;
    padding:3px 0px;
    text-align:right;
    font-weight:600;
  }

  .khub-blg-mr-sum-hd{
    font-family: 'lato',sans-serif;
    font-size: 1.2em;
    color: white;
    margin-top: 0;
    padding: 1px;
    text-align: right;
    font-weight: 600;
    float: left;
    border: 1px solid #fff;
    margin-right: 1%;
  }

  .heading99{
    font-family:'lato',sans-serif;
    font-size:14px;
    color: rgb(60, 57, 57);
    margin-top: -1px;
  }
  .heading90{
    font-family:'lato',sans-serif;
    font-size:14px;
    color:rgb(2, 31, 48);
   
  }
.button260{
    width: auto;
    height: 26px;
    margin-top: -10px;
    margin-right: 5px;
    background-color: white;
    border: 2px solid #e5e8e9;
    padding: 8px 10px 0px 10px;
    text-align: center !important;
    display: inline-block;
    font-size: 10px;
    font-weight: 900;
    border-radius: 5px;
    color: #ffffff;
    font-family: 'Lato', sans-serif;
    transition: all 0.2s ease;
    box-shadow: -1px 1px 2px 0px #ffffff;
    cursor:auto;
  }

  .button99 {
    width: auto;
    height: 26px;
    margin-top: 7px;
    margin-right: 5px;
    background-color: white;
    border: 1px solid #e5e8e9;
    padding: 8px 10px 0px 10px;
    text-align: center !important;
    display: inline-block;
    font-size: 10px;
    border-radius: 5px;
    color: #ffffff;
    font-family: 'Source sans pro', sans-serif;
    transition: all 0.2s ease;
    box-shadow: -1px 1px 2px 0px #ffffff;
    cursor: pointer;
}

  .footer-logo{
    height:60px;
  }


  .activeTag{
    width: auto;
    height: 26px;
    margin-top: 4px;
    margin-left: 2px;
    background-color: #F3F3F3;
    border: 1px solid #f3F3F3;
    padding: 8px 10px 0px 10px;
    text-align: center !important;
    display: inline-block;
    font-size: 10px;
    font-weight: 600 !important;
    border-radius: 5px;
    color: #ffffff !important;
    font-family: 'source sans pro', sans-serif;
    transition: all 0.2s ease;
    box-shadow: -1px 1px 2px 0px #ffffff;
    cursor: pointer;;
  }

  .resourcecategory {
    height: 4vh;
    margin-left: -6px;
    margin-top: -18px !important;
    padding: 1px 10px;
    font-size: 12px;
    color: white;
    width: 55vw;
    font-family: 'Lato',sans-serif;
    background-color: #A52534;
    /* border-radius: 4px; */
}

.resourcecategorycurated {
  height: 4vh;
  margin-left: -6px;
  margin-top: -18px !important;
  padding: 1px 10px;
  font-size: 12px;
  color: white;
  width: 55vw;
  font-family: 'Lato',sans-serif;
  background-color: #45A296;
  /* border-radius: 4px; */
}
.categorypage {
  border: 2px solid #dededf;
  font-weight: 500;
  text-transform: uppercase;
  padding: 1px 8px;
  display: inline-block;
  font-size: 13px;
  margin-top: 3px;
  background-color: white;
}

.ce-paragraph{
  font-size:15px;
  /* font-family:source sans pro; */
  font-family: Arial, Helvetica, sans-serif;
  padding: 4px 0px;
}

  .categorypage1{
    margin-top:6vh !important;
    border: 2px solid #218ccc;
    font-weight: 600;
    text-transform: uppercase;
    padding: 1px 8px;
    display: inline-block;
    font-size: 10px;
    margin-top: 3px;
    background-color: white;
  }

  .categorypage:hover{
    color: #fff;
    background: #218ccc;
  }
  

.cat-button{
    font-size:14px;
    color:rgb(5, 34, 71);
    font-weight:700;
    padding-left: 20px;
    margin:-6px -5px 0px 10px;
}

.mang-button{
    width: 200px;
    height: 40px;
    background-color: rgb(236, 241, 241);
    border-radius: 10px;
}

.sidebarinside{
    overflow-y:scroll;
    height:600px;
      
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(201, 201, 201); 
  border-radius: 8px;
}
 
.sidebar {
  border: 1px solid #f5f1f1;
  margin-top: -4px;
  padding: 0px;
  width: 350px;
  height: auto;
  border-radius: 1px;
}

.btn-primary {
  color: #fff;
  background-color: #b83544;
  border: none;
  margin-left: 1px;
  margin-top: 11px;
}

.btn-warn{
  margin-top:12px;
}

.btn-primary:hover{
  color: #fff;
  background-color: #a52534;
   border: none;
   margin-left: 1px;
   margin-top: 11px;
}

.nav-tabs {
  border-bottom: 1px solid #f5f1f1;
  font-size: 16px;
  font-weight: 600;
  margin-left: 0px;
  padding-left: 21px;
  margin-bottom: 12px;
}

.nav-tabs:hover{
  background-color:none
}

.nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover {
  color: #555;
  cursor: default;
  padding-right: 8px;
  background-color: #efefef;
  border-bottom: 1px solid #ddd;
  border-bottom-color: #ffffff;
  border:none;
}

.nav-tabs>li>a {
  padding-right: 19px;
  line-height: 1.42857143;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
  margin-left: -16px;
}
.des {
  color: #696666 !important;
  font-size: 15px;
  font-family: 'Helvetica Neue',sans-serif;
  font-weight: 400!important;
  padding-top:4px;
  margin-left:-10px;
}

  .name{
    color: #696666 !important;
  font-size: 15px;
  font-family: 'Helvetica Neue',sans-serif;
  font-weight: 400!important;
  padding-top:4px;
  margin-left:-10px; 
  }

  .author{
    color: #353535 !important;
    font-size: 18px;
    font-family:'Source Sans Pro',sans-serif; 
    font-weight: 600 !important;

  }
  .headstrip {
    background-color: #f5f5f5;
    padding: 3px 9px 12px 0px;
    text-align: center;
}
.whole1{
  margin-top: 0px;
  padding-right:50px;
}

.image4{
  margin-top:14px;
  width:80px;
  height:80px;
}

  .head1 {
    padding-top: 6%;
    padding-bottom:3% ;
    color: #383838 !important;
    font-weight: 700 !important;
    font-size: 20px;
    /* font-family: 'Helvetica', sans-serif; */
    font-family: Arial, Helvetica, sans-serif;
    word-break: break-word;
  }
  .head2 {
    color: #444748;
    font-weight: 800 !important;
    font-size: 20px;
    font-family: 'Lato', sans-serif;
}
  .head3 {
    
    color: #353535 !important;
    font-weight: 100 !important;
    font-size: 15px !important;
    font-family:'Source Sans Pro',sans-serif; 
    margin-top: 61%;
    margin-left: 2%;
  }

  .head4 {
    color: #353535 !important;
    font-weight: 100 !important;
    font-size: 15px !important;
    font-family:'Source Sans Pro',sans-serif; 
    margin-top: 0;
  }
  .subheading {
    color: #383838 !important;
    font-weight: 500 !important;
    font-size: 20px;
    font-family: 'PT Sans', sans-serif;
  }
  .content{
    color:rgb(65, 64, 64);
    font-size:14px;
    font-weight:400;
    padding-left:1px;
    padding-bottom: 2%;
    text-align: justify;
    font-family: 'PT Sans', sans-serif;
  }
  
  .view-more-button {
    border: 1px solid #f3efef;
    margin-left: 22vw;
    margin-bottom: 20px;
    width: 111px;
    border-radius: 5px;
    padding: 5px 5px 5px 17px;
    font-size: 15px;
}
#footer-head {
  color: #ffffff !important;
  font-weight: 800;
  font-size: 17px;
  padding-left: 5px;
  font-family: 'Montserrat';
}
  .contentt {
    color: rgb(2 2 2);
    font-size: 14px;
    font-weight: 500;
    padding-top: 3px;
    margin-left: 1px;
    text-align: left;
    font-family: 'PT Sans', sans-serif;
}

.drop > li {
  font-size: 16px;
  margin-top: 20px;
  /* margin-left: 20px; */
  margin-bottom: 5px;
  padding-bottom: 21px;
  border-bottom: 1px solid #f3f3f3;
  font-family: helvetica neue;
}

.major-icon, .major-icon1 {
  font-size: 15px;
  font-weight: 600;
  padding: 6px 0px;
  margin-right: 2%;
  margin-top: 2px;
  cursor: pointer;
  color: rgb(117 115 115);
  font-family: 'helvetica';
}


.major-icon {
  color: #565656;
  height: 76%;
  background-color: #efefef;
}

.heading30{
  height: 9px;
  margin-left: 8vw;
  margin-top: -2vh;
  font-size: 12px;
  color: black;
  width: 13vw;
}

  .contenttpage{
    color: #616161;
    font-size:14px;
    font-weight:400;
    padding-top:3px;
    margin-left:1px;
    text-align: left;
    font-family:'Helvetica Neue',sans-serif; 
  }

  .contentttpage {
    color: #616161;
    font-size: 15px;
    font-weight: 500;
    margin-left: 8vw;
    margin-top: 5vh;
    text-align: left;
    font-family: 'Helvetica Neue',sans-serif;
}

  .contentpage1{
    color: #616161;;
    font-size:15px;
    font-weight:500;
    margin-left:0px;
    margin-top: -2vh;
    text-align: left;
    font-family:'Helvetica Neue',sans-serif; 
  }
   
  .contentfullpage1 {
    color: rgb(65, 64, 64);
    font-size: 15px;
    font-weight: 400;
    margin-left: 26px;
    margin-top: -2vh;
    text-align: left;
    font-family: 'Helvetica neue',sans-serif;
}
.contenttfullpage {
  margin-left: 25px;
  font-family: helvetica neue;
  font-weight: 400;
}


  



.contentttfullpage {
  color: rgb(65, 64, 64);
  font-size: 15px;
  font-weight: 400;
  margin-left: -2vw;
  margin-top: 8vh;
  text-align: left;
  font-family: 'Helvetica Neue',sans-serif;
}

a{
    margin:none !important;
}


.author-head {
  color: #636363;
  font-weight: 600;
  font-family: helvetica neue;
  font-size: 17px;
  margin-top: -10px;
  margin-left: 4px;
}
.sub-cat{
    font-size:15px !important; 
    font-weight: 500 !important;
    margin-top:6px !important;
    height: auto !important;
    font-family:'Source Sans Pro',sans-serif; 
   
}

.cardstuffside {
    border-bottom: 1px solid #f5f1f1;
    padding: 0px 0px;
    width:320px;
    margin:0px -20px;
  }
  .imagecredit{
    border: 1px solid #f5f1f1;
    padding: 30px 30px;
   
  }

  .filter1-card {
    color: rgb(61, 58, 58) !important;
    background-color: white;
    border-radius: 5px;
    /* height: 500px; */
    margin-right: 20px;
    overflow-y: auto;
    height: 74vh;
    overflow-x: hidden;
    box-shadow: -1px 3px 10px 0px #f5f3f3;
    padding: 10px 10px 0px 10px;
    width: 19vw;
    margin-top: -29px;
    font-family: 'Source Sans Pro',sans-serif;
}

@media only screen and (min-width:3840px){
  .filter1-card {
    color: rgb(61, 58, 58) !important;
    background-color: white;
    border-radius: 5px;
    /* height: 500px; */
    margin-right: 20px;
    overflow-y: scroll;
    height: 74vh;
    overflow-x: hidden;
    box-shadow: -1px 3px 10px 0px #f5f3f3;
    padding: 10px 0px 0px 10px;
    width: 19vw;
    margin-top: -29px;
    font-family: 'Source Sans Pro',sans-serif;
}}
.filter2-card {
  font-size: 16px !important;
  font-weight: 400 !important;
  color: rgb(61, 58, 58) !important;
  background-color: white;
  border-radius: 5px;
  width: 21vw;
  padding: 9px 3px;
  margin-right: 20px;
  margin-left: 50px;
  height: 74vh;
  margin-top:-29px;
  box-shadow: -1px 3px 10px 0px #f5f3f3;
  overflow-y: scroll;
}

@media only screen and (min-width:1920px){
  .filter2-card {
    font-size: 16px !important;
    font-weight: 400 !important;
    color: rgb(61, 58, 58) !important;
    background-color: white;
    border-radius: 5px;
    width: 21vw;
    padding: 9px 3px;
    margin-right: 20px;
    margin-left: 50px;
    height: 74vh;
    margin-top:-29px;
    box-shadow: -1px 3px 10px 0px #f5f3f3;
    overflow-y: scroll;
}
}

@media only screen and (min-width:3840px){
  .filter2-card {
    font-size: 16px !important;
    font-weight: 400 !important;
    color: rgb(61, 58, 58) !important;
    background-color: white;
    border-radius: 5px;
    width: 21vw;
    padding: 9px 3px;
    margin-right: 20px;
    margin-left: 50px;
    height: 74vh;
    margin-top:-29px;
    box-shadow: -1px 3px 10px 0px #f5f3f3;
    overflow-y: scroll;
}
}
.filter3-card{
    font-size: 16px !important;
    font-weight: 400 !important;
    color: rgb(61, 58, 58) !important;
    background-color: white;
    border-radius: 5px;
    /* height: 500px; */
    margin-right:20px;
    margin-left: 1vw; 
    width:  56vw;
    height:70vh;
    box-shadow: -1px 3px 10px 0px #f5f3f3;
    /* padding:30px 0px 0px 20px; */
}

@media only screen and (min-width:1920px){
  .filter3-card{
    font-size: 16px !important;
    font-weight: 400 !important;
    color: rgb(61, 58, 58) !important;
    background-color: white;
    border-radius: 5px;
    margin-right:20px;
    margin-left: 1vw; 
    width:  56vw;
    height:70vh;
    box-shadow: -1px 3px 10px 0px #f5f3f3;
}
}

@media only screen and (min-width:3840px){
  .filter3-card{
    margin-top: 4vh;
    font-size: 16px !important;
    font-weight: 400 !important;
    color: rgb(61, 58, 58) !important;
    background-color: white;
    border-radius: 5px;
    margin-right:20px;
    margin-left: 1vw; 
    width:  56vw;
    height:70vh;
    box-shadow: -1px 3px 10px 0px #f5f3f3;
}
}

.section {
  border-bottom: 1px solid rgb(243 243 243);
  border-radius: 2px;
  padding: 10px 20px 0px 10px;
}

@media only screen and (min-width:3840px){
  .section{
    border-bottom: 1px solid rgb(212, 212, 212);
    border-radius: 2px;
    padding:20px 0px 0px 10px;
} 
}
.main-navbar{
    margin-top:56px;
}


.sec-navbar{
    /* border-top: 1px solid rgb(207, 207, 207) !important; */
    margin-top:-25px;
    height:50px !important;
}
.sec-navbar1{
    margin-top:-25px;
    height: 65px  !important;
    background-color: #bbebf3 !important 
}

.sec-navbar2{
  margin-top: -9px;
  height: 8vh !important;
  border-top:1px solid rgb(206, 198, 198);
  background-color: #38bdd4 !important;
  box-shadow:4px 3px 2px 0px #d8d8d8;
}

@media only screen and (min-width:1600px){
  .sec-navbar2{
    margin-top: -9px;
    height: 8vh !important;
    border-top:1px solid rgb(206, 198, 198);
    background-color: #38bdd4 !important;
    box-shadow:4px 3px 2px 0px #d8d8d8;
  }
}

@media only screen and (min-width:1920px){
  .sec-navbar2{
    margin-top: -9px;
    height: 5vh !important;
    border-top:1px solid rgb(206, 198, 198);
    background-color: #38bdd4 !important;
    box-shadow:4px 3px 2px 0px #d8d8d8;
  }
}
@media only screen and (min-width:2304px){
  .sec-navbar2{
    margin-top: -9px;
    height: 4vh !important;
    border-top:1px solid rgb(206, 198, 198);
    background-color: #38bdd4 !important;
    box-shadow:4px 3px 2px 0px #d8d8d8;
  }
  }

  @media only screen and (min-width:2560px){
    .sec-navbar2{
      margin-top: -9px;
      height: 53px !important;
      border-top:1px solid rgb(206, 198, 198);
      background-color: #38bdd4 !important;
      box-shadow:4px 3px 2px 0px #d8d8d8;
    }
  }
.copyright {
  height:88px;
  background-color: rgb(165 37 52);
  color: white;
  
}

.adjust-cat{
  margin-top: 8px;
  color: white;
  font-size: 15px;
}

.sec-cat{
  padding-left: 1%;
  font-family: 'Source Sans Pro',sans-serif;
  color: #515151 !important;
}
.sec-cat1{
  font-size:15px !important; 
  font-weight: 500 !important;
  /* margin-bottom:5px !important; */
  padding: 0px 0px 0px 8px !important;
  margin-top: -8px;
  font-family:'Source Sans Pro',sans-serif;
  color: #515151 !important;
}
.active{
  background-color: #ffffff !important;
  color:#484747!important;
}

.activenav{
  /* background-color: white !important; */
  color: #45a296 !important;
}

.default{
  color: #303030;
}

.activelaw{
  border: 2px solid #e5bcc1 !important; 
}

.activesec {
  /* background: #f3f3f3!important; */
  border: 2px solid #E5BCC1;
  border-bottom: -4px solid #f3f3f3!important;
  margin-left: 8px;
  margin-top: 5px;
  border-radius: 7px;
  
  padding: 10px 20px 0px 0px;
}

.activesec > .second-head {
  color:#A52534;
}
.columns-div{
  margin-top:20px;
  margin-left:8px;
}

.activetab {
  background-color: #f3f3f3!important;
  box-shadow: 3px 4px 10px 0px #d9d8d8;
  border: 2px solid #45a296;
}
.notactivetab{
  background-color: white;
  box-shadow: 3px 4px 10px 0px #d9d8d8;
}
.nav-pills.li{
  background-color: none!important;
}
.businus-logo{
    margin-top:-6px;
    height:40px;
}
.businus-logoshare{
  margin-top:4px;
  height:40px;
}
.descriptionstuff{
  padding:40px 0px 40px 0px;
  font-size:16px;
  font-weight:300;
}
.detail20{
  background-color: rgb(255, 255, 255);
  width: 14vw;
  padding:0px 0px;
  border-radius:0px;
  text-align:left;
  margin-bottom:20px
}

.modification{
 
  background-color: #ffffff;
  width: 100%;
}

.company-logo{
  padding-top: 2%;
}

.highlight2{
  padding-top: 15px !important;
}

.navbar-nav>li>a {
  padding-top: 7px !important;
  padding-bottom: 10px;
  line-height: 20px;
  margin-top: 5px;
}
.navbar {
  background-color: #ffffff;
  padding: 7px;
  border-bottom: 1px solid #f5f1f1;
  margin-bottom: 0;
  z-index: 3;
  /* width: auto; */
  position: fixed;
  margin-top: -1%;
}

.khub-scr-mt{
  margin-top: 9%;
}

.sidetag{
    font-family:'Open Sans ',sans-serif;
    font-weight:400; 
    font-size:14px !important;
    color: #212121;
    cursor: pointer;
    padding:4px;
    border: 1px solid #ffffff;
}


.activeCat {
  border: 2px solid #f3f3f3;
  font-weight: 600;
  cursor: pointer;
  background-color: whitesmoke;
}

.filters{
    height:300px !important;
}

.tag-cat-container{
  position: relative;
  right: 5%;
}

.filters-cat {
  margin-bottom: 20px;
  border: 1px solid rgb(243 239 239);
  margin-top: 5px;
  padding: 6px 12px;
  width: 230px;
  height: auto;
  min-height: 50vh;
  border-radius: 5px;
  background-color: white;
  /* position: fixed;
  top: 20%; */
}

.filters-tag {
  margin-bottom: 20px;
  border: 1px solid rgb(243 239 239);
  margin-top: 5px;
  padding: 6px 12px;
  width: 230px;
  height: auto;
  min-height: 50vh;
  border-radius: 5px;
  background-color: white;
  /* position: fixed;
  top: 72%; */
}

  .heading9 {
    color: #383838 !important;
    font-weight: 600 !important;
    font-size: 15px !important;
    font-family: 'Helvetica', sans-serif;
    text-align: center;
    background-color: whitesmoke;
    padding: 5px;
    border-radius: 8px;
}

  .cloud {
    display: inline;
    list-style-type: none;
    word-break: break-all;
    width:400px;
    padding: 10px 10px 50px 10px !important;
    margin-bottom:40px;
   
    
  }
  
  .sideoption{
    position: absolute;
    width: 60px;
    background-color: rgba(255,255,2555,1);
    border-radius: 5px;
    margin-right: 15px;
    box-shadow: -10px 0px 20px 2px rgba(0,0,0,.06);
  }

  .sideoption-item:not(:hover):not(:focus) {
    color: #9396a5!important;
}

  .sideoption-item:not(:last-child) {
    border-bottom: 1px solid #f1f3fc;
  }

  .sideoption-item{
    display: block;
    text-align: center;
    margin: 0px;
    transition: 0.3s all ease-in-out;
    position: relative;
    padding: 7px;
    cursor: pointer;
  }

  .sideoption-item-container {
    border-radius: 4px;
    padding: 8px 0;
}

.sideoption-hover {
  position: absolute;
  background-color: #ffffff;
  color: #32343d;
  padding: 20px 26px;
  transform: translateX(-70%);
  left: 0px;
  top: 0;
  bottom: 0;
  opacity: 0;
  transition: 0.2s all ease-in-out;
  z-index: -100;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  font-size: 13px;
  box-shadow: 0px 0px 20px 2px rgba(0,0,0,0.08);
}

  .facebook{  
    color: #139df8;
    font-size: 48px;
  }

  .mail{
    color: #de5449;
    font-size: 48px;
  }

  .whatsapp{
    color: #00e676;
    font-size: 48px;

  }

  .twitter{
    font-size: 48px;
    color: #1da1f2;
  }

  .linkedin{
    font-size: 48px;
    color: #0077b5;
  }

 .shareHeading{
   padding-top: 2%;
   padding-bottom: 1%;
 }

 .shareSubheading{
   padding-bottom: 1%;
 }

  .button25 {
    margin-left: 70px;
    width: 120px;
    padding: 8px 18px 0;
    border-radius: 3px;
    background-color: #fff;
    border: 2px solid #a52534;
  }

  @media only screen and (min-width:1920px){
    .button25 {
      margin-left: 0px;
      margin-left: -7vh;
      width:120px;
      padding: 8px 18px 0px 18px;
      border-radius: 3px;
      background-color: white;
      border: 2px solid #a52534;
    } 
  }

  @media only screen and (min-width:2560px){
    .button25 {
      margin-left: 0px;
      margin-left: -15vh;
      width:120px;
      padding: 8px 18px 0px 18px;
      border-radius: 3px;
      background-color: white;
      border: 2px solid #a52534;
    }
  }

  @media only screen and (min-width:3840px){
    .button25 {
      margin-left: 0px;
      margin-left: -23vh;
      width:120px;
      padding: 8px 18px 0px 18px;
      border-radius: 3px;
      background-color: white;
      border: 2px solid #a52534;
    }
  }
  .filter-buttons {
    border: 1px solid #F3F3F2;
    width: 17vw;
    background-color: rgb(255, 255, 255);
    margin: -1vh 5px 2vh;
    padding: 1vh 0 10px 0;
    border-radius: 5px;
    color: rgb(63, 63, 63);
}
.filter-buttons:active{
  border: 1px solid blueviolet;
}

.share-style{
  padding-left: 45%;
}

.search{
    width:160px;
    height: 37px;
    border:1px solid rgb(173, 173, 173);
    border-radius:5px;
}

.tab-text{
    color:rgb(75, 75, 75);
    font-size:14px;
    font-family:'Source Sans Pro',sans-serif;
    font-weight:500;
}
.tab-box{
    height:300px;
    overflow-y:scroll;
    margin-top:20px;
}

.ce-header {
  /* font-family: helvetica neue; */
  font-family: Arial, Helvetica, sans-serif;
  padding: 1em 0 3px !important;
  margin: 0;
  margin-bottom: -0.9em;
  line-height: 1.5em !important;
  outline: none;
  font-size: 18px;
  font-weight: 600;
}

.cdx-list__item{
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
}

.mainTabs{
  padding:30px 18px !important;
}

.tabclass{
  margin-bottom:-3vh ;
  margin-left:8vw;
  margin-top:10px;
}
.form-inline .form-control {
    display: inline-block;
    width: auto;
    border: 1px solid #e7e7e7;
    vertical-align: middle;
    box-shadow: none;
}

.navbar-default {
  background-color:none;
  border-top: none;
}

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #f5f1f1;
  border-radius: 4px;
  font-family:helvetica;
  text-align:center
}

.nav-pills>li.active>a, .nav-pills>li.active>a:focus, .nav-pills>li.active>a:hover{
  color: #fff;
  background-color: white ;
}

blockquote.twitter-tweet {
  display: inline-block;
  font-family: "Helvetica Neue", Roboto, "Segoe UI", Calibri, sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  border-color: #eee #ddd #bbb;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  margin: 10px 5px;
  padding: 0 16px 16px 16px;
  max-width: 468px;
}

blockquote.twitter-tweet p {
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
}

blockquote.twitter-tweet a {
  color: inherit;
  font-weight: normal;
  text-decoration: none;
  outline: 0 none;
}

blockquote.twitter-tweet a:hover,
blockquote.twitter-tweet a:focus {
  text-decoration: underline;
}

.btm-2{
  width: 913px !important;
  
    margin-top: 0px !important;
    margin-left: -6px !important;
  
}


.knowledgeHub_Noinfo_Text{
  text-align:center;
  background-color:rgb(235, 235, 255);
}


#readOnly{
  min-height:300px;
}

.ce-block a{
  text-decoration: underline;
  cursor: pointer;
  color: #23527c;
}

.khub_blog_container{
  padding: 0 0 0 12%;
}

.tab-content{
  margin-left: 8vw;
}

.khub_blog_ref{
  border: 1px solid #f5f1f1;
  height: auto;
  margin: 2% 0;
  padding: 3% 1% 3% 2%;
}

.dropdown-content{
  width: 11vw;
  margin-left: 11vw;
  margin-top: -2vh;
  z-index: 2;
}

.dropdown-content>ul{
  list-style: none;
}
.ReactTable .rt-tbody{
  overflow: hidden;
}
.summary-container{
  position: relative;
  border: 1px solid #f5f1f1;
  padding: 20px 7px;
  margin: 20px 0 -14px 0;
}

.khub_blog_ref>h4{
  margin-left: 3%;
}

.khub_all_bl_con{
  min-height: 100vh;
}

.mr-res-container{
  padding: 2% 0;
}

.mr-res-container>.mr-res-url{
    font-size: 1.3rem;
    color: blue;
    text-decoration: underline;
    word-break: break-word;
}
/* css for smallest mobile devices */

@media screen and (max-width: 450px) {
  .article-style {
    padding: 0px;
    width: 95vw;
    margin-bottom: 10%;
    margin-left: -7%;
}

.khub-blogcard-opt-dd{
  transition: visibility 0s, height 0.5s;
  width: 95vw;
  margin-left: -7%;
}

.khub_blg_more_cl_head{
  font-size: 0.9em;
}

.resourcecategory, .resourcecategorycurated{
  width: 95vw;
  margin-left: 14px;
}

.headstuff1{
  font-size: 1.4rem;
}

.content{
  font-size: 1.1rem;
}

.contentt{
  font-size: 1rem;
}

.heading24{
  font-size: 1rem;
}

.heading25{
  font-size: 1.4rem;
}

.image{
  width: 15vw;
  height: 12vh;
}

.khub_tag_btn_cont{
  margin-bottom: 5%;
}

/* .filters-cat{
  width: 93vw;
} */

.view-more-button{
  margin-left: 29vw;
}

.subhead{
  font-size: 1.3rem;
  margin: 0;
}

.businus-logo{
  height: 30px;
  margin-top: 3%;
  margin-left: -4px;
}

.banner-image{
  margin-top: 80px;
}

.navbar{
  padding: 0;
  margin-top: -3%;
}

.khub_card_blog_content{
  padding: 0 0 0 30px;
}

.khub_blog_image_container{
  padding: 0;
}

.tab-content{
  margin-top: 18%;
  margin-left: 0;
}

.dedicatedstuff{
  padding: 0 17px 20px;
}

.resourcepage-line{
  margin-top: 0px;
  padding: 10px 0 0 0;
}

.copy1{
  margin: -4px 14px 0 0;
  font-size: 1.5rem;
}

.bookmark1{
  margin: -8px 14px 0 0;
}

.share{
  margin: -9px 1vw 0 0;
}

.share>div>.fas{
  font-size: 1.4rem;
}

.bookmark1{
  margin: -4px 12px 0 0;
  font-size: 1.5rem;
}

.shareall2{
  margin-left: 37vh;
}

.khub_blog_whole{
  padding:6px 20px;
}

.categorypage{
  font-size: 1rem;
}

.contentpage1, .contenttpage{
  font-size: 0.9rem;
}

.contentttpage{
  font-size: 0.9rem;
  margin-top: 1vh;
  margin-left: 0;
}

.head1{
  font-size: 1.6rem;
}

.subheading{
  font-size: 1.4rem;
}

h2.ce-header{
  font-size: 1.8rem;
  line-height: 1.1em;
}

div.ce-paragraph{
  font-size: 1.4rem;
}

div.image-tool__image>div{
  font-size: 1.2rem;
}

.imagecredit{
  margin-bottom: 5%;
}

.cardstuffside{
  width: 395px;
  padding: 0px 16px;
}

.cardstuffside>.row>div{
  padding: 0;
}

.image4{
  width: 90px;
  padding-left:7px;
  margin: 7px 0;
}

.headstuff{
  font-size: 1.2rem;
  line-height:1.8rem;
}

.head2{
  font-size: 1.6rem;
}

.sidebar{
  width: auto;
}

.button25{
  margin-left: 0px;
}

.khub_all_bl_con{
  padding: 0;
}

.detail20{
  width: auto;
}

.khub_blog_ref{
  padding: 7% 2% 3% 5%;
}
.filters-tag {
  position: relative;
  top: 0;
}

.tag-cat-container {
  margin-left: 16%;
  right: 0;
}

}

/* Medium range screen laptop */

@media screen and (max-width:1600px) {
  .image{
    height: 18vh;
  }
}

/* mobile devices with the medium screen size  */
@media screen and (max-width:390px){
  .headstuff{
    font-size: 1.1rem;
  }
}

/* mobile devices with lowest screen resolution (like iphone se, 5) */
@media screen and (max-width:350px) {
  .button99{
    padding: 10px 10px 0px 10px;
    height: 20px;
  }

  .heading24 {
    font-size: 1.1em;
  }

  .khub-slider-cont-list>li>a>img {
    width: 26px;
    height: 26px;
    margin: 3% 4%;
  }

  .khub-slider-cont-heading{
    padding:10px 72px;
    font-size: 1em;
  }
}