.about{
  background-image:url('../../Images/aboutus2.png');
 
}

#ourmission{
  background-image: url('../../Images/our-mission.png');
  padding:30px 70px;margin:40px 0px;
}
.abouttext{
  font-size:18px;
  color:#2e2e2e;
  font-weight:400;
}

.khub_contact_req{
  padding: 80px 30px 0;
}

.details {
  color: #3f3f3f;
  font-size: 17px;
  font-family: lato,sans-serif;
  font-weight: 600;
}

label {
  color: #7c7c7c;
  font-size: smaller;
}

.form-group {
  padding-bottom: 3%;
}

.row {
  display: -ms-flexbox;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

@media (max-width: 768px){
  .about{
    background-image:url('../../Images/aboutus3.png');
  }
  #ourmission{
    background-image: url('../../Images/our-mission2.png')!important;
    padding:10px 10px 40px 10px !important;margin:10px 0px !important;
  }
  
}