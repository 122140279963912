.guide-card-contianer{
    padding: 20px;
}

.guide-card{
    border-radius: 0 15px 5px 5px;
    width: 250px;
    height: 320px;
    box-shadow: 0 8px 12px rgb(0 0 0 / 14%);
    position: relative;
    background-color: #fff;
    background-position: bottom left;
    cursor: pointer;
}

.guide-card:hover{
    box-shadow: 0 12px 16px rgb(0 0 0 / 14%);
}

.guide-card-content{
    position: absolute;
    height: 320px;
    padding: 20px;
    border-radius: 0 15px 5px 5px;
    box-shadow: 0 0 3px rgb(0 0 0 / 13%), -1px 3px 0 #fff, -2px 4px 1px rgb(0 0 0 / 12%), -3px 6px 0 #fff, -4px 7px 1px rgb(0 0 0 / 12%);
}

.guide-ct-al{
    align-items: initial;
    height: 100px;
    overflow: hidden;
}

.guide-ct-name{
    position: relative;
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1.4;
}

.guide-ct-description{
    letter-spacing: .3px;
    font-size: 14px;
    padding-right: 10px;
    margin-top: 8px;
    font-family: 'Source Sans Pro',sans-serif;
}

.gudie-card-page{
    position: absolute;
}

.guide-card-strip-h{
    opacity: .4;
    height: 325px;
    background-color: #c37b82;
    position: absolute;
    top: 0;
    left: -4px;
    bottom: 8px;
    width: 5px;
    border-radius: 5px 0 0 5px;
}

.guide-blog-index-container{
    height: 40vw;
    width: 90%;
    background-color: #fafafa;
    border-right: 2px solid rgba(46,54,83,0.07);
    padding: 20px 0;
    position:absolute;
}

.guide-blog-index-ch-n{
    font-size: 1.5rem;
    color: rgb(0 0 0 / 57%);
    padding: 4px 40px;
    font-weight: bold;
    cursor: pointer;
}

.guide-blog-index-bl-h{
    font-size: 1.5rem;
    padding: 6px 38px;
    margin: 2px 15px;
    cursor: pointer;
    color: #606060b3;
}

.blog-active{
    background-color: rgba(46,54,83,0.05);
    color: #c37b82;
}

.accordin-header.collapse-active i{
transform: rotate(90deg);
transition: all 0.3s ease-in-out;
}

.accordin-header i{
    transition: all 0.3s ease-in-out
}

.accordin-body{
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
}

.accordin-body.collapse-active{
    max-height: 10rem;
}

.guide-blog-topbar{
    height: auto;
    min-height: 69vh;
    box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
    position: relative;
    border:1px solid #f5f1f1;
    padding: 40px 3px 20px;
    margin: -5px 0;
}

.guide-top-strip{
    background-color: #A52534;
    margin-left: -3px;
    margin-right: -3px;
    margin-top: -41px;
    border-radius: 3px;
    padding: 4px 0;
}

.guide-modal-top-strip{
    background-color: #A52534;
    margin-top: -21px;
    border-radius: 3px;
    width: 80vw;
    padding: 4px 0;
}

.guide-source{
    font-size: 14px;
}

.guide-heading-case{
    padding: 30px 150px 0px 150px;
    font-size: 32px;
    font-weight: bold;
    font-family: 'Helvetica', sans-serif;
}

.guide-main-c{
    padding: 15px 10px 30px 10px;
}

.guide-contenttag{
    margin-left: 26px;
    font-size: 15px;
    color: #353535;
    font-weight: 200;
}

.guide-shareall-dropdown{
    position: absolute;
    background-color: #f9f9f9;
    min-width: auto;
    box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
    z-index: 1;
    margin-left: 118vh;
    padding: 10px;
}

.guide-blog-author-d{
    height: auto;
    box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
    border: 1px solid #f5f1f1;
    padding: 40px 20px 20px;
    margin: -5px 0;
}

.guide-modal-blog-author-d{
border-top: 2px solid #f3f3f3;
padding: 10px;
}

.guide-author-head{
    color: #636363;
    font-weight: 600;
    font-family: helvetica neue;
    font-size: 17px;
    margin-top: -10px;
    margin-left: 4px;
    padding: 10px;
}

.guide-arrow-up{
    width: 0;
    height: 0;
    border-left: 11px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 17px solid #eff0f1;
    position: absolute;
    opacity: 1;
    transition: all .5s ease;
}

.guide-moreOpt{
    position: absolute;
    background-color: #fff;
    height: 19vh;
    width: 55vw;
    border: 3px solid #f3f3f3;
    min-width: 98%;
    box-shadow: 0 6px 12px 0 rgb(204 204 204 / 10%);
    border-radius: 5px;
}

.guide-more-table-heading{
    font-size: 16px;
    font-family: 'Source-sans-pro', sans-serif;
}

.guide-more-table-d{
    font-size: 14px;
    font-family: 'Source-sans-pro', sans-serif;
}

.guide-contentpage{ 
    color: #616161;
    font-size: 14px;
    font-weight: 400;
    padding-top: 3px;
    margin-left: 1px;
    text-align: left;
    font-family: "Helvetica Neue",sans-serif;
}

.guide-filter-tag{
    border: 1px solid rgb(243 239 239);
    padding: 6px 12px;
    width: 230px;
    height: auto;
    min-height: 50vh;
    border-radius: 5px;
    background-color: white;
}

.guide-share-icon-font{
    font-size: 22px !important;
}