

.title-menu {
    font-size: 15px;
    color: #333;
    padding-top: 27px;
    padding-bottom: 0px !important;
    font-weight: 700;
    font-family: Helvetica,"sans-serif";
}

.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 4000;
    top: 0;
    left: -5px;
    border: 1px solid #bdbdbd;
    background-color: #fff;
    overflow-x: hidden;
    transition: all 0.4s ease;
    border-right: 1px solid rgb(255 255 255);
    padding-top: 10px;
    box-shadow: 3px 3px 2px #f5f2f2;
}

.sidenav a {
    padding: 18px 32px 32px 32px;
    text-decoration: none;
    font-size: 16px;
    color: #797979;
    display: block;
    font-weight: 500;
    font-family: 'Lato', sans-serif;
    transition: 0.3s;
}

.sidenav a:hover {
    color: rgb(0, 0, 0);
}

.sidenav .closebtn {
    position: absolute;
    top: 50px;
    right: 25px;
    font-size: 22px;
    color: #000;
    margin-left: 50px;
}

.sidenav .closebtn>.fa{
    font-size: 24px;
    color: grey;
    margin-top: -5px;
    margin-left: 10px;
}

.sidenav .back {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 22px;
    color: #000;
    margin-left: 50px;
}

#main {
    transition: margin-left .5s;
    padding: 16px;
    margin-left:0px;
}

.image-law-1 {
    margin-left: 0vw;
    margin-right: 2vw;
    width: 3vw ;
    height: 5vh ;
    color: blueviolet !important;
}

.sidenav span{
    font-size: 14px;
}

.khub_logo_container{
    margin-bottom: 5%;
}

@media screen and (max-width: 450px) {
    .sidenav {padding-top: 15px;}

    .sidenav a {font-size: 16px;}

    .sidenav .closebtn{
        padding-right: 0;
        top: 40px;
        right: 0px;
    }

    .image-law-1{
        width: 10vw;
        height: 5vh;
    }

    .title-menu{
        padding-top: 20px;
        font-size: 1em;
    }

    .sidenav .closebtn>.fa{
        font-size: 24px;
    }
}


.menu-icon {
    margin-top: px;
    color: #555555;
    left: 0px;
    border-right: 1px solid #d7d8d8;
    /* padding: 34px 15px 42px 20px; */
    padding: 42px 15px 32px 20px;
}



.card-1 {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  }



.sub-heading{


    margin-left:60px;
}
.head
{font-family: 'Lato', sans-serif;
font-size:30px;
font-weight:900;}


.card-header{
    margin-top: 17px;
    margin-left: 11px;
    font-size: 17px;
    color: rgb(165, 158, 158);
    font-weight: 600;
    font-family: "helvetica neue";
}


.close1
{left:0; margin-left:-22px;
    border-bottom:none;
 border-right:1px solid #868585}

.khub_sidebar_logo_img{
    margin-left: -3px;
    height: 40px;
    margin-top: 26px;
}

.khub_Sidebar_cls_mn_btn{
font-size:'30px';
color:'grey';
margin-top:'-5px';
margin-left:'20px'
}

@media screen and (min-width:450px) {

    .khub_sidebar_logo_img{
        height: 35px;
    }

    .khub_Sidebar_cls_mn_btn{
        font-size: 25px;
        color: grey;
        margin-top: -17px;
    }
    

    


}

