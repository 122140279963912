.embed-tool__content{
    width: 55vw;
    height: 74vh;
}

@media screen and (max-width:450px) {
    .embed-tool__content{
        width: 80vw;
        height: 40vh;
    }  
}