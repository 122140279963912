.footer {
  margin-top:39px;
    background-image: url('../../Images/footerbag.png');
    
    background-size: cover;
    background-position-y: 92%;
    padding-top: 50px;
    height: auto !important;
    position:inherit;
  }
  @media (max-width: 768px){
    .footer{
      background-image:url('../../Images/footermobile.png');
    }}

  
  ul {
    padding-left: 5px;
  }
  
  .email-div {
    font-size:14px;
    color: white;
    font-family: 'Montserrat';
  
    font-weight: 800;
}
  
  .email-input {
    height: 39px;
    border: 1px solid grey;
    border-radius: 5px;
    margin-bottom: 20px;
}
  
  .buttonsubs {
    color: rgb(42 40 40);
    background-color: #ffffff;
    margin-top: -20px;
    margin-bottom: 10px;
    border: none;
    padding: 12px 10px 10px 10px;
    text-align: center !important;
    display: inline-block;
    font-size: 15px;
    font-weight: 900;
    border-radius: 4px;
    font-family: 'Lato', sans-serif;
    transition: all 0.2s ease-in;
  }
  
  .buttonsubs:hover {
    transform: scale(1.2);
  }
  
  
  
  .fa:hover{
      opacity:0.7;
  }
  /* Facebook */
  .fa-facebook {
      background: #3B5998;
      color: white;
      font-size: 21px !important;
      padding: 11px 9px 1px 12px !important;
      content: '/f39e';
  }
  
  /* Twitter */
.fa-twitter {
  color: #35a9d7;
  background-color: #fdfeff;
 
 
   }

   .faf{
    font-size: 21px !important;
    
    padding: 11px 9px 1px 12px !important;
   }
   .fa-linkedin {
    background: rgb(1, 148, 158);
    color: white;
    
}

.fa-instagram {
  background: rgb(1, 148, 158);
  color: white;
  font-size: 21px !important;
    
  padding: 11px 9px 1px 12px !important;
}
  .copytext {
    font-size: 14px;
    color: #ffffff;
}
  
  .copyright1{
    background-color: #042557;
  }

  .khub_com_t_cy-rg{
    margin-top: 10%;
  }

.footer-item {
    font-size: 16px;
    font-family: 'Montserrat';
    font-weight: 600;
}

.footer-item>a{
  color: white !important;
}

.social-media-h-f>a>img{
  width: 25px;
  height: 25px;
}

@media screen and (max-width: 450px) {
  .footer-item>a{
    font-size: 0.9em;
    font-weight: 400;
  }

  .buttonsubs{
    padding: 5px 8px;
    margin-left: 6%;
    font-size: 0.9em;
  }
}