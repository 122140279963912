.list-sitemap li{
list-style: none;
}

.site-heading{

    padding: 5px;

}

.list-sitemap li a{
    color: #c2787f !important;
    font-size: smaller;
}

h3{
    font-size: 1.5em;
}

h4{
    font-size: 1.3em;
}

.sitemap{
    padding-top:80px;
}

