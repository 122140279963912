.singleQuestion {
    padding-bottom: 2%;
    margin-left: 2%;
    width: 95%;
    height: auto;
    margin-bottom: 1%;
    border-top: 1px solid #f3f2f2;
}
.question {
    padding-left: 10%;
    margin-top: -2%;
    font-weight: 600;
    font-family: Arial,"Helvetica Neue",Helvetica,sans-serif;
    font-size: 17px;
}

.questionDescription {
    font-size: 14px;
    padding-top: 0%;
    padding-left: 10%;
    margin-bottom:1%;
}

.allquestion{
    padding-left: 1%;
    padding-right: 31%;
}



.questionAsked {
    margin-left: 15%;
    margin-right: 17%;
    padding: 2%;
    margin-top: 2%;
    border: 1px solid #f5f1f1;
    padding: -1px;
}

.dedicatedQuestion{
    font-size: 25px;
   font-weight:600;
    padding-bottom: 1%;
    width: 105%;
}

.dedicatedDescription {
    padding-bottom: 4%;
    padding-top: 0%;
    font-size: 15px;
    width: 87%;
    padding-left: 3px;
}

.questionTime{
    padding-left: 6px;
    font-size: 15px;
}

.dedicatedAnswers{
    font-size:15px;
    padding-left:8px;
}
.user-info {
    float: right;
    font-size: 14px;
    width: 25%;
    /* background: #e1ecf4; */
    min-height: 12vh;
    height: auto;
    padding-top: 0%;
    padding-left: 1%;
    margin-top: 0%;
    margin-right: -15%;
    /* color: blue; */
}

.user-info-answer{
    float: right;
    font-size: 14px;
    width: 25%;
    background: #e1ecf4;
    min-height: 12vh;
    height: auto;
    padding-top: 1%;
    padding-left: 1%;
    margin-top: 2%;
    margin-right: 5%;
}

.addComment{
    font-size: 13px;
    border-top: 1px solid #e4e6e8;
    margin-left: 5%;
    width: 85%;
    color: grey;
    cursor: pointer;
    padding-top: 1%;
    padding-left: 2%;
}

.questionReply{
    border-top: 1px solid #e4e6e8;
    width: 85%;
    margin-left: 5%;
    padding-top: 1%;
    padding-bottom: 1%;
    font-size: 13px;
}

.user-info-question-reply{
   color: blue;
   padding: 2px 6px;
   background: #e1ecf4;
   border-radius: 4px;
   cursor: pointer;
   margin-right: 1%;
}

.answer{
    padding-top: 2%;
    border-top: 1px solid #e4e6e8;
    padding-bottom: 12%;
}

.your-answer {
    border-top: 1px solid #f5f1f1;
    padding-top: 2%;
    padding-top: 6px;
    color: #494848;
    padding-left: 4px;
    padding-bottom: 2%;
    FONT-SIZE: 18PX;
}

.seeAnswerReply{
    font-size: 12px;
    padding-left: 6%;
    padding-bottom: 2%;
    padding-top: 2%;
    color: #1d1ded;
    cursor: pointer;
}

.chip{
    margin-right: 1%;
    margin-bottom: 2%;
    display: inline-block;
    padding: 0 25px;
    height: 35px;
    font-size: 16px;
    line-height: 35px;
    border-radius: 15px;
    background-color: #f1f1f1
}

.closeChip{
    padding-left: 10px;
    color: #888;
    font-weight: bold;
    float: right;
    font-size: 20px;
    cursor: pointer;  
}

.closeChip:hover{
    color: #000000;
}

.tagBlock {
    padding: 4% 2%;
    margin-top: 2%;
    min-height: 10%;
    min-width: 10vw;
    height: auto;
    width: auto;
    border: 1px solid #f5f1f1;
    border-radius: 5px;
}

.tagsClass {
    padding: 4px 1%;
    background: #e1ecf4;
    font-size: 13px;
    border-radius: 3px;
     margin-left: 6%;
    margin-right: -3%;
    color: #2121e4;
}

.tagsDedicatedClass {
    padding: 4px 1%;
    background: #f5f5f5;
    font-size: 13px;
    border-radius: 3px;
    margin-left: 0%;
    margin-right: 1%;
    color: #424242;
}

.addCommentDedicated{
    
        font-size: 15px;
        border-top: 1px solid #efefef;
        margin-left: 6px;
        margin-top: 1%;
        width: 99%;
        color: grey;
        cursor: pointer;
        padding-top: 1%;
        padding-left: 0%;
    }
