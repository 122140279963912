@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,500;1,100;1,200;1,300&display=swap');

/*-----------------------------------------------businus.com_landing_page----------------*/

.box-container1{

background: #ffffff;
height: calc(100vh - 90px);


}
.box-container3{

  background: #ffffff;
  height: calc(100vh - 90px);
  
  
  }


.box-container2{

  background-color: #fbfbfc;
  height: calc(100vh -  90px);
  
  
  }
  

  .box-container2 img {
    width: 19vw;
    position: absolute;
    left: 12%;
    top: -9%;
    transition: all 0.3s ease-in-out;
}

    .box-container3 img {
      width: 19vw;
      position: absolute;
      left: 12%;
      top: -9%;
      transition: all 0.3s ease-in-out;
  }
  
.box-container2:hover{
background-color:#ecfcea;
  cursor:pointer;
      }

      
      .box-container3:hover {

        background-color:rgb(214, 237, 240);
        cursor:pointer;
           }
      .box-container1:hover {

        background-color:rgb(214, 237, 240);
        cursor:pointer;
           }
           

     

           .box-container1 img {
            width: 19vw;
            position: absolute;
            left: 12%;
            top: -9%;
            transition: all 0.3s ease-in-out;
        }

      .sub-logo{
        width: 45% !important;
        position:absolute;
       left: 27% !important;
       top:15% !important;
       transition: all 0.4s ease-in-out;
      }
      .sub-logo-dashboard{
        width: 58% !important;
        position:absolute;
       left: 21% !important;
       top:15% !important;
       transition: all 0.4s ease-in-out;
      }

      .clipArt {
        height: 65vh;
        margin-top: 27%;
        /* width: 13vw; */
    }
      .desc-div {
        font-family: montserrat,'sans-serif';
        margin-top: 38% !important;
        
        
    }
      .desc{
        font-size:22px;
        font-weight:600;
        color:#113855;
        text-align:center;
        
      }
      
      

      .favicon{
        height:15px;
      }

.khub-homepage-main-navbar{
  margin-top: 48px;
}

.khub-homepage-navbar{
  background-color: #f3f3f3;
}

.navbar-item-thumbnail{
  margin-right: 20px;
  margin-bottom: 0;
  background-color: transparent;
  max-width: 21%;
  flex: 0 0 21%;
  position: relative;
  text-align: center;
}

.navbar-item-img{
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.khub-fa-list{
  height: 520px;
  display: flex;
  overflow: hidden;
  margin-bottom: 2%;
}

.khub-homepage-article{
  flex-shrink: 1;
  display: flex;
  flex: 1 0 10em;
  padding: 1em;
  /* position: relative; */
  transition: flex-basis .3s ease-in-out,
  -webkit-flex-basis .3s ease-in-out,
  -ms-flex-preferred-size .3s ease-in-out;
  min-height: 1px;
  vertical-align: top;
  width: 100%;
  float: left;
  /* transform: translate(0px, 0px); */
}

.khub-homepage-article-mob{
  flex-shrink: 1;
  display: flex;
  flex: 1 0 10em;
  padding: 1em;
  /* position: relative; */
  transition: flex-basis .3s ease-in-out,
  -webkit-flex-basis .3s ease-in-out,
  -ms-flex-preferred-size .3s ease-in-out;
  min-height: 1px;
  vertical-align: top;
  width: 100%;
  float: left;
  /* transform: translate(0px, 0px); */
}

.khub-homepage-category{
  pointer-events: auto;
  margin-bottom: 3px;
  font-size: 1.3rem;
  line-height: 1.8rem;
}

.khub-homepage-category-mob{
  pointer-events: auto;
  margin-bottom: 3px;
  font-size: 1.3rem;
  line-height: 1.8rem;
}

.khub-homepage-title{
  font-size: 1.9rem;
  line-height: 2.7rem;
  text-transform: none;
  font-weight: 700;
  cursor: pointer;
}

.khub-homepage-meta{
  margin-top: 5px;
  font-size: 1.4rem;
  line-height: 2.2rem;
}

.khub-homepage-date{
  color: #fff;
    display: inline-block;
    vertical-align: top;
    margin-right: 14px;
}
.khub-homepage-card-thumbnail{
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  /* -webkit-box-shadow: 0 0 20px rgb(0 0 0 / 80%);
  box-shadow: 0 0 20px rgb(0 0 0 / 80%); */
}

.khub-homepage-card-thumbnail-mob{
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  /* -webkit-box-shadow: 0 0 20px rgb(0 0 0 / 80%);
  box-shadow: 0 0 20px rgb(0 0 0 / 80%); */
}

.khub-homepage-article> header{
  position: relative;
  z-index: 2;
  top: 38vh;
}

.khub-homepage-article-mob> header{
  position: relative;
  z-index: 2;
  top: 38vh;
}

.khub-homepage-category>a{
  padding: 5px 8px;
  border-radius: 2px;
  background-color: #8bc34a;
}

.khub-homepage-category-mob>a{
  padding: 5px 8px;
  border-radius: 2px;
  background-color: #8bc34a;
}

.khub-homepage-rt{
  color: #fff;
}

.khub-card{
  background-color: #fff;
  border-radius: 6px;
  height: 115vh;
  margin-bottom: 6%;
}

.khub-card-category-legal>h4{
  display: inline-flex;
  font-size: 2rem;
  color: #8d6fcf;
  padding: 10px 20px;
  cursor: pointer;
}

.khub-card-category-exim>h4{
  display: inline-flex;
  font-size: 2rem;
  color: #dc51a0;
  padding: 10px 20px;
  cursor: pointer;
}

.khub-card-category-wealth>h4{
  display: inline-flex;
  font-size: 2rem;
  color: #8bd623;
  padding: 10px 20px;
  cursor: pointer;
}

.khub-card-category-opportunity>h4{
  display: inline-flex;
  font-size: 2rem;
  color: #54d0e2;
  padding: 10px 20px;
  cursor: pointer;
}



.khub-card-category>span{
  font-size: 1.7rem;
  color: #bbbabd;
  padding: 10px;
}

.khub-card-top-ar{
  width: 100%;
}

.khub-card-top-ar-header-legal>a{
  font-size: 1.8rem;
  color: #8d6fcf;
}

.khub-card-top-ar-header-exim>a{
  font-size: 1.8rem;
  color: #dc51a0;
}

.khub-card-top-ar-header-wealth>a{
  font-size: 1.8rem;
  color: #8bd623;
}

.khub-card-top-ar-header-opportunity>a{
  font-size: 1.8rem;
  color: #54d0e2;
}

.khub-card-top-ar-cont{
  padding: 12px 20px;
}

.khub-card-top-ar-date{
color: rgba(0,0,0,.5);
font-size: 12px;
}

.khub-card-top-ar-des{
  color: rgba(0,0,0,.5);
  font-size: 1.6rem;
  line-height:1.8em;
}

.khub-card-latest-ar{
  border-top: 1px solid rgba(0,0,0,.1);
  padding: 20px;
}

.khub-card-latest-ar a{
  padding: 0;
}

.khub-card-latest-ar>.khub-card-latest-ar-heading{
  font-size:16px;
  padding-left: 0%;
}

.khub-card-latest-ar-heading a{
  word-break: break-word;
}

.khub-card-latest-ar-img{
  width: 70px;
  height: 70px;
  margin-right: 10px;
}

.khub-card-content-legal{
  border-top: 6px solid #8d6fcf;
  border-radius: 6px;
}

.khub-card-content-exim{
  border-top: 6px solid #dc51a0;
  border-radius: 6px;
}

.khub-card-content-wealth{
  border-top: 6px solid #8bd623;
  border-radius: 6px;
}

.khub-card-content-opportunity{
  border-top: 6px solid #54d0e2;
  border-radius: 6px;
}

.khub-slider-card-hr{
  background-color: #fff;
  border-radius: 6px;
  height: 54vh;
  margin-bottom: 6%;
}

.khub-slider-card-cont-us{
  background-color: #fff;
  border-radius: 6px;
  height: 21vh;
  margin-bottom: 5%;
}

.khub-slider-card-st-upd{
  background-color: #fff;
  border-radius: 6px;
  height: 35vh;
  margin-bottom: 6%;
}

.khub-slider-cont-heading{
  padding: 14px 90px;
  font-weight: 600;
  font-size: 18px;
  color: #404040;
}

.khub-slider-cont-list{
  list-style: none;
}

.khub-slider-cont-list>li{
  display: inline;
}

.khub-slider-cont-list>li>a>img{
  width: 38px;
  height: 38px;
  margin: 4%;
  cursor: pointer;
}

.khub-form-input{
  font-family: 'Roboto', sans-serif;
  color: #333;
  font-size: 1.4rem;
  margin: 0 auto;
  padding: 1.5rem 2rem;
  border-radius: 0.2rem;
  background-color: rgb(255, 255, 255);
  border: 1px solid #bdbbbb;
  width: 90%;
  display: block;
  transition: all 0.3s;
  margin-bottom: 2%;
}

.khub-cont-sub{
  margin: 4% 34%;
  padding: 2% 10%;
  background: #8d6fcf;
  color: white;
  font-weight: 700;
  border: none;
  border-radius: 3px;
}

.khub-slider-card-vr{
  background-color: #fff;
  border-radius: 6px;
  height: 30vh;
  margin-bottom: 10%;
}

.khub-homepage-c-card{
  background-color: #fff;
  border-radius: 6px;
  height: 41vh;
  margin-bottom: 5%;
  margin-top: -4%;
}

.khub-homepage-t-card{
  background-color: #fff;
  border-radius: 6px;
  height: 42vh;
  margin-bottom: 3%;
  padding: 10px;
}

.khub-homepage-c-header{
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  height: 8vh;
}

.khub-homepage-c-header>ul{
  list-style:none;
}

.khub-homepage-c-header>ul>li{
  display: inline;
  padding: 20px 10px;
  display: inline-block;
  border-right: 1px solid white;
  cursor: pointer;
  font-size: 13px;
}

.khub-homepage-c-header-active{
  background-color: whitesmoke;
  font-weight: 600;
  border-radius: 5px;
}

.khub-homepage-c-header>ul>li:last-child{
  border: none;
}
.carousel .slide{
  cursor: pointer;
}

.carousel .slide img{
  height: 40vh;
}

.navbar-nav .khub_navbar_menuButton{
  border: none;
  background: inherit;
  width: max-content;
}

.khub-fa-list>div>article>a>img{
  height: 520px;
  width: 100%;
}

.khub_homepage_topbar_cat{
  list-style: none;
}

.khub_homepage_topbar_cat>li{
  display: inline;
}

.navbar-contained{
  margin-top: 1%;
  width: 100%;
  display: inline-flex;
}

.khub_hmp_company_logo{
  margin-top: 3px;
  height: 40px;
}

.carousel .thumb{
  height: 54px;
}
/* CSS For Tablet */

@media screen and (max-width: 700px) {
  .khub-fa-list{
    height: 1008px;
    display: flex;
    flex-wrap: wrap;
    padding: 7%;
  }

  .khub-fa-list>div{
    flex: 100%;
    padding: 0 0 0 20px;
  }

  .khub-fa-list>div>article>a>img{
    height: 315px;
  }

  .khub-fa-list>div>article>header{
    top: 14vh;
  }

  .khub-homepage-main-navbar{
    margin-top: 42px;
  }

  .khub-card-container>div.col-md-3{
    width: 93vw;
    margin-left: 2%;
  }

  .khub-slider-card-vr{
    margin-bottom: 20%;
  }

  .khub-slider-cont-heading{
    padding: 14px 135px;
  }
}

/* CSS for mobile devices */

@media screen and (max-width: 450px){
  .khub-homepage-main-navbar{
    margin-top: 40px;
  }

  .khub-fa-list{
    padding: 40px 54px !important;
  }

  .khub-fa-list-mob{
    padding: 40px 54px !important;
  }

  .khub-fa-list-mob>div>article>a>img{
    height: 338px;
  }

  .khub-homepage-title {
    font-size: 1.5rem;
    line-height: 1.7rem;
    margin: 8px 0;
}

  .khub-fa-list>div>article>header{
    top: 16vh;
  }

  .khub-fa-list-mob>div>article>header{
    top: 16vh;
  }

  .khub-card-container>div.col-md-3 {
    width: 92vw;
    margin-left: 4%;
  }

  .khub-slider-card-vr {
    margin-bottom: 30%;
  }

  .khub-slider-cont-heading {
    padding: 8px 110px;
    font-size: 1.1em;
  }

  .khub-slider-cont-list>li>a>img{
    width: 32px;
    height: 32px;
    margin: 3% 5%;
  }

  .khub-slider-card-cont-us{
    height: 17vh;
    margin-bottom: 8%;
  }

  .khub-slider-card-st-upd{
    height: 27vh;
  }

  .khub-card{
    min-height: 100vh;
    height: auto;
  }

  .khub-homepage-category>a{
    font-size: 1rem;
  }

  .khub-homepage-category-mob>a{
    font-size: 1rem;
  }
  .khub_hmp_company_logo{
    height: 30px;
    margin-top: 10%;
    margin-left: -4px;
  }
}

/* smallest mobile devices */

@media screen and (max-width:392px) {
  .khub-fa-list>div>article>header{
    top:24vh;
  }

  .khub-fa-list-mob>div>article>header{
    top:24vh;
  }

  .khub-homepage-title{
    font-size: 1.2rem;
    line-height: 1.3;
    margin: 8px 0 0 0;
  }
   
  .khub-homepage-meta{
    margin-top: 5px;
    font-size: 1.2rem;
    line-height: 1.5rem;
  }

  .khub-card-category-legal>h4{
    font-size: 1.6rem;
    padding: 10px 15px;
  }

  .khub-card-category-exim>h4{
    font-size: 1.6rem;
    padding: 10px 15px;
  }

  .khub-card-category-opportunity>h4{
    font-size: 1.6rem;
    padding: 10px 15px;
  }

  .khub-card-category-wealth>h4{
    font-size: 1.6rem;
    padding: 10px 15px;
  }

  .khub-card-top-ar-cont{
    padding: 10px 15px;
  }

  .khub-card-top-ar-header-legal>a{
    font-size: 1.6rem;
  }

  .khub-card-top-ar-header-exim>a{
    font-size: 1.6rem;
  }

  .khub-card-top-ar-header-opportunity>a{
    font-size: 1.6rem;
  }

  .khub-card-top-ar-header-wealth>a{
    font-size: 1.6rem;
  }

  .khub-card-top-ar-date{
    font-size: 1rem;
  }

  .khub-card-top-ar-des {
    margin-top: 2%;
    font-size: 1.4rem;
    line-height: 1.8rem;
  }

  .khub-card-latest-ar>a{
    padding-left: 0;
  }

  .khub-card-latest-ar>.khub-card-latest-ar-heading{
    font-size: 1.2rem;
    line-height: 1.5rem;
    margin-top: 2%;
  }

  .khub-slider-cont-heading{
    padding: 12px 97px;
    font-size: 1.6rem;
    margin-bottom: 0;
  }

  .khub-slider-cont-list>li>a>img{
    width: 30px;
    height: 30px;
  }

  .khub-form-input{
    font-size: 1rem;
    padding: 1rem 1.2rem;
  }

  .khub-cont-sub{
    margin: 2% 34%;
  }
  
.navbar-nav .khub_navbar_menuButton{
  width: 65px;
}
}

@media screen and (max-width:375px) {
  .khub-slider-cont-heading{
    padding: 12px 90px;
  }

}
/* 
.khub-homepage-navbar {	
  background-color: #efefef;	
}	

	
.navbar-item-img {	
  width: 50px;	
  height: 50px;	
  border-radius: 50%;	
  margin-top: 6px;	
}	

.scroll-nav {
  padding:5px 10px 0px 15px;
    width: 100%;
    height: 93px;
    
    overflow-x: auto;
    border-bottom: 1px solid #dbd9d9;
  }
  
  .scroll-nav-text {
    padding-left: 7px;
    padding-top:3px;
    font-size: 1.3rem;
    letter-spacing: 0.2px;
    color: #8d8d8d !important;
  }
  
  
  ::-webkit-scrollbar {
    width: 10px;
    height:8px;
    scrollbar-color:rgb(243, 243, 243) !important;
  }
  
  
  ::-webkit-scrollbar-thumb {
    background: #F5F5F5; 
    border-radius: 10px;
    height:10px;
    width:4px !important;
  }
  
  .circular-card{
    margin: 0px 12px 0px 0px;
  }



  .khub-homepage-card-thumbnail{
    position: absolute;
  height:480px;
    width: 120%;
    left: 0;
    top: 0;
    z-index: 1;
    transition: width 2s, height 2s, background-color 2s, transform 0.3s;
    -webkit-box-shadow: -15px 0px 17px -7px rgba(39, 39, 39, 0.75);
    box-shadow: -15px 0px 17px -7px rgba(53, 53, 53, 0.75);
  }
  
  .khub-homepage-card-thumbnail:hover{
    cursor:pointer;
    
    transform: translate(-30px,0px);
    -webkit-box-shadow: -15px 0px 17px -7px rgba(5, 5, 5, 0.75);
    box-shadow: -15px 0px 17px -7px rgba(0, 0, 0, 0.75);
  }
  
  .khub-entry-header{
    transition: width 2s, height 2s, background-color 2s, transform 0.5s;
  }
  
  .khub-homepage-article:hover > .khub-entry-header{
    transform: translate(0px,-20px);
    
   }
  .khub-homepage-card-thumbnail::after{
   
    content: '';
    position: absolute;
    left: 0; top: 0;
    width: 100%; height: 100%;
    background: linear-gradient(rgb(13 13 13 / 70%),rgb(37 37 37 / 70%));
  
    
  }
  
  .khub-homepage-card-thumbnail ::after{
   
    content: '';
    position: absolute;
    left: 0; top: 0;
    width: 100%; height: 100%;
    background: linear-gradient(rgba(26, 26, 26, 0.7),rgba(31, 31, 31, 0.7));
  
    
  }
  .khub-homepage-card-thumbnail::before{
   background:#113855
  }
  
  .khub-homepage-article> header{
    position: relative;
    z-index: 2;
    top: 25vh;
  }

  .khub-fa-list {
    margin-left: -20px;
    margin-bottom: 4%;
    padding: 30px 119px 30px 88px;
    height: 540px;
    display: flex;
    z-index: 0;
    background: white;
  }
  
  .khub-fa-list:hover {
   cursor: pointer;
   
  }
  .khub-fa-list:hover > .khub-entry-header{
    transform: translate(30px,0px);
    
   }
  .khub-fa-list-image-gradient{
    position:relative;
    display:inline-block;
  }
  
  .img-gradient img{
    display:block;
  } */