.about{
  background-image:url('../../../Images/aboutus2.png');
 
}
@media (max-width: 768px){
  .about{
    background-image:url('../../../Images/aboutus3.png');
  }
  #ourmission{
    background-image: url('../../../Images/our-mission2.png')!important;
    padding:10px 10px 40px 10px !important;margin:10px 0px !important;
  }
  
  

}
#ourmission{
  background-image: url('../../../Images/our-mission.png');
  padding:30px 70px;margin:40px 0px;
}
.abouttext{
  font-size:18px;
  color:#2e2e2e;
  font-weight:400;
}