

.singleComment {
    padding: 14px;
    padding-bottom: 2%;
    margin-left: 1%;
    width: 97%;
    height: auto;
    background-color: #f7f7f7;
    border-radius: 8px;
    margin-bottom: 1%;
}

.commentName{
    color: rgb(151 151 151);
    font-size: 14px;
    padding-top: 0%;
    font-weight: 600;
}

.comment{
    margin-top: -2%;
    
    padding-left: 8%;
    margin-left:5%;
    font-size: 14px;
    padding-bottom: 2%;
}

.replyName{
    color: rgb(151 151 151);
    font-size: 14px;
    padding-top: 1%;
    font-weight: 600;
}

.reply{
    
    padding-left: 14%;
    font-size: 14px;
    margin-left:17px;
    margin-top: -2%;
    padding-bottom: 2%;
}

.commentPic{
    width:53px;
    height: 53px;
    border-radius: 50px;
    padding: 10px;
}

.replyPic{
    width: 53px;
    height: 53px;
    padding: 10px;
    margin-left: 6%;
    border-radius: 50px;
}

.commentTime{
    color: #687a86;
    padding-left: 8px;
    font-size: 12px;
}

.allComment{
    padding-bottom: 1%;
    padding-left: 1%;
    padding-right: 34%;
}

.adjustComment {
    width: 97% !important;
    margin-left: 1%;
    margin-top: 14px;
    font-size:14px;
    font-family: helvetica neue;
    border: 1px solid #f5f1f1;
}
.commentButton{
    margin-top: 1%;
    margin-left: 5%;
    margin-bottom: 2%;
}

.seeReply{
    color: blue;
    font-size: 14px;
    float: right;
    padding-right: 2%;
    cursor: pointer;
}

/* .seeReply span{
    margin-right: 12%;
} */

.replyButton{
    padding-left: 2%;
    cursor: pointer;
    width: 11%;
}

textarea.khub_comment_ta{
    display: block;
    width: 100%;
    height: auto;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #f5f1f1;
    border-radius: 4px;
    font-family: helvetica;
}

.khub_comment_reply{
    padding-left: 2%;
    cursor: pointer;
    font-size: 14px;
}

.comments-section {
    border: 1px solid #f5f1f1;
    border-radius: 2px;
    margin: 16px 0 24px 35px;
  }

@media screen and (max-width:450px){
    .allComment{
        padding-right: 3%;
    }

    textarea.khub_comment_ta,.form-control{
        font-size: 1.2rem;
    }

    .btn{
        font-size: 1rem;
    }

    .commentPic{
        width: 45px;
        height: 45px;
    }

    .commentName,.replyName{
        font-size: 1.4rem;
    }

    .commentTime{
        font-size: 0.9rem;
    }

    .comment,.reply{
        font-size: 1.2rem;
    }

    .khub_comment_reply{
        font-size: 1.2rem;
    }
    .comments-section{
        margin: 16px 0 24px 0px;
    }
}