.docban2{
    padding-top:80px;
    
  }
  .resource{
    background-color:#7bebfa;
    height:620px !important;
  }
  
  .authortexthead{
    padding-left:70px;
    font-weight:500;
    font-size:18px;
  }
  .authordescription{
    font-weight:100;
  }
  .resourcehead{
    font-size:24px;
    color:white;
    margin:0px 4px;
  }
  
  .resourcehead{
    padding:2px;
    margin-top:10px;
    background-color: rgb(0, 195, 255);
  }
  .authortext{
    padding-left:70px;
    font-size:14px;
    font-weight: 200;
  }
  .blog{
    /* background-image: url('../../images/blogbanner.png') ; */
    height:700px !important;
  }
  
  .guide{
    /* background-image: url('../../images/guidebanner.png'); */
    height:590px !important;
  }
  

  @media (max-width: 768px){

    .blog{
      /* background-image: url('../../images/blogmobilebanner.png') !important; */
      height:600px !important;
    }
    
    .guide{
      /* background-image: url('../../images/guidemobilebanner.png') !important; */
      height:590px !important;
    }    
  
  }

  .submitbutton{
    width:334px; background-color:#f7e92a; border:none;color:#333231}
    
    .active1{
      background-color: #0099ff00;
      color:#0a0a0a;
      margin-right:10px;
     
    }
  
    .show{
      display:block;
    }
    .hide{
      display:none;
    }
  
   li:onhover{
     cursor:pointer;
   }
   .modaltab{
     font-size:16px;
     margin-right:10px;
   }
   .modaltab:hover{
     cursor:pointer;
   }
   .modaltab:active{
     color:blue;
   }
  
   .modaltext{
     font-size:16px;
     color:#414141;
     font-weight:400;
     
   }
   .modalcontent{
    font-size:14px;
  
  }
 
  .imagecredit{
    border: 1px solid #f5f1f1;
    padding: 30px 30px;
  margin-top:30px;
  }
  .author-image{
    height:100px;
    width:100px;
  }
  
  .imagesmall{
    height:100px;
  }
  .view{
    margin-top:30px;
    margin-left:30px;
  }
  
  .resourcetweet{
    margin-top:20px;
  }

  
  .mainrow1{
    padding-top:80px !important;
    text-align:center;
  }

  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: hidden;
}

.te{
  padding:0px 5px;
  margin:5px 0px 0px -1px;
}

.nav {
 
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav1 {
  margin-top: 31px;
  
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
  font-size: 17px;
  font-family: helvetica neue;
}

.table>thead>tr>th {
  vertical-align: bottom;
  border-bottom: 2px solid #f7f7f7;
  width: 0;
}