/*!
 * Start Bootstrap - Modern Business (https://startbootstrap.com/template-overvies/modern-business)
 * Copyright 2013-2017 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-logomodern-business-nav/blob/master/LICENSE)
 */

 /*=========================================================================================*/
 @media (min-width: 768px) {
    .car8{
     padding-top:110px;
     height:87vh;
   
   }
   body {
     font-family: 'Roboto', sans-serif;
     font-weight: 300;
     /* overflow-x: hidden !important; */
     margin-right: -20px;
   }
   
   .slick-slider-custom{    border-top: 10px solid #e7e7e7 !important;
     border-bottom: 10px solid #e7e7e7 !important;
     padding: 26px 22px 0px 30px !important;
     margin-bottom: -60px !important;
     height:140px;
     color:rgb(245, 47, 47)!important;
     background-color: white;
     margin-top:30px;
     
     }
     .slick-slide img {
       height: 60px;
       display: block;
   }
     
     .slick-prev ,.slick-next {
       background: #f72d2d !important;
       padding:30px 1px;
       color:rgb(245, 47, 47)!important;
        
     }
     .slick-prev{
       left: 2px !important;
       color:rgb(245, 47, 47)!important;
     }
   
     .slick-next {
       right: 4px !important;
       color:rgb(245, 47, 47)!important;
   }
   
.container{
  padding: 50px 0;
}

   .containers-images {
     position: relative;
     width: 100%;
     box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
       transition:all 0.3s ease-in-out;
       padding-bottom: 40px;
       height:250px;
       text-align:center;
   
   }

   .service-image {
     display: block;
     width: 100%;
     height: 230px;
     background-color: rgba(255, 1, 1, 0);
     border-radius:10px;
   }
   
   .overlay-icons {
     position: absolute;
     top: 0;
     bottom: 0;
     left: 0;
     right: 0;
     height: 100%;
     width: 100%;
     opacity: 0;
     transition: .5s ease;
     background-color: #ff7a7a;
   }
   
   .containers-images:hover .overlay-icons {
     opacity: 1;
   
   }
   
   .containers-images:hover{
     box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
   }
   
   .text {
     color: white;
     font-size: 12px;
     position: absolute;
     top: 50%;
     left: 50%;
     -webkit-transform: translate(-50%, -50%);
     -ms-transform: translate(-50%, -50%);
     transform: translate(-50%, -50%);
     text-align: center;
   }
   
   .service-title{
     position: absolute;
     top: 75%;
     
     font-weight:200;
     font-size: 18px;
     color: #404040;
    
   }
   
   .service-div{
     text-align:center;
   }
   
   
   
   
    .request-button{
     background-color: #ffffff;
       height: 50px;
       position: fixed;
       bottom: 80px;
       width: 50px;
       border: 1px solid rgb(231, 74, 34) ;
       -webkit-transition: opacity 0.5s ease-in-out 0s, right;
       -o-transition: opacity 0.5s ease-in-out 0s, right;
       transition: opacity 0.5s ease-in-out 0s, right;
       cursor: pointer;
       opacity: 1;
       
       color: #252525;
       right: 20px;
       display: none;
       z-index: 1;
   }
   .request-button2{
     background-color:#ffffff;
       height: 50px;
       position: fixed;
       bottom: 140px;
       width: 50px;
       border: 1px solid rgb(231, 74, 34) ;
       -webkit-transition: opacity 0.5s ease-in-out 0s, right;
       -o-transition: opacity 0.5s ease-in-out 0s, right;
       transition: opacity 0.5s ease-in-out 0s, right;
       cursor: pointer;
       opacity: 1;
       
       padding:0px 0px 0px 0px;
       color: #1a1a1a;
       right: 20px;
       display: none;
       z-index: 1;
   }
     
   .mainbody1 {
     padding-top: 0px !important;
     overflow-x: none;
   
   }
   
   .contactText {
     font-size: 32px;
     font-weight: 200;
   }
   .subcontactText{
     font-size:22px;
     font-weight: 400;
     color:#5f5f5f;
     padding-left:0px;
   
   }
   .actioncard{
     background-color: white;
     margin:0px 80px 0px 80px;
     padding:30px 0px;
     box-shadow: 3px 1px 20px rgba(193, 175, 175, 0.4);
   }
   
   .contactstrips {
     margin-top:20px;
     background-color: #f5f5f5;
    border-radius:10px;
   padding-bottom:50px; 
     padding-top: 50px;
     
   }
   .consulttext{
     font-size:12px;
   }
   .textsocial{
     font-size:12px; 
   }
   
   .navbar .li:active{
     color:blue;
   
   }

   * a:hover{
     text-decoration: none;
   }
   .dropdown .dropdown-menu {
     transition: all 200ms ease;
     -moz-transition: all 200ms ease;
     -webkit-transition: all 0.0010ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
     -o-transition: all 0.001ms ease;
     -ms-transition: all 2000ms ease;
   }
  
   .dropdown:hover .dropdown-menu {
     display: block;
    
   }
   .heading8{
     font-size:19px;
     color:#ffffff;
     font-weight:200;
    
   }
   
   .socialiconsdiv{
   margin-left:-170px;
   }
   .dropdown-item {
     padding: 10px 30px !important;
   }
   
   .dropdown-item:hover {
     color: rgb(1, 1, 73) !important;
     background-color: #ffffff;
   }
   
   #main-navbar {
     padding-left: 20px;
   }
   .expertimg{
     padding-left:40px;
     padding-bottom:20px;
     animation: expert 4s ease infinite ;
   }
   @-webkit-keyframes expert {
     from {transform: scale(1.0);}
     to {transform: scale(1.2);}
   }
   
   @keyframes expert {
     from {transform: scale(1.0);}
     to {transform: scale(1.1);}
   }
   
   
   .navbar-brand {
     margin-left: 40px;
     font-size: 24px;
     font-weight: 600;
   }
   @media only screen and (max-width: 480px){
     .navbar-brand {
       margin-left: -40px;
       font-size: 24px;
       font-weight: 600;
     }}
   
  
   
   .nav-link, .navbar-brand {
     color: #0d1d37;
     padding-top:15px;
     margin-left:-5px;
   }
   
   .nav-link:hover {
     /* border-top:1px solid rgb(245, 67, 67); */
     color: #3838d1;
   }
   
   .nav-item {
     padding: 0px 3px;
     font-size: 16px;
     font-weight: 500;
     font-family:lato,'sans-serif';
     color: #0d1d37;
   }
   .nav-item:hover {
     /* border-top:1px solid rgb(245, 67, 67); */
     color: #0d1d37;
   }
   .breadcrumbitem{
     font-size:14px;
     color:rgb(255, 255, 255) !important;
     display:inline;
     margin-right: -4px;
     padding-left:2px;
     padding-right:0px;
     font-weight:100;
   }
   
   .breadcrumb li {
     display:inline;
     
   
   }
   .carousel-item {
     margin-top:0px;
     height: 73vh;
     min-height: 400px;
     background: no-repeat center center scroll;
     -webkit-background-size: cover;
     -moz-background-size: cover;
     -o-background-size: cover;
     background-size: cover;
     -webkit-transition: property duration timing-function delay;
     -moz-transition: property duration timing-function delay;
     transition: property duration timing-function del;
   }
   
   .car1 {
     height: 84vh;
   }
   
   .car5 {
     height: 68vh;
   }
   
   .banimage {
     padding: 65px 150px 50px 0px;
   }
   
   .portfolio-item {
     margin-bottom: 30px;
   }
   
   .circles {
     width: 150px;
     height: 150px;
   }
   
   .circles2 {
     width: 50px;
     height: 50px;
   }
   
   #card1 {
     margin: 40px;
   }
    .pricestart{
      background-color:#FFF129;
      padding:10px 10px 3px 30px;
      margin-bottom:10px;
    }
    .pricestart1{
     background-color:#FFF129;
     padding:10px 10px 3px 10px;
     margin-bottom:10px;
   }
   .prices{
   color:rgb(84, 84, 84);
   font-weight:600;
   font-size:17px;
   }
   #contactform{
     padding:10px;
   }
   
   
   
   abbr[data-original-title], abbr[title] {
     text-decoration: underline;
     -webkit-text-decoration: underline dotted;
     text-decoration: none;
     cursor: help;
     border-bottom: 0;
   }
  
   
   .contactsec{
     padding:30px;
   
   }
   
  
   
   #latestarticles {
     color: #535d6d;
     font-family: 'Lato', sans-serif;
    font-size:200;
   }
   
   .fa {
     padding: 18px;
   }
   
   .fa:hover {
     opacity: 0.7;
   }
   
   /* Facebook */
   
  
   
   /* Twitter */
   
   /* .fa-twitter {
     background: #55ACEE;
     color: white;
     height: 13px;
     width: 13px;
     font-size: 20px;
   } */
   .fa-facebook-official{
     color:white;
   }
   /* .fa-twitter-square{
     color:white;
   }  */
   /* .fa-linkedin-square{
     color:white;
   }  */
 
   /* .sendquery{
     background-image:url('../images/aoww.jpg');
   } */
   .contact {
     margin-top: 30px;
     background-color: #ffffff;
     padding: 30px;
   }
   
   .common {
     padding: 60px;
   }
   
   /*wht we offer*/
   
   .choose{
     box-shadow:1px 1px 14px 0px #b7b7b7;
     
   }
   
     
     
    
     
   .circle-row {
     margin-top: 30px;
     margin-bottom: 30px;
   }
   
   .circle-row1 {
     margin-top: 30px;
     margin-bottom:30px;
     margin-left: -59px;
   }
   .customers7{
     padding:20px 0px 30px 60px;
   }
   .customers{
     padding:20px 20px 20px 0px !important;
   }
   .customers9{
     padding:40px 50px 40px 60px;
   }
   .customers5 {
     padding:20px 0px 0px 0px;
   }
   .customers4 {
     padding:40px 40px 40px 120px;
   }
   .worktext{
     font-size:18px;
     font-weight:200;
     font-family: lato , 'sans-serif';
   }
   .aboutus {
     height: 400px;
   }
   
   .head {
     color: #ffffff;
     padding-top: 70px;
     padding-left: 230px;
     font-family: 'Lato', sans-serif;
   }
   
   /*stuff to be used again*/
   
   .circles1 {
     width: 20px;
     height: 20px;
     margin-bottom: 20px;
   }
   
   /* PRODUCTS */
   .products{
     margin:60px -30px;
   }
   
   #requestademo {
     padding: 30px;
     background-color: rgb(255, 255, 255);
     text-align: center;
   }
   
   .button1:hover {
     opacity: 0.6;
   }
   
   .sidestuff{
   text-align:center;
   }
   /*main page css*/
   
   .sec1 {
     height: 280px;
     
      background-color: #ffffff;
     padding: 28px 30px 5px 20px;
     margin: 70px 20px 30px 20px;
     border-radius: 8px;
     transition:all 0.2s ease;
     box-shadow: 5px 6px 20px 0px #9494935c
     
     
   
   }
   .sec1:hover {
     height: 280px;
    
     
     padding: 28px 30px 5px 20px;
     margin: 70px 20px 30px 20px;
     border-radius: 4px;
     
     
     
     transform:scale(1);
     cursor:pointer;
     
   
   }
   .sec2 {
     height: 280px;
    
      background-color: #ffffff;
     padding: 28px 30px 5px 20px;
     margin: 70px 20px 30px 20px;
     border-radius: 8px;
     transition:all 0.2s ease;
     box-shadow: 5px 6px 20px 0px #9494935c
     
     
    
   
   
   }
   .carousel button {
     outline: 0;
     border: 0;
     background: 0 0;
   }
   .button10{
     background-color:#fff129 !important; 
   }
   .testimonials{
     font-size:28px;
     color:#383838;
     font-family:'lato', sans-serif;
     font-weight:800;
     padding-bottom:20px;
   }
   .testimonials2{
     font-size:28px;
     color:#ffffff;
     padding-top:27px;
     font-family:'lato', sans-serif;
     font-weight:800;
     padding-left:20px;
     padding-bottom:20px;
   }
   .socialmed{
   background-color:rgb(235, 138, 79);
   }
   .fa-automobile{
     color:rgb(53, 53, 53);
     padding:5px;
   }
   
   .fa-cart-plus{
     color:rgb(53, 53, 53);
     padding:2px;
     font-size:25px;
   }
   .sec2:hover{
     height: 280px;
    
      background-color: #ffffff;
     padding: 28px 30px 5px 20px;
     margin: 70px 20px 30px 20px;
     border-radius: 8px;
     transition:all 0.2s ease;
     box-shadow: 5px 6px 20px 0px #9494935c;
     
     cursor:pointer;
    
   
   }
   .red{
     color:rgb(255, 153, 0);
   }
   .row1 {
     padding: 10px 50px 10px 130px !important;
     margin: 0px 30px;
   }
   
   .link1 {
     background-color: #0e89dbce;
     color: #ffffff;
     padding: 40px;
     padding: 40px 20px 40px 300px;
   }
   
   .link2 {
     background-color: #000932;
     color: #ffffff !important;
     padding: 40px 20px 40px 300px;
     transition:all 0.5s ease;
   }
   /* .link2:hover{
     background-image:url('../images/pricinghover.png');
     color:#000000 !important;
    
   } */
   
   .sec3 {
     padding: 40px 50px 40px 68px;
     height: 340px;
     font-family: 'Lato', sans-serif;
     color: #6b7070;
   }
   
   .textblock {
     margin-top: 13%;
     padding: 26px;
     background-color: rgba(55, 185, 255, 0.479);
     /* height: 280px; */
     border-radius: 5px;
     width: 100%;
     color: #050505;
   }
   
   .textblock2 {
     margin-top: 10%;
     padding: 40px;
     background-color: rgba(237, 255, 159, 0.542);
     height: 280px;
     border-radius: 5px;
     width: 50%;
     font-size: 22px;
     color: #333333;
   }
   
   .oppor {
     padding: 30px 90px 30px 90px;
     height: 460px;
     text-align: center;
     border-radius: 5px;
     margin: 20px -20px;
   }
   
   .oppor1 {
     padding: 40px;
   }
   
   .opportrain {
     padding: 30px 0px 30px 0px;
     height: 460px;
     text-align: center;
     border-radius: 5px;
   }
   
   .oppor1train {
     padding: 40px;
   }

   
   /*fonts*/
   
   .heading3 {
     color: #2b2b2b;
     font-weight: 200;
     font-size: 24px;
     font-family: lato , 'sans-serif';
   }
   
   .heading5 {
     color: #2b2b2b;
     font-weight: 300;
     font-size: 25px;
     font-family: lato , 'sans-serif';
   }
   
   
   .heading18
    {
     color: #3c3c3c !important;
     font-weight: 200 !important;
     font-size: 20px !important;
     font-family: lato , 'sans-serif';
   }
   
   .heading15 {
     color: #ffffff !important;
     font-weight: 200 !important;
     font-size: 20px !important;
     font-family: lato , 'sans-serif';
   }
   
   .heading4 {
     color: #505050;
     font-weight: 200;
     font-size: 18px;
     font-family: lato , 'sans-serif';
   }
   
   .heading1 {
     color: #3d3d3d;
     font-family: lato , 'sans-serif';
     font-size: 27px;
     margin-top: 2px;
     font-weight: 200;
   }
   
   .headingpricing {
     margin-top: 30px;
     color: #3d3d3d;
     font-size: 30px;
     font-family: lato , 'sans-serif';
     margin-left: 395px;
   }
   
   .heading {
     margin-top: 25px;
     color: #2b2b2b;
     font-size: 24px;
     font-family: lato , 'sans-serif';
     
   }
   .heading32 {
     margin-top: 25px;
     color: #ffffff;
     font-size: 24px;
     font-family: lato , 'sans-serif';
     font-weight: 200;
   }
   .heading1 {
     margin-top: 25px;
     color: #ffffff;
     font-size: 24px;
     font-family: lato , 'sans-serif';
     font-weight: 200;
   }
   .heading64 {
     margin-top: 25px;
     color: #272727;
     font-size: 24px;
     font-family: lato , 'sans-serif';
     font-weight: 200;
   }
   
   .heading16 {
     margin-top: 25px;
     color: #1b1b1b;
     font-size: 24px;
     font-family: lato , 'sans-serif';
     font-weight: 200;
   }
   .sub-heading-query{
     margin-top: 25px;
     color: #ffffff;
     font-size: 20px;
     font-family: lato , 'sans-serif';
     font-weight: 200;
   }
   .headinghow{
   
     color: #6d6d6d;
     font-size: 24px;
     font-family: lato , 'sans-serif';
     font-weight: 200;
   }
   
   .heading2 {
     font-size: 23px;
     font-weight: 600;
     color: #1a0e3c;
     font-family: lato , 'sans-serif';
   }
   
   .button1 {
     margin-top: 30px;
     text-align: center !important;
     text-decoration: none;
     font-size: 30px;
     font-family: 'Lato', sans-serif;
     margin-bottom: .5rem;
     font-weight: 500;
     line-height: 1.2;
     color: rgb(55, 55, 55) !important;
     text-decoration: blink !important;
   }
   
   .button1:hover {
     transform: scale(1.1);
   }
   
   .button2 {
     margin-top: 30px;
     background-color: rgb(247, 165, 13);
     /* Green */
     border: none;
     padding: 8px 10px 4px 10px;
     text-align: center !important;
     display: inline-block;
     font-size: 10px;
     font-weight: 900;
     border-radius: 4px;
     color: #ffffff;
     font-family: 'Lato', sans-serif;
     transition: all 0.2s ease;
     box-shadow:-1px 1px 2px 0px #b7b7b7;
   
   }
   .heading6 {
     color: #161616;
     font-weight: 200;
     font-size: 16px;
    
   }
   
   .smalltext{
     font-size:12px;
     color:rgb(78, 78, 78);
     padding-left:40px;
     padding-top:5px;
   }
   .heading34 {
     color: #161616;
     font-weight: 200;
     font-size: 16px;
     font-family: lato , 'sans-serif';
   }
   
   .button11:hover {
     transform: scale(1);
     padding: 8px 10px 4px 10px;
     background-color: rgba(112, 191, 230, 0.548);
     cursor:pointer;
     border: 2px solid #2F9CD5;
   }
   .button11{
     margin-top: 30px;
     background-color:  rgba(109, 188, 225, 0.21);
     /* Green */
     border: 2px solid rgba(255, 255, 255, 0);
     padding: 8px 10px 4px 10px;
     text-align: center !important;
     display: inline-block;
     font-size: 10px;
     font-weight: 900;
     border-radius: 4px;
     color: #ffffff;
     font-family: 'Lato', sans-serif;
     transition: all 0.2s ease;
     
   
   
   }
   .button11:hover .heading9{
     color:#ffffff;
   }
   .button12:hover {
     transform: scale(1);
     padding: 8px 10px 4px 10px;
     background-color:  rgba(47, 158, 213, 0.5);
     cursor:pointer;
     border: 2px solid #2F9CD5;
    
   }
   .button12:hover .heading9{
     color:#ffffff;
   }
   
   .button12{
     margin-top: 8px;
     background-color: rgba(109, 188, 225, 0.21);
     /* Green */
     border: 2px solid rgba(255, 255, 255, 0);
     padding: 8px 10px 4px 10px;
     text-align: center !important;
     display: inline-block;
     font-size: 10px;
     font-weight: 900;
     border-radius: 4px;
     
     font-family: 'Lato', sans-serif;
     transition: all 0.2s ease;
     
   
   }
   .button13{
     margin-top: 8px;
     background-color: rgba(12, 255, 182, 0.789) !important;
     /* Green */
     border: 2px solid rgb(255, 255, 255);
     padding: 14px 16px 8px 16px;
     text-align: center !important;
     display: inline-block;
     font-size: 15px;
     font-weight: 900;
     border-radius: 3px;
     margin-left:10px;
     width:290px;
     font-family: 'Lato', sans-serif;
     transition: all 0.2s ease;
     
   
   }
   
   .button31{
     margin-top: 58px;
     background-color: rgb(30, 195, 166) !important;
     /* Green */
     border: 2px solid rgb(255, 255, 255);
     padding: 14px 16px 8px 16px;
     text-align: center !important;
     display: inline-block;
     font-size: 15px;
     font-weight: 900;
     border-radius: 3px;
     margin-left:10px;
     width:180px;
     font-family: 'Lato', sans-serif;
     transition: all 0.2s ease;
     
   
   }
   .labels{
     color:white !important;
   }

   .button14{
     margin-top: 8px;
     background-color: rgb(255, 255, 255) !important;
     /* Green */
     border: 2px solid rgb(255, 255, 255);
     padding: 14px 16px 8px 16px;
     text-align: center !important;
     display: inline-block;
     font-size: 15px;
     font-weight: 900;
     border-radius: 3px;
     margin-left:10px;
     box-shadow:-1px 1px 2px 0px #b7b7b7;
     font-family: 'Lato', sans-serif;
     transition: all 0.2s ease;
     
   
   }
   .button18{
     margin-top: 8px;
     background-color: rgb(255, 175, 3) !important;
     /* Green */
     border: 2px solid rgb(255, 255, 255);
     padding: 14px 20px 8px 20px;
     text-align: center !important;
     display: inline-block;
     font-size: 15px;
     font-weight: 900;
     border-radius: 3px;
     margin-left:10px;
     box-shadow:-1px 1px 2px 0px #b7b7b7;
     font-family: 'Lato', sans-serif;
     transition: all 0.2s ease;
     
   
   }
   .button14:hover{
   cursor:pointer;
   transform:scale(1.1);
   }
   .button18:hover{
     cursor:pointer;
     transform:scale(1.1);
     }
   .button15{
     margin-top: 8px;
     background-color: rgba(2, 4, 41, 0.789) !important;
     /* Green */
     border: 2px solid rgb(255, 255, 255);
     padding: 14px 16px 8px 16px;
     text-align: center !important;
     display: inline-block;
     font-size: 15px;
     font-weight: 900;
     border-radius: 3px;
     margin-left:10px;
     box-shadow:-1px 1px 2px 0px #b7b7b7;
     font-family: 'Lato', sans-serif;
     transition: all 0.2s ease;
     
   
   }
   .button15:hover{
   cursor:pointer;
   transform:scale(1.1);
   }
   .button13:hover{
     margin-top: 8px;
     background-color: rgba(12, 255, 182, 0.789) !important;
     /* Green */
     border: 2px solid rgb(255, 255, 255);
     padding: 14px 16px 8px 16px;
     text-align: center !important;
     display: inline-block;
     font-size: 15px;
     font-weight: 900;
     border-radius: 3px;
     margin-left:10px;
     width:290px;
     font-family: 'Lato', sans-serif;
     transition: all 0.2s ease;
     
   
   }
   
   
   
   .button7 {
     margin-top: 30px;
     background-color: rgb(6, 170, 247);
     /* Green */
     border: none;
     padding: 8px 10px 4px 10px;
     text-align: center !important;
     display: inline-block;
     font-size: 10px;
     font-weight: 900;
     border-radius: 4px;
     color: #ffffff;
     font-family: 'Lato', sans-serif;
     transition: all 0.2s ease;
     box-shadow:-1px 1px 2px 0px #b7b7b7;
   
   }
   .button7:hover {
     transform: scale(1);
     padding: 8px 10px 4px 10px;
     background-color: rgb(2, 32, 92);
   }
   
   .megatext{
     font-size:20px;
     padding:10px 50px 10px 50px;
     font-weight:100px;
   }
   
   .button6 {
   
   
     background-color:rgb(243, 93, 93);
     /* Green */
     border: none;
     padding: 12px 35px 8px 35px;
     margin-left:15px;
     margin-bottom: 10px;
     text-align: center !important;
     display: inline-block;
     font-size: 17px;
     font-weight: 900;
     border-radius: 4px;
     color: #ffffff;
     font-family: 'Lato', sans-serif;
     transition: all 0.2s ease;
     box-shadow:-1px 1px 2px 0px #b7b7b7;
   
   }
   
   
   .button6:hover {
     transform: scale(1.1);
   
     background-color:rgb(247, 39, 39);
   }
   
   .contactbuttontext{
     font-size:20px;
   }
   
   .startupmodal{
     padding:20px 40px 20px 30px;
   }
   
   .dialogue {
     /* padding: 10px 10px 0px 10px; */
     padding:20px 10px 30px 10px;
     border:8px solid white;
     background-color:rgb(255, 255, 255);
     width:320px;
     border-radius:5px;
     margin-bottom:50px;
     margin-top:50px;
     margin-right:-45px;
     box-shadow: 3px 1px 20px 0px #16648598;
   
   }
   
   .car3{
     height:65vh;
   }
   
   .imagecorp {
     
       border: 2px solid #ffffff;
       border-radius: 10px;
       margin: 0px 30px;
       padding: 40px 5px 30px 5px;
       background-color: #ffffff;
       -webkit-box-shadow: 4px 3px 20px 0px #888888;
       box-shadow: 5px 2px 13px 0px #7caacf87;
   
   }
   .homestripitem1{
     cursor:pointer;
   }
   .imagecorp2 {
     border: 2px solid #ffffff;
     border-radius: 10px;
     margin: 0px 0px 0px 70px;
     height:150px;
     
     padding: 40px 5px 30px 5px;
     background-color: #ffffff;
     -webkit-box-shadow: 4px 3px 20px 0px #888888;
       box-shadow: 5px 2px 13px 0px #7caacf87;
   
   }
   
   .ask1 {
     padding: 40px;
     background-color: #a0a0a065;
     box-shadow: 2px 1px 4px 0px #888888;
   
   }
   .how{
    
       padding:30px 0px 30px 0px !important;
   
     background-color: white;
   }
   /* .ask {
     background-image: url('../images/askbanner.png');
     box-shadow:2px 1px 4px 0px #888888;
   } */
   
   .askanexpert {
   
   
     border-radius: 10px;
     padding: 60px;
   }
   
   .askbutton {
     padding: 20px;
     height:119px;
     border-radius: 5px;
     
     
   }
   /* .askb{
     width:290px;
     height:90px;
     background-image: url('../images/phoneicon2.png');
   
   } */
   /* .askb:hover{
     width:290px;
     height:119px;
     background-image: url('../images/phoneicon1.png');
   } */
   
   /* .askbutton:hover {
     
     background-image: url('../images/phoneicon2.png');
     -webkit-background-clip: text;
     -webkit-text-fill-color: #4b2424;
   } */
   
   .dialogue1 {
     /* padding: 10px 10px 0px 10px; */
     padding: 30px 10px 10px 10px;
     border: 8px solid rgb(228, 225, 225);
     background-color: rgb(255, 255, 255);
     width: 320px;
     border-radius: 15px;
     margin-bottom: 50px;
     margin-top: 70px;
     margin-right: -45px;
     box-shadow: 3px 1px 20px 0px #2e94c7;
   }
   .dialogue2 {
     /* padding: 10px 10px 0px 10px; */
     padding: 30px 10px 10px 10px;
     border: 8px solid rgb(228, 225, 225);
     background-color: rgb(255, 255, 255);
     width: 320px;
     border-radius: 15px;
     margin-bottom: 50px;
     margin-top:70px;
     margin-right: -45px;
     box-shadow: 3px 1px 20px 0px #2e94c7;
   }
   
   .dialogue6 {
     /* padding: 10px 10px 0px 10px; */
     padding: 10px 10px 10px 10px;
     border: 8px solid rgb(255, 255, 255);
     background-color: rgb(255, 255, 255);
     width: 380px;
     height:400px;
     border-radius: 8px;
     margin-bottom: 50px;
     margin-top: 20px;
     margin-right: -45px;
     box-shadow: 3px 1px 20px 0px #5e0202;;
   }
   
   .dialoguetext {
     font-family: 'Lato', sans-serif;
     color: #888888;
     font-size:15px;
     margin-top:-20px;
     padding-left:10px;
    
   }
   .dialoguetextmain {
     font-family: 'Lato', sans-serif;
     color: #575656;
     font-size:17px;
     font-weight:600;
     background-color: #F7E92A;
     padding:5px 0px;
     margin-bottom:8px;
    
   }
   
   .textblock1 {
     padding: 5px 0px 25px 40px;
     margin-bottom: 50px;
   }
   
   
   .paddingTop-5{
   
     padding-top: 10px;
   }
   /*ABOUT PAGE CSS*/
   
   .aboutimage  {
     margin-left: 30px;
     font-size: 18px;
     
   }
   .abouthead{
     font-size: 18px;
     font-weight:600 ;
     font-family:lato,'sans-serif';
   }
   
   .aboutcontent {
     font-size: 14px;
     color: #6d6c6c;
     
   
   }
   
   .iconcontent {
     padding-top: 20px;
     font-size: 18px;
     font-weight: 600;
   }
   
   .iconborder {
     border: 1px solid #88e9fa;
     margin: 45px;
     padding: 19px;
     border-radius: 7px;
     background-color: #ffffff
   }
   
   .iconborder1 {
     margin: 30px 32px 30px 50px;
     border-radius: 7px;
     -webkit-box-shadow: 5px 8px 20px 10px #cfcfcf;
     box-shadow: 5px 8px 20px 10px #cfcfcf;
     background-color: rgb(29, 29, 29);
     opacity: 1;
     height: 140px;
     width: 170px;
     -webkit-transition: all 0.5s ease;
     -o-transition: all 0.5s ease;
     transition: all 0.5s ease;
   }
   
   .iconborder1:hover {
     transform: scale(1.2);
   }
   
   .iconcontent1 {
     padding-top: 20px;
     font-size: 25px;
     font-weight: 600;
     color: #ffffff;
   }
   
   .requestname {
     padding-bottom: 20px;
   }
   
   /*training page*/
   .trainingdiv{
     padding:80px 0px;
   }
   .trainsec1 {
     font-family: 'Lato', sans-serif;
     border: 2px solid #dfdfdf;
     padding: 20px 20px 10px 20px;
     margin: 20px 0px;
     border-radius: 4px;
   }
   
   .mysec {
     padding: 40px 0px 100px 0px;
   }
   
   /*----Counte-----*/
   
   body{
     font-size:18px;
     font-weight: 400;
   }
   .p-y-2 {
    padding-top: 28px;
    padding-bottom: 28px;
   }
   .p-y-3 {
    padding-top: 45px;
    padding-bottom: 45px;
   }
   .m-b-1 {
    margin-bottom: 18px;
   }
   .m-t-1 {
    margin-top: 18px;
   }
   
   
   
   
   .main_counter_area{
   background: url(https://images.pexels.com/photos/196288/pexels-photo-196288.jpeg?w=940&h=650&auto=compress&cs=tinysrgb) no-repeat top center;
   background-size: cover;
   overflow: hidden;
   }
   .main_counter_area .main_counter_content .single_counter{
   background: rgba(236, 72, 72, 0.5);
    color: #fff;
   }
   .main_counter_area .main_counter_content .single_counter i{
   font-size:36px;
   }
   
   
   /*==========================================================
           counter  style Two
   ============================================================*/
   
   
   .sections {
   padding-top: 85px;
   padding-bottom: 105px;
   overflow: hidden;
   }
   .overlay {
   background-color: rgb(239, 104, 115);
   width: 100%;
   height: 300px;
   }
   .counter_two{
   background: url(https://images.pexels.com/photos/203541/pexels-photo-203541.jpeg?w=940&h=650&auto=compress&cs=tinysrgb) no-repeat 100% 100%;
   background-size:cover;
   height: 240px;
   overflow: hidden;
   }
   
   .fa-tshirt{
     color:black;
   }
   
   .counter_two .main_counter_two .single_counter_two_right{
   margin-top:30px;
   overflow: hidden;
   display: block;
   }
   .counter_two .main_counter_two .single_counter_two_right i{
   color:#fefefe;
   font-size:3.125rem;
   margin-bottom:40px;
   line-height: 4rem;
   display: inline-block;
   }
   .counter_two .main_counter_two .single_counter_two_right h2{
   color:#fff;
   margin-bottom:30px;
   line-height: 0;
   }
   .counter_two .main_counter_two .single_counter_two_right p{
   color:#fefefe;
   }
   
   /* Landscape phones and portrait tablets */
   
   
   
   /*==========================================================
           counter  style three
   ============================================================*/
   
   #counter_threeup {
   
   font-size: 14px;
   color: #666666;
   width: 100%;
   font-weight: 400;
   }
   .lightbg {
   background-color: #f9f9f9;
   }
   .sections {
   padding-top: 100px;
   padding-bottom: 100px;
   position: relative;
   overflow: hidden;
   }
   .overlay-icons {
     position: absolute;
     top: 0;
     bottom: 0;
     left: 0;
     right: 0;
     height: 100%;
     width: 100%;
     opacity: 0;
     transition: .5s ease;
     background-color: #ff7a7a;
   }
   /*For Counterup Section*/
   
   .counter_threeup{
   margin-top:20px;
   display: block;
   }
   .counter_threeup .counter_threeup-photo{
   width: 22%;
   height: 100px;
   float:left;
   margin-right: 10px;
   }
   .counter_threeup .counter_threeup-photo img{
   width:100%;
   margin-top:10px;
   }
   .counter_threeup .counter_threeup-content{
   padding-top:10px;
   padding-left:80px;
   
   
   }
   .counter_threeup .counter_threeup-content h5{
   font-family: 'Oswald', sans-serif;
   font-size:1.571em;
   }
   .counter_threeup .counter_threeup-content h6{
   font-size:1.286em;
   font-family: 'Lobster', cursive;
   }
   
   /*@media (max-width:992px){
   .counter_threeup{
    margin-bottom:30px;
   } 
   }*/
   
   
   /*-----------------------------*/
   .trainsec3 {
     background: #fafafa;
     border-radius: 100%;
     border: 2px solid #dcd5e8;
     height: 200px;
     width: 200px;
     padding-top: 70px;
     margin: 40px 20px 20px 30px;
     color: #333333;
     font-size: 18px;
     font-weight: 600px;
     font-family: 'Lato', sans-serif;
   }
   
   
   /*CORPORATE*/
   
   
   
   
   
   
   
   
   
   
   
   /*.line1send {
     left: 336px;
   }
   
   .line2send {
     left: 749px;
   }
   
   .line3send {
     left: 280px;
   }
   
   .line4send {
     left: 591px;
   }
   
   .line5send {
     left: 902px;
   }
   */
   
   .imagecorp1 {
     font-size: 18px;
     font-weight: 600;
     padding:0px 0px;
     width:180px;
   }
   
   
   
   /*PRICINGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGG*/
   
   /* Style the tab */
   
   /*PRICINGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGG*/
   
   /* Style the tab */
   
   
   
   
   
   
   
   
   
   /*********************************************/
   .hexagon1:hover .offercontent{
     padding: 5px;
     color: #ffffff;
     font-size:17px;
   }
   
   .offercontent3 {
     padding: 10px;
     color: #fffbfb;
     font-size:17px;
   }
   
   .hexagon1:hover .offercontent1 {
     padding: 10px;
     color: #ffffff;
     font-size:17px;
   }
   
   
   
   .hexagon1 {
     position: relative;
     width: 150px;
     height: 86.60px;
     background-color: #2694DF;
     margin: 43.30px 0;
     box-shadow: 0 0 20px rgba(80, 80, 80, 0.4);
     transition:all 0.2s ease;
   }
   .hexagon1:hover
   { color:#fafafa;
     transform: scale(1.2);
     background-color: rgb(0, 22, 63);
   }
   
   .expertrow{
     padding-left:1%;
     padding-top:80px;
   }
   .hexagon1:before,
   .hexagon1:after {
     content: "";
     position: absolute;
     z-index: 1;
     width: 106.07px;
     height: 106.07px;
     -webkit-transform: scaleY(0.5774) rotate(-45deg);
     -ms-transform: scaleY(0.5774) rotate(-45deg);
     transform: scaleY(0.5774) rotate(-45deg);
     background-color: inherit;
     left: 21.9670px;
     box-shadow: 0 0 20px rgba(102, 127, 141, 0.4);
   }
   
   .hexagon1:before {
     top: -53.0330px;
   }
   
   .hexagon1:after {
     bottom: -53.0330px;
   }
   
   /*cover up extra shadows*/
   .hexagon1 span {
     display: block;
     position: absolute;
     top:0px;
     left: 0;
     width:150px;
     height:86.6025px;
     z-index: 2;
     background: inherit;
   }
   /*opportuneety*/
   .textfields{
     margin-top:-10px;
     width:110px;
   }
   .textfields1{
     margin-top:3px;
     width:110px;
   }
   .opportuneety1 {
     height: 510px;
     transition: 0.2s ease-in;
   }
   
   
   
   .oppormain {
     padding: 0px 45px 0px 45px;
   }
   
   .platformcontent {
     padding-top: 0px;
     font-family: 'Lato', sans-serif;
     color: #6e6e6e;
     font-size: 28px;
   }
   
   /*form-group*/
   
  
   .resource1{
     width:860px;
     color: #7a7a7a;
     font-size:14px;
   }
   .form-control1 {
     color: #0f0f0f;
     font-size:14px;
   }
   
   
   .pricing {
     padding-top: 100px;
     padding-left: 60px;
   }
   /*-----======================================================================================*/
   
   /*===========================================================================================*/
   .charitybox{
     margin-top:35px;
   
     height:auto;
     background-color: #F7E92A;
     border:2px solid rgb(255, 225, 0);
     padding:6px;
     border-radius:5px;
     box-shadow: 0 0 20px rgba(80, 80, 80, 0.4);
   
   }
   .charitytxt{
     padding:7px 30px 30px 30px;
     font-size:18px;
     font-weight:200;
     
     color:#020202;
   }
   .charityhead{
     padding-top:10px;
     text-align:center;
     color:#000000;
     font-weight:200;
   }
   
   label {
     color: rgb(39, 37, 37);
   }
   
   .options{
     padding-top:6px;
   }
   .headitem{
     background-color: #f0f8fa;
     padding: 10px 15px;
     font-size: 16px;
     font-weight: 600;
     font-family: 'Lato', sans-serif;
     color: #0d1d37;
   }
   .options-dropdown{
     padding:20px;
     width:200px;
     margin-right:10px;;
   }
   .overview{
     padding:30px 0px;
   }
   
   .bannername
   {padding-top:180px;
   padding-left:210px;
   font-family: 'Arial-Rounded',sans-serif;
   font-size:50px;
   text-shadow: 2px 2px 8px #62b1d0;}
   
   .bannername1
   {padding-top:60px;
   padding-left:0px;
   font-family: 'Arial-Rounded',sans-serif;
   font-size:50px;
   text-shadow: 2px 2px 8px #62b1d0;}
   
   .productview{
     height:400px;
     
   }
   .navmain-item{
     color:#6b6b6d ;
     font-size:15px;
     font-weight:200;
   }
   .navmenu{
     margin-left:0px;
     margin-top:40px;
     background-color: #F2E700;
   }
   

   
   .navvyitem{
     background-color: rgb(255, 255, 255);
     border:1px solid rgb(255, 217, 0);
     padding:7px 5px;
      width:245px;
     text-align:center;
     -webkit-box-shadow: 0px 0px 11px 1px #bbbbbb;
     box-shadow: -1px 2px 8px 0px #e4dfdf;
   
   }
   
   .homestripitem1{
     background-color:  rgb(255, 249, 249);
     border:1px solid rgb(255, 252, 252);
     padding:7px 5px;
    
     text-align:center;
     -webkit-box-shadow: 0px 0px 11px 1px #bbbbbb;
     box-shadow: -1px 2px 8px 0px #e4dfdf;
   
   }
   
   .white{
     background-color: rgb(255, 255, 255);
     -webkit-box-shadow: 0px 0px 11px 1px #bbbbbb;
     box-shadow: -1px 2px 8px 0px #e4dfdf;
   }
   
   
   .navvyitem1{
     background-color: rgb(255, 255, 255);
     padding:7px 5px;
     border:1px solid rgb(255, 230, 1);
      width:365px;
     text-align:center;
    
     -webkit-box-shadow: 0px 0px 11px 1px #bbbbbb;
     box-shadow: -1px 2px 8px 0px #e4dfdf;
   }
   
   .navvyitem:hover{
     cursor:pointer;
   }
   .navvyitem1:hover{
     cursor:pointer;
   }

   /* .only1productsection{
     background-image:url('../images/mainproban.png');
     box-shadow: 0px 0px 11px 1px #bbbbbb;
     padding-top:30px;
     padding-bottom:50px;
   } */
   .onlyproductsection1{
     background-color: #ffffff;
     box-shadow: 0px 0px 11px 1px #bbbbbb;
    
   }
   .pricecard{
   margin-top:30px;
   margin-bottom:40px;
   }
   .priceheaddiv{
     background-color: #104892;
     border-bottom:1px solid white;
     text-align:center;
     padding:10px 0px;
   }
   .pricehead1{
     color:white;
     font-size:30px;
    
   
   }
   .pricingprice1{
     color:white;
     font-size:27px;
    
   
   }
   .pricingcontent2{
     color:white;
     font-size:23px;
     
    
   
   }
   .aboutprice{
     padding-top:20px;
     text-align:center;
     background-color: #104892;
     padding-bottom:30px;
   }
   .arrow-down {
     width: 0;
     height: 0;
     border-left: 10px solid transparent;
     border-right: 10px solid transparent;
     border-top: 10px solid rgb(255, 255, 255);
     margin-top: 0px;
     position: absolute;
     z-index: 3;
     opacity: 0;
     -webkit-transition: all 0.5s ease;
     -o-transition: all 0.5s ease;
     transition: all 0.5s ease;
     
   }
   .center-image{
     background-color: #ffc8c8;
     padding: 1px 8px;
     text-align: -webkit-center;
     border-top: 6px solid #ebebeb;
       border-bottom: 6px solid #ebebeb;
   }
   
     .cardstuff {
      
       border: 1px solid #e3e1e1a8;
       border-radius: 6px;
       padding: 10px 0px;
       /* -webkit-box-shadow: 5px 6px 20px 0px #9494935c; */
       /* box-shadow: 1px 0px 20px 0px #e4e4e4; */
       margin: 30px 15px;
       background-color: rgb(255, 255, 255);
   }
   
   .cardstuff1 {
      
     border: 1px solid #e3e1e1a8;
     border-radius: 6px;
     padding: 10px 30px;
     /* -webkit-box-shadow: 5px 6px 20px 0px #9494935c; */
     /* box-shadow: 1px 0px 20px 0px #e4e4e4; */
     margin: 30px 15px;
     background-color: rgb(255, 255, 255);
   }
   .cardcontent{
     padding:4px 10px;
   }
   
     
   .detail10{
     
     background-color: #ffffff;
     padding:0px 0px;
     border-radius:20px;
     
   }
   
   
   .detail3{
     
     background-color:#ffffff;
     padding:0px 0px;
     border-radius:8px;
     text-align:left
   }
   .detail4{
     
     background-color: #a1d6ff;
     padding:8px 0px;
     border-top:2px solid rgb(237, 237, 237);
     border-bottom:2px solid  rgb(237, 237, 237);
     
   }
   
   .detail5{
     
     background-color: rgb(238, 250, 255);
       
     padding:8px 0px;
     border-top:2px solid  rgb(237, 237, 237);
     border-bottom:2px solid  rgb(237, 237, 237);
   }
   .detail6{
     
     background-color: rgb(222, 237, 255);
     padding:8px 0px;
     border-top:2px solid  rgb(237, 237, 237);
     border-bottom:2px solid  rgb(237, 237, 237);
   }
   
    
    
   
   .contenttt{
     color:rgb(65, 64, 64);
     font-size:12px;
     font-weight:300;
     margin-left:2px;
     text-align: left;
   }
   
   
   
    
   .navvvyitem{
     background-color: rgb(200, 222, 255);
     border:1px solid white;
     padding:12px 5px 6px 5px;
      width:245px;
     text-align:center;
    
   
   }
   
   
   a {
     color: #575757;
     text-decoration: none;
     background-color: transparent;
     -webkit-text-decoration-skip: objects;
   }
   
   
   .button27 {
     margin-left: 0px;
     width:160px;
     padding: 10px 10px 3px 10px;
     border-top-left-radius: 100px;
     border-bottom-left-radius: 100px;
     
     
     background-color:rgb(30, 195, 166) !important;
     border: 1px solid  rgb(30, 195, 166) !important;
     -webkit-box-shadow: 0px 0px 11px 1px #bbbbbb;
     box-shadow: -1px 6px 8px 0px #b52c2c;
   }
   .button28 {
     margin-left: 0px;
     width:160px;
     padding: 10px 10px 3px 10px;
     border-top-right-radius: 100px;
     border-bottom-right-radius: 100px;
     
     
     background-color:rgb(30, 176, 195) !important;
     border: 1px solid  rgb(30, 170, 195) !important;
     -webkit-box-shadow: 0px 0px 11px 1px #bbbbbb;
     box-shadow: -1px 6px 8px 0px #b52c2c;
   }
   
  
   .homesection2{
     margin-top:50px;
     padding:130px 30px;
     height:400px;
     background-color: white;
   }
   
   .second1{
     text-align:left;
     border-left:3px solid #2379BC;
     padding-right:30px;
   }
   .second2
   {text-align:center;
   padding-right:30px;}
   
   .content1 {
     margin-top: 21px;
     font-size: 21px;
     color: #353535;
     font-weight: 200;
   }
   .content6 {
     margin-top: 21px;
     font-size: 21px;
     color: #ffffff;
     font-weight: 200;
   }
   
   .content8 {
     margin-top: 21px;
     font-size: 16px;
     color: #3d3d3d;
     font-weight: 100 !important;
     
   
   }
   .content7{
     margin-top: 5px;
     font-size: 28px;
     color: #2b2b2b;
     font-weight: 200;
   }
   
   .content3 {
     margin-top: 14px;
     font-size: 25px;
     color: #353535;
     font-weight: 200;
   }
   .content5  {
     margin-top: 14px;
     font-size: 25px;
     color: #ffffff;
     font-weight: 200;
     
   }
   .content4{
     margin-top: 21px;
     font-size: 12px;
     color: #353535;
     font-weight: 200;
   }
   
 
   
   .dedicatedstuff{
     height:auto
   }
   
   
   .breadcrumb1{
     margin-top:70px;
   }
   /* .sidebar{
     border:1px solid #e9e9e9;
     margin-top:25px;
     padding:7px 10px;
     width:350px;
     height:auto;
    
     border-radius:4px;
   } */
   
   
   
   .btnfilter{
     border:1px solid #e9e9e9;
   }
   
   .filtercontent{
     padding-top:8px;
   }
   
   
   .search-bar{
     padding-top:454px;
     padding-left:0px;
     padding-right:20px;
   }
   .blocksearch{
    
     width:40px;
     height:20px;
     padding:0px 14px 0px 4px;
   }
   .fa-search{
   height:14px;
   }
   
   
   
   
   
   
   .link{
     color:rgb(31, 30, 36)!important;
   }
   
   .testimonials1{
     margin-bottom:60px;
   }
   
   .docsec1{
     padding-top:90px;
   }
   
   .customers24 {
     padding:40px 0px 40px 0px;
   }
   
    }
   
   /*******************ALPHRADE**********************************************************/
   @media (max-width: 768px) {
     .body{
       /* overflow-x: hidden; */
       width: 100%;
     }
   
   .bannername{
   
     padding-top: 190px;
       padding-left: 5%;
       font-family: 'Arial-Rounded',sans-serif;
       font-size: 42px;
       text-shadow: 2px 2px 8px #62b1d0;
   
   }
    
   .dialogue1{
     margin-top:80px !important;
     width:90% !important;
   }
   .dialogue2{
     margin-top:380px !important;
     width:90% !important;
   }
   .textblock {
     margin-top: 13%;
     padding: 26px;
     background-color: rgba(55, 185, 255, 0.479);
     /* height: 280px; */
     border-radius: 5px;
     width: 100%;
     color: #050505;
   }
   
   .textblock2 {
     margin-top: 10%;
     padding: 40px;
     background-color: rgba(237, 255, 159, 0.542);
     height: 280px;
     border-radius: 5px;
     width: 50%;
     font-size: 22px;
     color: #333333;
   }
   
   
   
   .counter_threeup .counter_threeup-photo img{
     width:50%;
     height: 60%;
    } 
   .car1 {
     height: 820px !important;
   }
   .car2 {
     height: 700px !important;
   }
   .car4 {
     height: 550px !important;
   }
   .car5 {
     height: 980px !important;
   }
   mobile{
     display:block !important;
   }

   .incorporatepricing {
     padding-top:90px;
     height: auto;
     padding-bottom:40px;
   }
   
   .textblock1 {
     padding: 5px 0px 25px 38px;
     margin-bottom: 50px;
   }
   .iconrow2 {
     padding-top: 10px;
     padding-left:12%;
   }
   .iconrow {
     padding-top: 10px;
     padding-left:12%;
   }
   .car3{
     height:920px;
   }
   
   
   
   
   .whymobile{
     margin-right:20px;
   }
   .button13{
     margin-top: 8px;
     background-color: rgba(12, 255, 182, 0.789) !important;
     /* Green */
     border: 2px solid rgb(255, 255, 255);
     padding: 14px 16px 8px 16px;
     text-align: center !important;
     display: inline-block;
     font-size: 12px;
     font-weight: 900;
     border-radius: 3px;
     margin-left:10px;
     width:180px;
     
     
     font-family: 'Lato', sans-serif;
     transition: all 0.2s ease;
     
   
   }
   
   #whatwedoterms{
     margin-left:2px;
   }
   .footrow{
     padding-bottom:20px;
   }
   .mobilepro{
     margin-left:-73px;
     margin-right:45px;
   }
   .iconborder1 {
     margin: 30px 10px 30px 10px;
     border-radius: 7px;
     -webkit-box-shadow: 5px 8px 20px 10px #cfcfcf;
     box-shadow: 5px 8px 20px 10px #cfcfcf;
     background-color: rgb(29, 29, 29);
     opacity: 1;
     height: 140px;
     width: 170px;
     -webkit-transition: all 0.5s ease;
     -o-transition: all 0.5s ease;
     transition: all 0.5s ease;
   }
   
   .iconborder1:hover {
     transform: scale(1.2);
   }
   .iconrowmob {
     padding-top: 80px;
     padding-left:8%;
   }
   .iconrowmob2 {
     padding-top: 10px;
     padding-left:8%;
   }
   .banimage {
     padding: 25px 0px 25px 0px;
   }
   .bantext {
     color: #181717;
     font-size: 16px;
     font-family: 'Lato', sans-serif;
     padding-top: 8px;
     font-weight:700;
     margin-left: -8px;
   }
   
   .dialoguetext {
     font-family: 'Lato', sans-serif;
     color: #888888;
     font-size:18px;
     padding-left:5px;
   
    
   }
   .heading15 {
     color: #ffffff !important;
     font-weight: 600 !important;
     font-size: 17px !important;
     font-family: 'Lato', sans-serif;
   }
   .head {
     color: #ffffff;
     padding-top: 70px;
     padding-left: 20px;
     font-family: 'Lato', sans-serif;
   }
   .navvyitem{
     background-color: #F2E700;
     padding: 7px 5px;
     border: 1px solid white;
     width: 48%;
     text-align: center;
    
   
   }
   .navvyitem1{
     background-color: #F2E700;
       padding: 7px 5px;
       border: 1px solid white;
       width: 48%;
       text-align: center;
    
   
   }
   
   
   .contactText {
     font-size: 22px;
     font-weight: 600;
   }
   .subcontactText{
     font-size:22px;
     font-weight: 400;
     color:#5f5f5f;
     padding-left:0px;
   
   }
   
   
   
   .dialogue6 {
     /* padding: 10px 10px 0px 10px; */
     padding: 10px 10px 10px 10px;
     border: 8px solid rgb(255, 255, 255);
     background-color: rgb(255, 255, 255);
     width: 243%;
     height: 520px;
     border-radius: 8px;
     margin-bottom: 50px;
     margin-top: 208px;
     margin-right: -45px;
     -webkit-box-shadow: 3px 1px 20px 0px #5e0202;
     box-shadow: 3px 1px 20px 0px #5e0202;
   }
   
   .car8{
     height:120vh;
   }
   
   .smalltext{
     font-size:13px;
     color:rgb(78, 78, 78);
     padding-left:18px;
     padding-top:5px;
   }
   
   .overlay {
     background-color: rgb(239, 104, 115);
     width: 100%;
     height: auto;
     padding:10px 0px 60px 0px;
     margin-bottom:30px;
     text-align:center;
     }
   
   
  
   .request-button{
     background-color: #ffffff;
       height: 50px;
       position: fixed;
       bottom: 80px;
       width: 50px;
       border: 1px solid rgb(231, 74, 34) ;
       -webkit-transition: opacity 0.5s ease-in-out 0s, right;
       -o-transition: opacity 0.5s ease-in-out 0s, right;
       transition: opacity 0.5s ease-in-out 0s, right;
       cursor: pointer;
       opacity: 1;
       
       color: #252525;
       left: 20px;
       display: none;
       z-index: 1;
   }
   
   .slick-slide img {
     height: 40px;
     display: block;
   }
   .request-button2{
     background-color:#ffffff;
       height: 50px;
       position: fixed;
       bottom: 140px;
       width: 50px;
       border: 1px solid rgb(231, 74, 34) ;
       -webkit-transition: opacity 0.5s ease-in-out 0s, right;
       -o-transition: opacity 0.5s ease-in-out 0s, right;
       transition: opacity 0.5s ease-in-out 0s, right;
       cursor: pointer;
       opacity: 1;
       
       padding:0px 0px 0px 0px;
       color: #1a1a1a;
       left: 20px;
       display: none;
       z-index: 1;
   }
   
   .cardstuff1 {
      
     border: 1px solid #d3d2d2a8;
     border-radius: 6px;
     padding: 10px 30px;
     width:340px;
     /* -webkit-box-shadow: 5px 6px 20px 0px #9494935c; */
      box-shadow: 1px 0px 20px 0px #e4e4e4; 
     margin: 30px 15px;
     background-color: rgb(255, 255, 255);
   }
   
   
   .heading1 {
     margin-top: 25px;
     color: #ffffff;
     font-size: 24px;
     font-family: lato , 'sans-serif';
     font-weight: 200;
   }
   .actioncard{
     background-color: white;
     margin:0px 80px 0px 80px;
     padding:30px 0px;
     margin-top:20px;
     box-shadow: 3px 1px 20px rgba(193, 175, 175, 0.4);
   }
   .red{
     color:rgb(255, 153, 0);
   }
   
   .button6 {
   
   
     background-color:rgb(243, 93, 93);
     /* Green */
     border: none;
     padding: 12px 25px 8px 25px;
     margin-left:15px;
     margin-top:20px;
     margin-bottom: 10px;
     text-align: center !important;
     display: inline-block;
     font-size: 17px;
     font-weight: 900;
     border-radius: 4px;
     color: #ffffff;
     font-family: 'Lato', sans-serif;
     transition: all 0.2s ease;
     box-shadow:-1px 1px 2px 0px #b7b7b7;
   
   }
   .contactText {margin-top:20px;
     font-size: 32px;
     font-weight: 200;
   }
   
   
   .contactstrips {
     margin-top:20px;
     background-color: #f5f5f5;
    border-radius:10px;
   padding-bottom:70px; 
     padding-top: 50px;
     
   }
   .thewhole{
     overflow-x:hidden;
     width: 100vw;
   }
   .from-control {
     width:300px;
     margin-top:4px;
     color: #7a7a7a;
     font-size:14px;
   }
   .imagecorp1 {
     font-size: 18px;
     font-weight: 600;
     padding:0px 0px;
     
   }
   }
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   @media only screen and (min-width:758px){
     .mobile{
       display:none !important;
     }
   }
   
   @media only screen and (max-width: 500px) {
     .collapse {
       display: none !important;
     }
   }
   .subtitles{
     padding-top:0px;
     padding-left:44px;
     font-size:13px;
     
     color:#aeaeae;
     font-weight:200;
   }
   
   .bantext {
     color: #181818;
     font-size: 16px;
      font-weight:100;
     padding-top: 8px;
     padding-right: 1px;
     margin-left: -30px;
   }
   
   .iconrow {
     padding-top: 80px;
   }
   .iconrow2 {
     padding-top: 10px;
   }